:root {
  --screen-mobiles-xs: 350px;
  --screen-mobiles-s: 400px;
  --screen-mobiles: 580px;
  --screen-mobiles-l: 750px;
  --screen-tablets: 900px;
  --screen-tablets-l: 1100px;
  --screen-tablets-xl: 1320px;
  --screen-desktop: 1600px;
  --DualSlide-breakpoint: var(--screen-mobiles);
  --global-space: 20px;
  --global-radius: 20px;
  --app-width: 1260px;
  --app-padding: calc(var(--global-space) * 1.25);
  --app-nav-height: 61px;
  --webkit-fill-available: 100vh;
  --color-primary: #00e7a3;
  --color-primary-2: #47d28e;
  --color-secondary: #ff6946;
  --color-tertiary: #0083fd;
  --color-border-gray: #f2f3f5;
  --text-color-primary: #1a1a1a;
  --text-color-secondary: #4a4a4a;
  --text-color-invert: #fff;
  --foundation-green-dark: #5dae86;
  --foundation-green-normal: #7ce8b3;
  --foundation-green-dark-active: #386851;
  --foundation-green-dark-hover: #4a8b6b;
  --foundation-green-darker: #2b513f;
  --transition-duration: 0.25s;
  --DualSlide-ts: none;
  --DualSlide-ts-d: 600ms;
  --BodySpace-padding: calc(var(--global-space) * 5);
  --p-ta: inherit;
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
}

button {
  font-family: Anakotmai, sans-serif;
}

@mixin button {
  display: inline-block;
  width: fit-content;
  min-width: 264px;
  padding: calc(var(--global-space) * 0.35) calc(var(--global-space) * 1.6);
  transition: background-color 0.2s;
  border-radius: 8px;
  background: var(--foundation-green-dark);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  color: white;
  font-family: Anakotmai, sans-serif;
  font-size: 26px;
  font-weight: 700;
  text-align: center;

  &:hover {
    background-color: rgba(93, 174, 134, 0.9);
  }
}

@mixin submit-button {
  padding: 17px 40px;
  border-radius: 8px;
  background-color: #47aa91;
  color: white;
  font-size: 26px;
  font-weight: 700;

  &:hover {
    background-color: rgba(93, 174, 134, 0.9);
  }

  @media (max-width: $screen-mobiles) {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@mixin bg-with-image {
  padding: 76px 41px;
  background: linear-gradient(
      0deg,
      rgba(94, 126, 152, 0.9),
      rgba(94, 126, 152, 0.9)
    ),
    url('/images/footer-bg.svg');
  background-repeat: no-repeat;
  background-position: top 0;
  background-size: cover;
}

@mixin company-map {
  width: 100%;
  height: 160px;
  margin-top: 32px;
  border-radius: 8px;
}
