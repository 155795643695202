@import './node_modules/prismjs/themes/prism.css';
@import '/components/custom-ai/custom-ai.scss';

$screen-mobiles-xs: 350px;
$screen-mobiles-s: 400px;
$screen-mobiles: 580px;
$screen-mobiles-l: 750px;
$screen-tablets: 900px;
$screen-tablets-l: 1100px;
$screen-tablets-xl: 1320px;
$screen-desktop: 1600px;

$DualSlide-breakpoint: $screen-mobiles;

@font-face {
  font-family: Poppins-Regular;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url(https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins-SemiBold;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: Anakotmai;
  font-weight: 400;
  font-display: swap;
  src: local('Anakotmai-Light'), url(/fonts/Anakotmai-Light.otf);
}

@font-face {
  font-family: Anakotmai;
  font-weight: 500;
  font-display: swap;
  src: local('Anakotmai-Medium'), url(/fonts/Anakotmai-Medium.otf);
}

@font-face {
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

$Poppins-Regular: poppins-regular, tahoma, -apple-system, blinkmacsystemfont,
  'Segoe UI', roboto, oxygen-sans, ubuntu, cantarell, 'Helvetica Neue',
  helvetica, arial, sans-serif;

$Poppins-SemiBold: poppins-semibold, tahoma, -apple-system, blinkmacsystemfont,
  'Segoe UI', roboto, oxygen-sans, ubuntu, cantarell, 'Helvetica Neue',
  helvetica, arial, sans-serif;

@mixin overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s;
  background-color: rgba(#161616, 0.7);
}

@mixin h2 {
  font-family: $Poppins-SemiBold;
  font-size: 44px;
  letter-spacing: 0.05rem;

  @media (max-width: $screen-tablets) {
    font-size: 36px;
  }
}

@mixin h3 {
  font-size: 28px;
  letter-spacing: 0.025rem;

  @media (max-width: $screen-mobiles) {
    font-size: 22px;
  }
}

:root {
  --global-space: 20px;
  --global-radius: 20px;
  --app-width: 1220px;
  --app-padding: var(--global-space);
  --Nav-height: 60px;
  --Nav__panel-ts: none;
  --DualSlide-ts: none;
  --DualSlide-ts-d: 600ms;
  --BodySpace-padding: calc(var(--global-space) * 5);
  --p-ta: inherit;
  --transition-duration: 0.25s;
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --color-primary: #00e7a3;
  --color-primary-2: #47d28e;
  --foundation-green-dark: #5dae86;
  --foundation-green-normal: #7ce8b3;
  --foundation-green-dark-active: #386851;
  --foundation-green-dark-hover: #4a8b6b;
  --foundation-green-darker: #2b513f;
  --color-border-gray: #f2f3f5;

  @media (max-width: $screen-mobiles-l) {
    --BodySpace-padding: calc(var(--global-space) * 3);
  }
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  overflow-wrap: break-word;
  word-break: break-word;
}

body,
input,
select,
textarea,
button {
  color: #525252;
  font-family: $Poppins-Regular;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: calc(2ex + 12px);
  overflow-anchor: none;
}

a,
button {
  color: currentColor;
  text-decoration: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &[href]:hover {
    cursor: pointer;
    // text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: normal;
}

[disabled] {
  opacity: 0.4;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none; // To prevent `onClick` triggering.

  &[title] {
    cursor: help !important;
  }
}

figure,
iframe,
img {
  display: block;
  max-width: 100%;
  border: none;
}

ul,
ol {
  list-style-type: none;
}

table {
  // border-collapse: collapse;
  border-spacing: 0;
}

svg {
  display: block;
  width: 100%;
}

video {
  display: block;
  width: 100%;
}

picture,
source {
  display: contents;
}

textarea {
  padding: 8px;
  border: 1px solid var(--grey-text, #a6a6a6);
  border-radius: 4px;
  resize: none;

  @media (max-width: $screen-mobiles) {
    font-size: 12px;
  }
}

textarea:placeholder-shown {
  background-color: #fbfbfb;
}

@mixin span {
  color: var(--color-primary-2);
  font-weight: 700;
}

@mixin submit-button {
  padding: 10px 40px;
  border: none;
  border-radius: 8px;
  background-color: var(--foundation-green-dark);
  color: white;
  font-family: Anakotmai, sans-serif;
  font-size: 26px;
  font-weight: 700;

  &:hover {
    background-color: rgba(93, 174, 134, 0.9);
  }

  @media (max-width: $screen-mobiles) {
    padding: 8px 16px;
    font-size: 14px;
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    font-size: 18px;
    font-weight: 500;

    span {
      padding-left: 2px;
      color: #b60000 !important;
    }

    @media (max-width: $screen-mobiles) {
      font-size: 12px;
    }
  }

  input {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--grey-text, #a6a6a6);
    border-radius: 4px;
    font-size: 18px;

    @media (max-width: $screen-mobiles) {
      padding: 12 8px;
      font-size: 12px;
    }
  }

  input:placeholder-shown,
  textarea:placeholder-shown {
    background-color: #fbfbfb;
    font-family: Anakotmai, sans-serif;
  }

  .error {
    color: #b60000 !important;
    font-size: 13px;
  }
}

.container {
  max-width: calc(var(--app-padding) * 2 + var(--app-width));
  margin: 0 auto;
  padding: 0 var(--app-padding);
}

.Layout {
  &--active {
    --Nav__panel-ts: opacity;
    --DualSlide-ts: transform;
  }
}

.Index {
  &__how {
    position: relative;
    padding: calc(var(--BodySpace-padding)) 0;

    &__img {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__h2 {
      @include h2;

      color: #fff;
      text-align: center;
    }

    &__dl {
      display: grid;
      grid-gap: calc(var(--global-space) * 1) calc(var(--global-space) * 2);
      grid-template-columns: repeat(2, 1fr);
      margin-top: calc(var(--BodySpace-padding) * 0.5);
      counter-reset: counter-stats-item;

      @media (max-width: $screen-tablets-l) {
        grid-template-columns: 1fr;
      }

      &__item {
        display: grid;
        grid-gap: calc(var(--global-space) * 1);
        grid-template-columns: 65px 1fr;
        align-items: start;
        counter-increment: counter-stats-item;

        &::before {
          content: '0' counter(counter-stats-item);
          display: block;
          color: #fff;
          font-family: $Poppins-SemiBold;
          font-size: 40px;
          letter-spacing: 1.67px;
        }

        &__dt {
          padding-top: calc(var(--global-space) * 0.25);
          color: #fff;
          font-family: $Poppins-SemiBold;
          font-size: 19px;
          letter-spacing: 0.79px;
        }

        &__dd {
          margin-top: calc(var(--global-space) * 0.5);
          color: #fff;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;
          text-align: var(--p-ta);
        }
      }
    }
  }

  &__introduce {
    padding-top: calc(var(--BodySpace-padding));

    > .container {
      display: grid;
      grid-gap: calc(var(--global-space) * 4);
      grid-template-columns: max-content 1fr;

      @media (max-width: $screen-tablets-l) {
        grid-gap: calc(var(--global-space) * 3);
        grid-template-columns: 1fr;
      }

      @media (max-width: $screen-mobiles) {
        grid-gap: calc(var(--global-space) * 2);
      }
    }

    &__h2 {
      @include h2;

      padding-top: calc(var(--BodySpace-padding));
      color: #1a1a1a;

      @media (max-width: $screen-tablets-l) {
        padding-top: 0;
      }

      span {
        color: #03e2a7;
      }

      br {
        @media (max-width: $screen-mobiles-s) {
          display: none;
        }
      }
    }

    &__dl {
      display: grid;
      grid-gap: calc(var(--global-space) * 2);
      grid-template-columns: repeat(2, 1fr);
      counter-reset: counter-stats-item;

      @media (max-width: $screen-mobiles) {
        grid-template-columns: 1fr;
      }

      &__item {
        display: grid;
        grid-gap: calc(var(--global-space) * 0.25);
        grid-template-columns: calc(var(--global-space) * 1.25) 1fr;
        counter-increment: counter-stats-item;

        &::before {
          content: '0' counter(counter-stats-item);
          display: block;
          color: #1a1a1a;
          font-family: $Poppins-SemiBold;
          font-size: 13px;
          letter-spacing: 0.54px;
        }

        &__dt {
          color: #1a1a1a;
          font-family: $Poppins-SemiBold;
          font-size: 13px;
          letter-spacing: 0.54px;
        }

        &__dd {
          margin-top: calc(var(--global-space) * 0.5);
          color: #1a1a1a;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;
          text-align: var(--p-ta);
        }
      }
    }

    .Buttons {
      margin-top: calc(var(--global-space) * 2.5);
      padding-left: calc(var(--global-space) * 1.25);

      @media (max-width: $screen-mobiles) {
        justify-content: end;
        padding-left: 0;
      }
    }

    &__a {
      @include h3;

      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: max-content max-content;
      align-items: center;
      color: #1a1a1a;
      font-family: $Poppins-Regular;

      &::after {
        content: '';
        display: block;
        width: 48px;
        height: 22.1px;
        background-image: url(/images/Index__introduce__a.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }
  }

  &__gallery {
    overflow: hidden;

    &__dl {
      display: grid;
      grid-gap: calc(var(--global-space) * 0.75);
      grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));

      @media (max-width: $screen-mobiles-l) {
        grid-gap: calc(var(--global-space) * 0.5);
      }

      &__item {
        display: grid;
        position: relative;
        background-color: rgba(151, 151, 151, 0.32);

        --Index__gallery__dl__item__wrapper-o: 0;

        &:hover {
          --Index__gallery__dl__item__wrapper-o: 1;
        }

        @media (max-width: $screen-mobiles) {
          pointer-events: none;
        }

        > * {
          grid-area: 1 / 1 / 2 / 2;
        }

        &:nth-of-type(n + 5) {
          @media (max-width: $screen-tablets-xl) {
            display: none;
          }
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: opacity 0.2s;
          transition-timing-function: ease-in-out;
          opacity: var(--Index__gallery__dl__item__wrapper-o);
          background-color: rgba(82, 108, 107, 0.9);
          will-change: opacity;

          @media (max-width: $screen-mobiles) {
            display: none;
          }
        }

        &__img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &__wrapper {
          display: grid;
          position: relative;
          z-index: 1;
          grid-gap: calc(var(--global-space) * 1);
          align-content: center;
          padding: calc(var(--global-space) * 1);
          transition: opacity 0.2s;
          transition-timing-function: ease-in-out;
          opacity: var(--Index__gallery__dl__item__wrapper-o);
          text-align: center;
          justify-items: center;
          will-change: opacity;

          @media (max-width: $screen-mobiles-l) {
            grid-gap: calc(var(--global-space) * 0.5);
          }

          @media (max-width: $screen-mobiles) {
            display: none;
          }
        }

        &__p {
          color: #fff;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;
        }

        &__span {
          color: #fff;
          font-family: $Poppins-Regular;
          font-size: 28px;
          letter-spacing: 1.17px;
        }

        &__dt {
          color: #fff;
          font-family: $Poppins-SemiBold;
          font-size: 28px;
          letter-spacing: 1.17px;

          @media (max-width: $screen-mobiles-l) {
            font-size: 16px;
          }
        }

        &__a {
          margin-top: calc(var(--global-space) * 1);
          color: #fff;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;
        }
      }
    }
  }

  &__clients {
    padding: calc(var(--BodySpace-padding)) 0;

    &__h2 {
      @include h2;

      color: #1a1a1a;
      text-align: center;
    }

    &__p {
      margin-top: calc(var(--global-space) * 1);
      color: #3a4f5f;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
      text-align: center;
    }

    &__gallery {
      display: grid;
      grid-gap: calc(var(--global-space) * 2);
      align-items: center;
      margin-top: calc(var(--global-space) * 3);

      &__a {
        @media (max-width: $screen-mobiles) {
          display: none;
        }

        &::before {
          content: '';
          display: block;
          width: 48px;
          height: 22.01px;
          background-image: url(/images/Index__clients__gallery__a.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }

        &--alt {
          transform: scaleX(-1);
        }
      }

      &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 75%;
        max-width: 947px;
        margin: 0 auto;
        gap: calc(var(--global-space) * 2);
        justify-items: center;

        li {
          width: 148px;

          img {
            margin: auto;
          }
        }
      }
    }
  }

  &__why {
    &__wrapper {
      position: relative;
      padding: calc(var(--BodySpace-padding)) 0;
      background-color: #3a4f5f;

      @media (max-width: $screen-mobiles-l) {
        padding: 0;
      }

      > .container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: $screen-mobiles-l) {
          grid-template-columns: 1fr;
          padding: 0;
        }
      }

      &__img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        object-fit: cover;

        @media (max-width: $screen-mobiles-l) {
          width: 100%;
        }
      }
    }

    &__p {
      @include h2;

      position: relative;
      align-self: center;
      padding-right: calc(var(--global-space) * 3);
      color: #fff;
      text-align: center;

      @media (max-width: $screen-mobiles-l) {
        padding: calc(var(--BodySpace-padding)) calc(var(--app-padding));
      }
    }

    &__left {
      align-self: center;

      @media (max-width: $screen-mobiles-l) {
        position: relative;
      }
    }

    &__right {
      padding-left: calc(var(--global-space) * 3);

      @media (max-width: $screen-mobiles-l) {
        padding: calc(var(--global-space) * 3) calc(var(--global-space) * 1);
      }

      &__h2 {
        @include h3;

        color: #fff;
        font-family: $Poppins-Regular;
        text-align: center;
      }

      &__dl {
        display: grid;
        grid-gap: calc(var(--global-space) * 2);
        margin-top: calc(var(--global-space) * 2);
        text-align: left;
        counter-reset: counter-stats-item;

        &__item {
          display: grid;
          grid-gap: calc(var(--global-space) * 0.5);
          grid-template-columns: 30px 1fr;
          text-align: var(--p-ta);
          counter-increment: counter-stats-item;

          @media (max-width: $screen-tablets-l) {
            grid-template-columns: 1fr;
          }

          &::before {
            content: '0' counter(counter-stats-item);
            display: block;
            color: #fff;
            font-family: $Poppins-Regular;
            font-size: 19px;
            letter-spacing: 0.79px;
            text-align: var(--p-ta);
          }

          &__dt {
            color: #fff;
            font-family: $Poppins-Regular;
            font-size: 19px;
            letter-spacing: 0.79px;
            text-align: var(--p-ta);
          }

          &__ul {
            margin-top: calc(var(--global-space) * 0.5);

            &__li {
              list-style-position: inside;
              list-style-type: disc;
              color: #fff;
              font-family: $Poppins-Regular;
              font-size: 13px;
              letter-spacing: 0.54px;
              text-align: left;
            }
          }
        }
      }
    }

    &__img {
      width: 100%;
    }
  }

  &__know {
    padding-top: calc(var(--BodySpace-padding));

    &__h2 {
      @include h2;

      color: #1a1a1a;
      text-align: center;
    }

    &__p {
      margin-top: calc(var(--global-space) * 1);
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
      text-align: center;
    }

    &__blogs {
      margin-top: calc(var(--global-space) * 4);

      @media (max-width: $screen-mobiles) {
        margin-top: calc(var(--global-space) * 2);
      }

      &__p {
        @include h3;

        align-self: end;
        color: #1a1a1a;
        font-family: $Poppins-Regular;
        justify-self: start;

        &::after {
          content: '';
          display: block;
          width: 35px;
          height: 16px;
          margin-top: calc(var(--global-space) * 1);
          background-image: url(/images/Index__know__blogs__p.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;

          @media (max-width: $screen-tablets) {
            margin-top: calc(var(--global-space) * 0.5);
          }
        }
      }

      &__feature {
        color: #1a1a1a;
        font-family: $Poppins-Regular;
        font-size: 28px;
        letter-spacing: 1.17px;

        @media (max-width: $screen-tablets) {
          display: none;
        }
      }

      &__latest {
        grid-column: span 3;
        grid-row: 2 / 3;
        color: #1a1a1a;
        font-family: $Poppins-Regular;
        font-size: 28px;
        letter-spacing: 1.17px;

        @media (max-width: $screen-tablets) {
          display: none;
        }
      }

      &__dl {
        display: grid;
        grid-gap: calc(var(--global-space) * 3) calc(var(--global-space) * 1);
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: $screen-tablets) {
          grid-gap: calc(var(--global-space) * 1.5);
          grid-template-columns: repeat(2, 1fr);
          justify-content: center;
        }

        @media (max-width: $screen-mobiles) {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

.BlogItem {
  display: grid;
  grid-template-rows: max-content 1fr;
  // border: 1px solid rgba(151, 151, 151, 0.32);

  --BlogItem__image__a-o: 0;
  --BlogItem__image__svg-d: block;
  --BlogItem__image__img-h: 100%;
  --BlogItem__image__img-mh: unset;
  --BlogItem__image__img-pos: absolute;

  // @media (max-width: $screen-mobiles) {
  //   --BlogItem__image__svg-d: none;
  //   --BlogItem__image__img-h: unset;
  //   --BlogItem__image__img-mh: 300px;
  //   --BlogItem__image__img-pos: static;
  // }

  &:hover {
    --BlogItem__image__a-o: 1;
  }

  &--leader {
    grid-column: span 2;

    @media (min-width: $screen-tablets + 1px) {
      --BlogItem__image__svg-d: none;
      --BlogItem__image__img-h: unset;
      --BlogItem__image__img-mh: 400px;
      --BlogItem__image__img-pos: static;
    }

    @media (max-width: $screen-tablets) {
      grid-column: unset;
    }
  }

  &__image {
    display: grid;
    position: relative;
    background-color: rgba(151, 151, 151, 0.32);

    * {
      grid-area: 1 / 1 / 2 / 2;

      @media (max-width: $screen-mobiles) {
        grid-area: unset;
      }
    }

    &__svg {
      display: var(--BlogItem__image__svg-d);
    }

    &__img {
      position: var(--BlogItem__image__img-pos);
      top: 0;
      left: 0;
      width: 100%;
      height: var(--BlogItem__image__img-h);
      max-height: var(--BlogItem__image__img-mh);
      object-fit: cover;
    }

    &__a {
      display: grid;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      transition: opacity 0.2s;
      opacity: var(--BlogItem__image__a-o);
      background: rgba(255, 118, 94, 0.9);
      color: #fff;
      font-family: $Poppins-SemiBold;
      text-align: center;
      object-fit: cover;
      will-change: opacity;

      @include h3;
    }
  }

  &__wrapper {
    display: grid;
    grid-gap: calc(var(--global-space) - 4px);
    // grid-template-rows: 1fr max-content;
    // padding: calc(var(--global-space) * 1);
  }

  &__dt {
    margin-top: calc(var(--global-space) - 4px);
    color: #1a1a1a;
    font-family: $Poppins-Regular;
    font-size: 19px;
    letter-spacing: 0.79px;
  }

  &__dd {
    color: #e78979;
    font-family: $Poppins-SemiBold;
    font-size: 13px;
    letter-spacing: 0.54px;
    text-transform: uppercase;
  }
}

.DualSlide {
  --DualSlide__h2-c: #1a1a1a;
  --DualSlide__h2__span-c: #03e2a7;
  --DualSlide__p-c: #1a1a1a;
  --DualSlide__a-b-bgi: url(/images/DualSlide__a.svg);

  position: relative;

  @media (max-width: $DualSlide-breakpoint) {
    height: unset !important;
  }

  &__contents {
    display: grid;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    @media (max-width: $DualSlide-breakpoint) {
      position: static;
      grid-template-columns: 1fr;
      height: unset;
      overflow: visible;
    }

    &--inactive {
      position: absolute;
      top: 50%;
    }

    &--free-height {
      position: static;
      height: unset;
      overflow: unset;

      .DualSlide__wrapper {
        position: relative;
        align-items: unset;
        height: unset !important;
        padding-top: 0 !important;

        .DualSlide__wrapper__content {
          align-self: unset;
          padding-top: var(--BodySpace-padding);
          padding-bottom: var(--BodySpace-padding);

          @media (max-width: $screen-mobiles) {
            padding-bottom: 0;
          }
        }
      }
    }

    > * {
      transition: var(--DualSlide-ts) var(--DualSlide-ts-d);
      transition-timing-function: var(--ease-in-out-cubic);
      will-change: var(--DualSlide-ts);

      &:nth-of-type(2n-1) {
        transform: translateY(var(--DualSlide-tly-l));

        @media (max-width: $DualSlide-breakpoint) {
          transform: none;
        }
      }

      &:nth-of-type(2n) {
        transform: translateY(var(--DualSlide-tly));

        @media (max-width: $DualSlide-breakpoint) {
          transform: none;
        }
      }

      &:first-child {
        @media (max-width: $DualSlide-breakpoint) {
          margin-bottom: calc(20vh * -1 + calc(var(--global-space) * 2));
        }
      }
    }
  }

  &__content {
    &--0 {
      @media (max-width: $DualSlide-breakpoint) {
        grid-gap: calc(var(--global-space) * 1) !important;
      }
    }

    &--1 {
      @media (max-width: $DualSlide-breakpoint) {
        grid-row: 6 / 7;
        background-color: #2784f5;
      }
    }

    &--2 {
      @media (max-width: $DualSlide-breakpoint) {
        grid-row: 4 / 5;
        background-color: #00c793;
      }
    }

    &--3 {
      @media (max-width: $DualSlide-breakpoint) {
        grid-row: 3 / 4;
      }
    }

    &--4 {
      @media (max-width: $DualSlide-breakpoint) {
        grid-row: 5 / 6;
      }
    }

    &--5 {
      @media (max-width: $DualSlide-breakpoint) {
        grid-row: 2 / 3;
      }
    }

    &--6 {
      @media (max-width: $DualSlide-breakpoint) {
        grid-row: 2 / 3;
      }
    }

    &--7 {
      @media (max-width: $DualSlide-breakpoint) {
        background-color: #2784f5;
      }
    }

    &--8 {
      @media (max-width: $DualSlide-breakpoint) {
        grid-row: 2 / 3;
      }
    }

    &--9 {
      @media (max-width: $DualSlide-breakpoint) {
        background-color: #ff765e;
      }
    }

    &--10 {
      @media (max-width: $DualSlide-breakpoint) {
        margin-bottom: 0 !important;
      }
    }
  }

  &__wrapper {
    display: grid;
    align-items: center;
    height: 100vh;
    padding-left: calc(var(--global-space) * 1);

    --DualSlide__wrapper__content-p: calc(var(--global-space) * 3);
    --DualSlide__wrapper__content-pr: var(--DualSlide__wrapper__content-p);
    --DualSlide__wrapper__content-pl: 0;
    --DualSlide__wrapper__content-as: center;
    --DualSlide__wrapper__content-js: end;

    &--alt {
      justify-content: start;
      padding-right: calc(var(--global-space) * 1);
      padding-left: 0;

      --DualSlide__wrapper__content-pr: 0;
      --DualSlide__wrapper__content-pl: var(--DualSlide__wrapper__content-p);
    }

    @media (max-width: $screen-tablets) {
      --DualSlide__wrapper__content-p: calc(var(--global-space) * 2);
    }

    @media (max-width: $DualSlide-breakpoint) {
      height: unset;
      padding: calc(var(--global-space) * 5) calc(var(--global-space) * 1);

      --DualSlide__wrapper__content-p: 0;
      --DualSlide__wrapper__content-as: unset;
    }

    &--green {
      background-color: #00c793;

      --DualSlide__h2-c: #fff;
      --DualSlide__p-c: #fff;
    }

    &--blue {
      background-color: #2784f5;

      --DualSlide__h2-c: #fff;
      --DualSlide__p-c: #fff;
    }

    &--red {
      background-color: #ff765e;

      --DualSlide__h2-c: #fff;
      --DualSlide__p-c: #fff;
    }

    &--h1 {
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 20vh;
      padding-top: 29vh;

      --DualSlide__wrapper__content-as: start;

      @media (max-width: $DualSlide-breakpoint) {
        grid-gap: calc(var(--global-space) * 3);
        padding-top: calc(var(--Nav-height) + calc(var(--global-space) * 2));
        padding-bottom: 0;
      }

      &--blue {
        --DualSlide__h2__span-c: #2784f5;
        --DualSlide__a-b-bgi: url(/images/DualSlide__a--blue.svg);
      }

      &--red {
        --DualSlide__h2__span-c: #ff765e;
        --DualSlide__a-b-bgi: url(/images/DualSlide__a--red.svg);
      }

      &--contact {
        grid-template-rows: unset;

        @media (max-width: $DualSlide-breakpoint) {
          padding-bottom: var(--BodySpace-padding);
        }
      }

      &--contact-green {
        padding-right: calc(var(--global-space) * 1);
        padding-left: 0;
        background-color: #03e2a7;

        --DualSlide__wrapper__content-pr: 0;
        --DualSlide__wrapper__content-pl: var(--DualSlide__wrapper__content-p);
        --DualSlide__wrapper__content-js: start;

        @media (max-width: $DualSlide-breakpoint) {
          padding: calc(var(--global-space) * 3) calc(var(--global-space) * 1) !important;
        }

        .DualSlide__h2 {
          @media (max-width: $DualSlide-breakpoint) {
            display: none;
          }
        }
      }
    }

    &__bar {
      position: relative;
      height: 100%;

      @media (max-width: $DualSlide-breakpoint) {
        margin-right: calc(var(--global-space) * -1);
        margin-left: calc(var(--global-space) * -1);
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 50vw;
        height: 100%;
        background-color: var(--DualSlide__h2__span-c);

        @media (max-width: $DualSlide-breakpoint) {
          width: 100%;
        }
      }
    }

    &__content {
      align-self: var(--DualSlide__wrapper__content-as);
      width: 100%;
      max-width: calc(var(--app-width) / 2);
      padding: calc(var(--global-space) * 1) 0;
      padding-right: var(--DualSlide__wrapper__content-pr);
      padding-left: var(--DualSlide__wrapper__content-pl);
      justify-self: var(--DualSlide__wrapper__content-js);

      @media (max-width: $DualSlide-breakpoint) {
        max-width: unset;
      }
    }
  }

  &__h2 {
    @include h2;

    color: var(--DualSlide__h2-c);

    span {
      color: var(--DualSlide__h2__span-c);
    }
  }

  &__p {
    margin-top: calc(var(--global-space) * 1.5);
    color: var(--DualSlide__p-c);
    font-family: $Poppins-Regular;
    font-size: 19px;
    letter-spacing: 0.79px;
    text-align: var(--p-ta);

    &--small {
      font-size: 13px;
      letter-spacing: 0.54px;
    }

    span {
      color: #2784f5;
      font-family: $Poppins-SemiBold;
    }
  }

  .Buttons {
    display: grid;
    justify-content: start;
    margin-top: calc(var(--global-space) * 2);
  }

  &__a {
    display: grid;

    &::before {
      content: '';
      display: block;
      width: 23.87px;
      height: 34.38px;
      background-image: var(--DualSlide__a-b-bgi);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &__what {
    @include h3;

    display: grid;
    grid-gap: calc(var(--global-space) * 1);
    grid-template-columns: 1fr max-content;
    align-items: center;
    margin-top: calc(var(--global-space) * 2);
    color: #fff;
    font-family: $Poppins-Regular;

    @media (max-width: $DualSlide-breakpoint) {
      font-size: 22px;
      letter-spacing: 0.92px;
    }

    &::after {
      content: '';
      display: block;
      width: 48px;
      height: 22.01px;
      background-image: url(/images/DualSlide__what.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &__image {
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media (max-width: $DualSlide-breakpoint) {
      height: auto;
      padding-left: calc(var(--global-space) * 3.5);
    }

    &--alt {
      @media (max-width: $DualSlide-breakpoint) {
        padding-right: calc(var(--global-space) * 3.5);
        padding-left: 0;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: $DualSlide-breakpoint) {
        height: auto;
      }
    }
  }
}

.GetStarted {
  position: relative;
  padding: calc(var(--BodySpace-padding)) 0;
  background-color: #52686c;

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > .container {
    position: relative;
  }

  &__h2 {
    @include h2;

    color: #fff;
    text-align: center;
  }

  &__p {
    margin-top: calc(var(--global-space) * 1);
    color: #fff;
    font-family: $Poppins-Regular;
    font-size: 19px;
    letter-spacing: 0.79px;
    text-align: center;

    a {
      color: #03e2a7;
    }
  }

  &__form {
    display: grid;
    grid-gap: calc(var(--global-space) * 1);
    max-width: 550px;
    margin-top: calc(var(--global-space) * 2);
    margin-right: auto;
    margin-left: auto;

    &__buttons {
      display: grid;
      justify-content: center;
    }

    &__text {
      margin-top: 10px;
      color: #fff;
      font-size: 16px;
      text-align: left;
    }
  }
}

.Input {
  display: block;
  width: 100%;
  padding: calc(var(--global-space) * 0.75);
  border: none;
  border-radius: 0;
  background-color: #fff;
  color: #1a1a1a;
  font-family: $Poppins-Regular;
  font-size: 13px;
  letter-spacing: 0.54px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &--select {
    background: #fff url('/images/arrow-down.svg') no-repeat 97% 50%;
  }
}

.InputFile {
  display: grid;
  position: relative;
  grid-gap: calc(var(--global-space) * 0.75);
  grid-template-columns: max-content 1fr;
  cursor: pointer;

  &__button {
    display: grid;
    align-items: center;
    padding: 0 calc(var(--global-space) * 0.75);
    background-color: #c0d4ce;
    color: #1a1a1a;
    font-family: $Poppins-Regular;
    font-size: 13px;
    letter-spacing: 0.54px;
    line-height: 1;
  }

  &__span {
    overflow: hidden;
    color: #1a1a1a;
    font-family: $Poppins-Regular;
    font-size: 13px;
    letter-spacing: 0.54px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
}

@mixin company-map {
  width: 100%;
  height: 160px;
  margin-top: 32px;
  border-radius: 8px;
}

@mixin bg-with-image {
  padding: 76px 41px;
  background: linear-gradient(
      0deg,
      rgba(94, 126, 152, 0.9),
      rgba(94, 126, 152, 0.9)
    ),
    url('/images/footer-bg.svg');
  background-repeat: no-repeat;
  background-position: top 0;
  background-size: cover;
}

.form {
  display: flex;
  position: absolute;
  bottom: 47px;
  left: 53px;
  flex-direction: column;
  max-width: 730px;
  padding: 36px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  color: #000;
  gap: 16px;

  @media (min-width: $screen-desktop) {
    left: 100px;
  }

  @media (max-width: $screen-tablets-l) {
    position: static;
    width: 80%;
    margin: auto;
  }

  @media (max-width: $screen-mobiles) {
    padding: 16px;
  }

  .title {
    font-size: 32px;
    font-weight: 700;

    h3 {
      color: var(--foundation-blue-darker, #214f44);
      font-size: 36px;
      font-weight: 700;
    }

    @media (max-width: $screen-tablets-l) {
      text-align: center;
    }

    @media (max-width: $screen-mobiles) {
      display: none;
    }
  }

  .title-mobile {
    display: none;

    @media (max-width: $screen-mobiles) {
      display: block;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }
  }

  span {
    @include span();
  }

  .fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
    margin-bottom: 12px;

    @media (max-width: $screen-mobiles) {
      grid-template-columns: 1fr;
    }
  }

  .condition {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding: 16px 0;
    gap: 16px;

    .checkbox {
      font-family: Anakotmai, sans-serif;

      .Component__checkbox__input-label,
      .label,
      a {
        color: #8a8a8a;
        font-size: 16px;
        font-weight: 500;

        @media (max-width: $screen-mobiles) {
          font-size: 12px;
        }
      }

      .Component__checkbox__input-container {
        align-items: center;
        width: 24px;
        height: 24px;
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        color: #8a8a8a;

        &--checked {
          border: none;
          background-color: var(--foundation-green-dark);

          path {
            stroke: white;
          }
        }

        @media (max-width: $screen-mobiles) {
          width: 18px;
          height: 18px;
        }
      }

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .form-button {
    @include submit-button;

    width: 100%;
    margin-top: 16px;
  }
}

.FooterWithForm {
  color: var(--text-color-invert);
  font-family: Anakotmai, sans-serif;

  .container {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    max-width: none;
    height: 634px;
    padding: 0;

    @media (max-width: $screen-tablets-l) {
      grid-template-columns: 1fr;
    }

    &__info {
      @include bg-with-image();

      padding: calc(var(--global-space) * 2.4) calc(var(--global-space) * 1.8);
      color: white;

      @media (min-width: $screen-desktop) {
        padding-left: 160px;
      }

      .address {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        gap: 32px;

        @media (max-width: $screen-mobiles) {
          font-size: 16px;
        }

        .socials {
          display: flex;
          gap: 20px;
        }
      }

      .contact {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        font-size: 26px;

        a {
          width: 240px;
        }

        @media (max-width: $screen-mobiles) {
          font-size: 20px;
        }

        iframe {
          @include company-map;

          max-width: 500px;
        }
      }
    }

    &__form {
      position: relative;
      background-color: #3a4f5f;

      @media (min-width: $screen-desktop) {
        padding-right: 60px;
      }

      @media (max-width: $screen-tablets-l) {
        grid-row-start: 1;
        padding: 32px 0;
      }
    }
  }
}

.Footer {
  padding: calc(var(--BodySpace-padding) * 0.5) 0;
  background-color: #52686c;

  > .container {
    display: grid;
    grid-gap: calc(var(--global-space) * 2) calc(var(--global-space) * 1);
    grid-template-columns: 1fr max-content;
    align-items: start;

    @media (max-width: $screen-mobiles-l) {
      grid-template-columns: 1fr;
    }
  }

  &__tl,
  &__tr {
    display: grid;
    grid-gap: calc(var(--global-space) * 1);
    justify-content: start;
  }

  &__tr {
    @media (max-width: $screen-mobiles-l) {
      display: none;
    }

    &__imgs-group {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: repeat(auto-fill, 108px);
    }
  }

  &__bl {
    align-self: end;
  }

  &__p {
    color: #fff;
    font-family: $Poppins-Regular;
    font-size: 13px;
    letter-spacing: 0.54px;
  }

  &__a {
    color: #fff;
    font-family: $Poppins-SemiBold;
    font-size: 28px;
    letter-spacing: 1.83px;

    @media (max-width: $screen-mobiles) {
      font-size: 32px;
      letter-spacing: 0;
    }

    @media (max-width: $screen-mobiles-s) {
      font-size: 27px;
    }
  }

  &__tel {
    color: #fff;
    font-family: $Poppins-Regular;
    font-size: 28px;
    letter-spacing: 1.17px;
  }

  &__links {
    display: grid;
    grid-gap: calc(var(--global-space) * 1);
    grid-template-columns: repeat(8, max-content);
    align-items: center;

    @media (max-width: $screen-tablets-l) {
      grid-template-columns: repeat(3, max-content);
    }

    @media (max-width: $screen-mobiles-s) {
      grid-template-columns: repeat(2, max-content);
    }

    &__a {
      color: #fff;
      font-family: $Poppins-Regular;
      font-size: 13px;
      letter-spacing: 0.54px;
    }

    &__socials {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: auto auto;
    }

    &__social {
      &::before {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background-image: url(/images/Footer__links__social.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }

      &--linkedin::before {
        background-image: url(/images/Footer__links__social--linkedin.svg);
      }
    }
  }
}

.Services {
  --Services__nav-h: 140px;

  &__nav {
    position: sticky;
    position: -webkit-sticky;
    z-index: 1;
    top: var(--Nav-height);
    height: var(--Services__nav-h);
    background-color: rgba(#fff, 0.3);

    @media (max-width: $screen-tablets-l) {
      display: none;
    }

    > .container {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: repeat(auto-fit, minmax(1px, max-content));
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }

    &__a {
      transition: color 0.2s, font-weight 0.2s;
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
      will-change: color, font-weight;

      &--active {
        color: #03e2a7;
        font-family: $Poppins-SemiBold;
      }
    }
  }

  &__boxes {
    display: grid;
    grid-gap: calc(var(--BodySpace-padding));
  }

  &__box {
    position: relative;

    &__handle {
      transform: translateY(
        calc((var(--Services__nav-h) + var(--Nav-height)) * -1)
      );
    }

    > .container {
      display: grid;
      grid-gap: calc(var(--global-space) * 2) calc(var(--global-space) * 8);
      grid-template-columns: 1fr 480px;

      @media (max-width: $screen-tablets-l) {
        grid-gap: calc(var(--global-space) * 2) calc(var(--global-space) * 4);
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: $screen-tablets) {
        grid-template-columns: 1fr;
      }
    }

    &__h2 {
      @include h2;

      color: #1a1a1a;
    }

    &__left {
      &__wrapper {
        position: sticky;
        position: -webkit-sticky;
        top: calc(var(--global-space) * 2 + var(--Nav-height));

        @media (max-width: $screen-tablets-l) {
          top: calc(var(--Nav-height) + calc(var(--global-space) * 2));
        }
      }

      &__try {
        margin-top: calc(var(--global-space) * 1);
        margin-bottom: calc(var(--global-space) * -2);

        &__p {
          color: #1a1a1a;
          font-family: $Poppins-Regular;
          font-size: 19px;
          letter-spacing: 0.79px;
        }

        .Buttons {
          margin-top: calc(var(--global-space) * 0.5);
        }

        &__a {
          padding: calc(var(--global-space) * 0.25)
            calc(var(--global-space) * 1);
          border-radius: 11px;
          background-color: #02d4d6;
          color: #fff;
          font-family: $Poppins-SemiBold;
          font-size: 19px;
          letter-spacing: 0.79px;
        }
      }

      &__img {
        margin-top: calc(var(--BodySpace-padding));

        @media (max-width: $screen-tablets) {
          margin-top: calc(var(--global-space) * 1.5);
        }
      }
    }

    &__right {
      padding-top: calc(var(--BodySpace-padding));

      @media (max-width: $screen-tablets-l) {
        padding-top: 0;
      }

      &__dl {
        display: grid;
        grid-gap: calc(var(--global-space) * 1);

        &__item {
          padding: calc(var(--global-space) * 1.5);
          border: 1px solid #c6c6c6;
          border-radius: 10px;

          &__dt {
            @include h3;

            display: flex;
            align-items: center;
            color: #1a1a1a;
            font-family: $Poppins-SemiBold;

            @media (max-width: $screen-mobiles) {
              font-family: $Poppins-Regular;
            }

            &__span {
              @media (max-width: $screen-tablets) {
                flex: 1;
              }

              br {
                @media (max-width: $screen-mobiles) {
                  display: none;
                }
              }
            }

            &::after {
              content: '';
              display: block;
              flex: 0 0 35px;
              width: 35px;
              height: 16px;
              margin-left: calc(var(--global-space) * 1);
              background-image: url(/images/Services__box__right__dl__item__dt.svg);
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
            }
          }

          &__dd {
            margin-top: calc(var(--global-space) * 0.5);
            color: #1a1a1a;
            font-family: $Poppins-Regular;
            font-size: 13px;
            letter-spacing: 0.54px;

            @media (max-width: $screen-tablets) {
              margin-top: calc(var(--global-space) * 1.5);
            }
          }
        }
      }
    }
  }

  &__more {
    margin-top: var(--BodySpace-padding);

    &__h3 {
      @include h2;

      margin-bottom: calc(var(--global-space) * 3);
      color: #1a1a1a;
      text-align: center;

      @media (max-width: $screen-mobiles-l) {
        margin-bottom: calc(var(--global-space) * 1.5);
      }
    }

    &__dl {
      display: grid;
      grid-gap: calc(var(--global-space) * 0.5) calc(var(--global-space) * 2);
      grid-template-columns: repeat(3, 1fr);
      margin-top: calc(var(--global-space) * 3);
      margin-bottom: calc(var(--global-space) * 3);

      @media (max-width: $screen-tablets-xl) {
        grid-gap: calc(var(--global-space) * 0.5) calc(var(--global-space) * 1);
      }

      @media (max-width: $screen-mobiles-l) {
        grid-gap: 0;
        grid-template-columns: 1fr;
        margin-top: calc(var(--global-space) * 1);
        margin-bottom: 0;
      }

      &__item {
        display: contents;

        &__dt {
          grid-row: 1 / 2;
          color: #1a1a1a;
          font-family: $Poppins-Regular;
          font-size: 19px;
          letter-spacing: 0.79px;

          @media (max-width: $screen-mobiles-l) {
            grid-row: unset;
            margin-bottom: calc(var(--global-space) * 0.5);
          }
        }

        &__dd {
          color: #1a1a1a;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;

          @media (max-width: $screen-mobiles-l) {
            margin-bottom: calc(var(--global-space) * 1);
          }
        }
      }
    }

    &__center {
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
      text-align: center;

      @media (max-width: $screen-mobiles-l) {
        text-align: left;
      }
    }

    &__small {
      display: block;
      margin-top: calc(var(--global-space) * 1);
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 13px;
      letter-spacing: 0.54px;
      text-align: center;

      @media (max-width: $screen-mobiles-l) {
        margin-top: calc(var(--global-space) * 0.5);
        text-align: left;
      }
    }

    &__ps {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
    }

    &__p {
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;

      strong {
        font-family: $Poppins-SemiBold;
      }
    }
  }
}

.Cases {
  &__hero {
    display: block;

    > .container {
      display: grid;
      grid-gap: calc(var(--global-space) * 1.5);
      grid-template-columns: 1fr 1fr;
      align-items: center;

      @media (max-width: $screen-mobiles-l) {
        grid-gap: calc(var(--global-space) * 2);
        grid-template-columns: 1fr;
      }
    }

    &__img {
      @media (max-width: $screen-mobiles-l) {
        width: 100%;
      }
    }

    &__h2 {
      @include h2;

      color: #1a1a1a;
    }

    &__p {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      margin-top: calc(var(--global-space) * 2);
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 13px;
      letter-spacing: 0.54px;
      text-align: var(--p-ta);

      @media (max-width: $screen-mobiles-l) {
        margin-top: calc(var(--global-space) * 1);
      }
    }

    &__buttons {
      display: grid;
      grid-template-columns: max-content;
      margin-top: calc(var(--global-space) * 4);

      @media (max-width: $screen-mobiles-l) {
        margin-top: calc(var(--global-space) * 2);
      }
    }
  }

  &__dl {
    margin-top: calc(var(--BodySpace-padding));

    > .container {
      display: grid;
      grid-gap: calc(var(--global-space) * 1.5);
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: $screen-mobiles-l) {
        grid-template-columns: 1fr;
      }
    }

    &__item {
      display: grid;
      grid-gap: calc(var(--global-space) * 2);
      align-content: space-between;
      padding: calc(var(--global-space) * 1.5);
      border: 1px solid rgba(151, 151, 151, 0.32);
      border-radius: 10px;

      @media (max-width: $screen-tablets-l) {
        grid-gap: calc(var(--global-space) * 1.5) calc(var(--global-space) * 1);
        grid-template-columns: max-content 1fr;
        align-content: space-between;
      }

      @media (max-width: $screen-mobiles) {
        padding: calc(var(--global-space) * 1.5) calc(var(--global-space) * 1);
      }

      &__top {
        display: grid;
        grid-gap: calc(var(--global-space) * 1.5);
        grid-template-columns: 160px 1fr;

        @media (max-width: $screen-tablets-l) {
          display: contents;
        }
      }

      &__img {
        width: 160px;

        @media (max-width: $screen-tablets-l) {
          grid-row: 2 / 3;
          width: 50px;
          height: 50px;
          object-fit: cover;
        }
      }

      &__wrapper {
        @media (max-width: $screen-tablets-l) {
          grid-column: 1 / 3;
        }
      }

      &__dt {
        @include h3;

        color: #1a1a1a;
        font-family: $Poppins-SemiBold;
      }

      &__dd {
        display: grid;
        grid-gap: calc(var(--global-space) * 1);
        margin-top: calc(var(--global-space) * 1);
        color: #1a1a1a;
        font-family: $Poppins-Regular;
        font-size: 13px;
        letter-spacing: 0.54px;
        text-align: var(--p-ta);
      }

      .Buttons {
        @media (max-width: $screen-tablets-l) {
          grid-row: 2 / 3;
          margin-top: 0;
        }
      }

      &__a {
        display: grid;
        grid-gap: calc(var(--global-space) * 1);
        grid-template-columns: max-content max-content;
        align-items: center;
        color: #1a1a1a;
        font-family: $Poppins-Regular;
        font-size: 19px;
        letter-spacing: 0.79px;

        &::after {
          content: '';
          display: block;
          width: 35px;
          height: 15px;
          background-image: url(/images/Cases__dl__item__a.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }
      }
    }
  }
}

.Button {
  display: block;
  padding: calc(var(--global-space) * 0.75) calc(var(--global-space) * 1.5);
  border: none;
  background-color: #03e2a7;
  color: #1a1a1a;
  font-family: $Poppins-Regular;
  font-size: 19px;
  letter-spacing: 0.79px;

  &--gray {
    background-color: #52686c;
    color: #fff;
  }

  &--orange {
    background-color: #ff765e;
    color: #fff;
  }
}

.Buttons {
  display: grid;
  justify-content: start;

  &--jc-c {
    justify-content: center;
  }
}

.Products {
  &__p {
    color: #1a1a1a;
    font-family: $Poppins-Regular;
    font-size: 19px;
    letter-spacing: 0.79px;
    text-align: center;
  }

  &__h2 {
    @include h2;

    color: #1a1a1a;
  }

  &__content {
    display: grid;
    grid-gap: calc(var(--BodySpace-padding) * 0.5);
    grid-template-columns: max-content 1fr;
    margin-top: calc(var(--BodySpace-padding));

    @media (max-width: $screen-tablets) {
      grid-template-columns: 1fr;
    }
  }

  &__dl {
    display: grid;
    grid-gap: calc(var(--global-space) * 2.5);
    grid-template-columns: 1fr 1fr;

    @media (max-width: $screen-tablets-l) {
      grid-gap: calc(var(--global-space) * 1.5);
    }

    @media (max-width: $screen-mobiles) {
      grid-template-columns: minmax(auto, 320px);
      justify-content: center;
    }

    &__item {
      display: grid;
      grid-template-rows: max-content 1fr;
      border: 1px solid rgba(151, 151, 151, 0.32);

      &:not([href]) {
        cursor: default;
      }

      &__image {
        display: grid;
        align-items: center;
        justify-content: center;
        padding: calc(var(--global-space) * 4) calc(var(--global-space) * 1.5);
        background-color: rgba(218, 218, 218, 0.3);

        &__wrapper {
          display: grid;
          align-items: center;
          justify-content: center;
          width: 128px;
          height: 128px;
          overflow: hidden;
          border-radius: 30px;
          background-color: #fff;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        }
      }

      &__wrapper {
        display: grid;
        grid-gap: calc(var(--global-space) * 1.5);
        grid-template-rows: max-content 1fr max-content;
        padding: calc(var(--global-space) * 1.5);
      }

      &__dt {
        @include h3;

        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        color: #1a1a1a;
        font-family: $Poppins-SemiBold;

        &__span {
          display: block;
          margin-left: calc(var(--global-space) * 1);
          color: #1a1a1a;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;
        }
      }

      &__dd {
        color: #1a1a1a;
        font-family: $Poppins-Regular;
        font-size: 13px;
        letter-spacing: 0.54px;
        text-align: var(--p-ta);
      }

      &__a {
        &::before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          background-image: url(/images/Products__dl__item__a.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }
      }
    }
  }
}

.About {
  &__p {
    color: #1a1a1a;
    font-family: $Poppins-Regular;
    font-size: 19px;
    letter-spacing: 0.79px;
    text-align: center;
  }

  &__dl {
    display: grid;
    grid-gap: calc(var(--global-space) * 1.5);
    grid-template-columns: repeat(3, 1fr);
    margin-top: var(--BodySpace-padding);

    @media (max-width: $screen-mobiles-l) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $screen-mobiles) {
      grid-template-columns: minmax(auto, 320px);
      justify-content: center;
    }

    &__item {
      display: grid;
      grid-gap: calc(var(--global-space) * 1.5);
      grid-template-rows: max-content 1fr;
      padding: calc(var(--global-space) * 1.5);
      padding-top: calc(var(--global-space) * 3);
      border: 1px solid rgba(151, 151, 151, 0.32);
      border-radius: 10px;

      &__dt {
        margin-top: calc(var(--global-space) * 1);
        color: #1a1a1a;
        font-family: $Poppins-SemiBold;

        @include h3;
      }

      &__dd {
        color: #1a1a1a;
        font-family: $Poppins-Regular;
        font-size: 13px;
        letter-spacing: 0.54px;
        text-align: var(--p-ta);
      }
    }
  }

  &__culture {
    display: grid;
    grid-gap: calc(var(--global-space) * 3);
    grid-template-columns: max-content 1fr;
    margin-top: var(--BodySpace-padding);

    @media (max-width: $screen-tablets-l) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: $screen-tablets) {
      grid-template-columns: 1fr;
    }

    &__right {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      align-content: space-between;
      padding-top: calc(var(--global-space) * 2);

      @media (max-width: $screen-tablets) {
        grid-gap: calc(var(--global-space) * 2);
        padding-top: 0;
      }

      &__h3 {
        @include h2;

        color: #1a1a1a;

        span {
          color: #2784f5;
        }
      }

      &__p {
        margin-top: calc(var(--global-space) * 2.5);
        color: #1a1a1a;
        font-family: $Poppins-Regular;
        font-size: 19px;
        letter-spacing: 0.79px;
        text-align: var(--p-ta);

        @media (max-width: $screen-tablets) {
          margin-top: calc(var(--global-space) * 1);
        }
      }

      &__a {
        padding: calc(var(--global-space) * 1.5);
        border: 1px solid #c6c6c6;
        border-radius: 10px;

        @media (max-width: $screen-tablets) {
          justify-self: start;
        }

        &__span {
          @include h3;

          display: grid;
          grid-gap: calc(var(--global-space) * 1);
          grid-template-columns: max-content max-content;
          align-items: center;
          color: #1a1a1a;
          font-family: $Poppins-Regular;

          @media (max-width: $screen-mobiles-s) {
            grid-template-columns: 1fr max-content;
          }

          &::after {
            content: '';
            display: block;
            width: 35px;
            height: 16px;
            background-image: url(/images/About__culture__right__a__span.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }

        &__small {
          margin-top: calc(var(--global-space) * 0.5);
          color: #1a1a1a;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;
        }
      }
    }
  }
}

.SelectLang {
  --padding: calc(var(--global-space) * 0.2) calc(var(--global-space) * 0.8);

  position: relative;
  margin-left: calc(var(--global-space) * 1.5);
  border: 1px solid var(--color-border-gray);
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0.54px;
  cursor: pointer;

  @media screen and (max-width: $screen-mobiles-l) {
    margin-top: 0;
    margin-left: calc(var(--global-space) * 1);
  }

  &:hover {
    .SelectLang__popup {
      visibility: visible;
      opacity: 1;
    }
  }

  &__value {
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    justify-content: center;
    padding: var(--padding);
    font-weight: 500;
    white-space: nowrap;
  }

  &__popup {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: var(--padding);
    padding-top: calc(var(--global-space) * 1.5);
    transition: opacity var(--transition-duration) var(--ease-in-out-cubic);
    border: 1px solid #dcdcdc;
    opacity: 0;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.04);

    a {
      color: var(--color-primary) !important;
      color: #000;
      font-family: Anakotmai, sans-serif;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  svg {
    width: 8px;
    margin-left: 4px;
  }
}

.Nav {
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  top: 0;
  padding: calc(var(--global-space) * 0.6) calc(var(--global-space) * 2.7);
  transition: transform var(--transition-duration) var(--ease-in-out-cubic);
  background-color: white;

  &.Nav--data-labeling {
    background-color: white;
  }

  @media screen and (max-width: $screen-tablets) {
    align-items: center;
    padding: 0;
  }

  &--scroll {
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

    &.Nav--ai-training {
      background-color: #073658;
    }

    &.Nav--data-analytics {
      background-color: #2b3083;
    }

    &.Nav--data-labeling {
      background-color: white;
    }
  }

  &--hide {
    transform: translateY(-100%);
  }

  &--ai-training {
    color: #fff;

    .Nav__sub-menus {
      background-color: #073658;

      a:hover {
        background-color: #0a4976;

        @media screen and (max-width: $screen-tablets) {
          background-color: transparent;
        }
      }
    }

    .Nav__links {
      align-items: end !important;

      @media screen and (max-width: $screen-tablets) {
        background-color: #073658;
      }
    }

    .SelectLang__popup {
      border-color: rgba(255, 255, 255, 0.2);
      background-color: #073658;

      a {
        color: inherit;
      }
    }
  }

  &--data-analytics {
    color: #fff;

    .Nav__sub-menus {
      background-color: #2b3083;

      a:hover {
        background-color: #33389b;

        @media screen and (max-width: $screen-tablets) {
          background-color: transparent;
        }
      }
    }

    .Nav__links {
      @media screen and (max-width: $screen-tablets) {
        background-color: #2b3083;
      }
    }

    .SelectLang__popup {
      border-color: rgba(255, 255, 255, 0.2);
      background-color: #2b3083;

      a {
        color: var(--color-primary) !important;
      }
    }
  }

  .container {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    padding: 0;

    a {
      min-width: 250px;

      @media screen and (max-width: $screen-tablets-xl) {
        min-width: 101px;
      }

      svg {
        @media screen and (max-width: $screen-tablets-xl) {
          width: 101px;
        }
      }
    }

    @media screen and (max-width: $screen-tablets) {
      justify-content: space-between;
      height: 60px;
      padding: 0 25px;
    }

    .mobile {
      display: none;

      @media screen and (max-width: $screen-tablets) {
        display: flex;
        flex-direction: row;
        justify-content: end;
        width: 100%;
        gap: 24px;

        &__lang {
          display: none;
          color: black;

          @media screen and (max-width: $screen-tablets) {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          hr {
            width: 1px !important;
            height: 17px;
          }

          :first-child {
            color: #63ba8f;
            font-weight: 700;
          }

          a {
            min-width: 0;
          }

          &__value {
            padding: 0 6px;
            font-family: Anakotmai, sans-serif;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
    }
  }

  &__logo-brand {
    display: block;
    position: relative;
    z-index: 20;
    margin: auto 0;
    outline: 0;
    font-size: 0;
  }

  &__links {
    display: flex;
    flex: 1;
    align-items: baseline;
    justify-content: flex-end;
    margin-left: auto;
    color: #000;
    font-family: Anakotmai, sans-serif;
    font-weight: 500;
    white-space: nowrap;

    @media screen and (max-width: $screen-tablets) {
      visibility: hidden;
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: calc(var(--global-space) * 3) 0;
      overflow-y: auto;
      transition: none;
      opacity: 0;
      background-color: #fff;
      pointer-events: none;
    }

    &--show {
      visibility: visible;
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__item {
    position: relative;
    padding: 0 calc(var(--global-space) * 1.6);
    cursor: pointer;

    @media screen and (max-width: $screen-tablets-xl) {
      padding: 0 calc(var(--global-space) * 1.25);
    }

    @media screen and (max-width: $screen-tablets) {
      padding: 0;
      text-align: center;
    }

    &:hover {
      .Nav__sub-menus {
        visibility: visible;
        opacity: 1;
      }
    }

    &--active {
      a {
        text-decoration: none;

        &::before {
          transform: scaleX(1);
        }
      }

      &:nth-of-type(1),
      &:nth-of-type(4) {
        a {
          color: var(--color-tertiary);

          &::before {
            background-color: var(--color-tertiary);
          }
        }
      }

      &:nth-of-type(4),
      &:nth-of-type(5) {
        a {
          color: var(--color-secondary);

          &::before {
            background-color: var(--color-secondary);
          }
        }
      }

      &:nth-of-type(6) {
        a {
          color: var(--color-primary);

          &::before {
            background-color: var(--color-primary);
          }
        }
      }
    }
  }

  &__a {
    position: relative;
    transition: color var(--transition-duration);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.54px;

    &:first-of-type {
      margin-left: 0;

      @media screen and (max-width: $screen-tablets) {
        margin-top: auto;
      }
    }

    &:last-of-type {
      @media screen and (max-width: $screen-tablets) {
        margin-bottom: auto;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform var(--transition-duration) var(--ease-in-out-cubic);
      background-color: currentColor;

      @media screen and (max-width: $screen-tablets) {
        display: none;
      }
    }

    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }

    @media screen and (max-width: $screen-tablets) {
      display: block;
      margin-left: 0;
      padding: calc(var(--global-space) * 0.25) 0;
      font-size: 32px;
      letter-spacing: 1.17px;
    }

    @media screen and (max-width: $screen-mobiles) {
      font-size: 13px;
    }

    &--disabled {
      cursor: default;
      pointer-events: none;

      @media screen and (max-width: $screen-tablets) {
        display: none;
      }
    }
  }

  &__sub-menus {
    display: block;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 200px;
    // padding-top: calc(var(--global-space) * 0.6);
    transition: opacity var(--transition-duration) var(--ease-in-out-cubic);
    opacity: 0;
    background: #fff;

    @media screen and (max-width: $screen-tablets) {
      visibility: visible;
      position: relative;
      top: 0;
      left: 0;
      min-width: none;
      padding-top: 0;
      opacity: 1;
    }

    &__a {
      display: block;
      padding: calc(var(--global-space) * 0.75) calc(var(--global-space) * 2);
      transition: background-color var(--transition-duration)
        var(--ease-in-out-cubic);
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.54px;

      &:hover {
        background: #f9f9f9;

        @media screen and (max-width: $screen-tablets) {
          background: transparent;
        }
      }

      @media screen and (max-width: $screen-tablets) {
        padding: calc(var(--global-space) * 0.25) 0;
        font-size: 32px;
        letter-spacing: 1.17px;
      }

      @media screen and (max-width: $screen-mobiles) {
        font-size: 13px;
      }
    }
  }

  &__toggle {
    display: none;

    @media screen and (max-width: $screen-tablets) {
      display: block;
      position: relative;
      z-index: 10;
      border: none;
      background: white;
      box-shadow: none;
    }
  }
}

// .Nav {
//   position: fixed;
//   z-index: 2;
//   top: 0;
//   left: 0;
//   width: 100%;
//   transition: transform var(--transition-duration) var(--ease-in-out-cubic);
//   background-color: #fff;
//   &--hide {
//     transform: translateY(-100%);
//   }

//   > .container {
//     display: grid;
//     grid-gap: calc(var(--global-space) * 7);
//     grid-template-columns: max-content 1fr;
//     align-items: center;
//     height: var(--Nav-height);

//     @media screen and (max-width: $screen-tablets-xl) {
//       grid-gap: calc(var(--global-space) * 3);
//     }

//     @media (max-width: $screen-tablets) {
//       grid-gap: calc(var(--global-space) * 1);
//     }

//     @media (max-width: $screen-mobiles-l) {
//       grid-template-columns: max-content max-content;
//       justify-content: space-between;
//     }
//   }

//   &__left {
//     display: grid;
//     grid-gap: calc(var(--global-space) * 1);
//     grid-template-columns: max-content max-content;
//     align-items: center;

//     @media (max-width: $screen-tablets) {
//       grid-gap: calc(var(--global-space) * 0.5);
//     }
//   }

//   &__h1 {
//     transform: translateY(-2px);

//     @media (max-width: $screen-mobiles-s) {
//       transform: none;
//     }

//     &__a {
//       display: block;
//       width: 173px;
//       height: 27px;
//       outline: 0;
//       background-image: url(/images/Nav__h1__a.svg);
//       background-repeat: no-repeat;
//       background-position: center center;
//       background-size: cover;
//       font-size: 0;
//       text-indent: -99999px;

//       @media (max-width: $screen-mobiles-s) {
//         width: 34px;
//         height: 34px;
//         background-image: url(/images/Nav__h1__a--smo.svg);
//       }
//     }
//   }

//   &__lang {
//     transform: translateY(3px);
//     padding: 2px 16px;
//     border: 1px solid var(--color-border-gray);
//     border-radius: 4px;
//     --Nav__lang__a-p: calc(var(--global-space) * 0.2);
//     --Nav__lang__a-o: 0;
//     --Nav__lang__a-pe: none;

//     @media (max-width: $screen-mobiles-s) {
//       transform: translateY(1px);
//     }

//     &:hover {
//       --Nav__lang__a-o: 1;
//       --Nav__lang__a-pe: auto;
//     }

//     &__a {
//       display: grid;
//       position: relative;
//       z-index: 1;
//       grid-gap: 3px;
//       grid-template-columns: max-content max-content;
//       align-items: center;
//       justify-content: center;
//       padding: var(--Nav__lang__a-p);
//       color: #1a1a1a;
//       font-family: $Poppins-SemiBold;
//       font-size: 16px;
//       letter-spacing: 0.54px;
//       line-height: 1;
//       user-select: none;

//       &::after {
//         content: '';
//         display: block;
//         width: 6px;
//         height: 4px;
//         background-image: url(/images/Nav__lang__a.svg);
//         background-repeat: no-repeat;
//         background-position: center center;
//         background-size: cover;
//       }
//     }

//     &__panel {
//       display: grid;
//       position: absolute;
//       padding: 4px 16px;
//       top: 0;
//       left: 0;
//       align-content: end;
//       width: 100%;
//       height: 200%;
//       transition: opacity 0.2s;
//       transition-timing-function: ease-in-out;
//       border: 1px solid rgba(#c8c8c8, 0.5);
//       opacity: var(--Nav__lang__a-o);
//       background-color: #fff;
//       box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
//       pointer-events: var(--Nav__lang__a-pe);
//       will-change: opacity;

//       &__a {
//         padding: var(--Nav__lang__a-p);
//         color: #03e2a7;
//         font-family: $Poppins-SemiBold;
//         font-size: 16px;
//         letter-spacing: 0.54px;
//         line-height: 1;
//       }
//     }
//   }

//   &__panel {
//     @media (max-width: $screen-mobiles-l) {
//       display: grid;
//       position: fixed;
//       top: 0;
//       right: 0;
//       grid-template-columns: repeat(2, max-content);
//       grid-template-rows: max-content 1fr max-content max-content;
//       justify-content: space-between;
//       width: 50vw;
//       height: 100%;
//       padding: 0 calc(var(--global-space) * 1);
//       overflow: scroll;
//       transition: var(--Nav__panel-ts) 0.2s;
//       opacity: 0;
//       background-color: #f4f4f4;
//       pointer-events: none;
//       -webkit-overflow-scrolling: touch;
//       overflow-scrolling: touch;
//       will-change: var(--Nav__panel-ts);
//     }

//     &--active {
//       @media (max-width: $screen-mobiles-l) {
//         opacity: 1;
//         pointer-events: all;
//       }
//     }

//     @media (max-width: $screen-mobiles) {
//       width: 100vw;
//     }

//     &::before,
//     &::after {
//       @media (max-width: $screen-mobiles-l) {
//         content: '';
//         display: block;
//         grid-column: 1 / 3;
//         height: calc(var(--global-space) * 1);
//       }
//     }

//     &__a {
//       display: none;
//       color: #1a1a1a;
//       font-family: $Poppins-Regular;
//       font-size: 19px;
//       letter-spacing: 0.79px;

//       @media (max-width: $screen-mobiles-l) {
//         display: block;
//       }
//     }
//   }

//   &__links {
//     display: grid;
//     grid-gap: calc(var(--global-space) * 1);
//     grid-template-columns: repeat(8, max-content);
//     justify-content: space-between;
//     transform: translateY(4px);

//     @media (max-width: $screen-mobiles-l) {
//       grid-column: 1 / 3;
//       grid-gap: calc(var(--global-space) * 1.5);
//       grid-template-columns: 1fr;
//       align-content: center;
//       padding: calc(var(--global-space) * 2) 0;
//       transform: none;
//       justify-items: center;
//     }

//     &__a {
//       color: #1a1a1a;
//       font-family: $Poppins-SemiBold;
//       font-size: 16px;
//       letter-spacing: 0.54px;

//       @media (max-width: $screen-mobiles-l) {
//         font-size: 28px;
//         letter-spacing: 1.17px;
//       }
//     }
//   }

//   &__handle {
//     display: none;
//     position: absolute;
//     top: 50%;
//     right: calc(var(--global-space) * 1);
//     transform: translateY(calc(-50% + 2px));
//     color: #1a1a1a;
//     font-family: $Poppins-SemiBold;
//     font-size: 19px;
//     letter-spacing: 0.79px;

//     @media (max-width: $screen-mobiles-l) {
//       display: block;
//     }

//     @media (max-width: $screen-mobiles-s) {
//       transform: translateY(calc(-50%));
//     }
//   }
// }

.Contact {
  padding-top: var(--Nav-height);

  &__left {
    display: grid;
    grid-gap: calc(var(--global-space) * 3);
    margin-top: calc(var(--global-space) * 2);

    &__item {
      &__header {
        display: grid;
        grid-gap: calc(var(--global-space) * 1);
        grid-template-columns: max-content max-content;
        align-items: baseline;
        margin-bottom: calc(var(--global-space) * -1.25);

        @media (max-width: $screen-mobiles-l) {
          grid-gap: 0;
          grid-template-columns: 1fr;
        }

        &__small {
          display: grid;
          grid-gap: calc(var(--global-space) * 0.5);
          grid-template-columns: max-content max-content;
          align-items: baseline;
          transform: translateY(-1px);
          color: #1a1a1a;
          font-family: $Poppins-SemiBold;
          font-size: 13px;
          letter-spacing: 0.54px;

          &::before {
            content: '';
            display: block;
            width: 24px;
            height: 16px;
            transform: translateY(2px);
            background-image: url(/images/Contact__left__item__header__small.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }
      }
    }

    &__h3 {
      color: #1a1a1a;
      font-family: $Poppins-SemiBold;
      font-size: 28px;
      letter-spacing: 1.17px;
    }

    &__tel-mail {
      display: grid;
      margin-top: calc(var(--global-space) * 1);
    }

    &__tel-mail-flex {
      display: flex;
      flex-wrap: wrap;
      margin-top: calc(var(--global-space) * 0.5);
      margin-bottom: calc(var(--global-space) * -0.5);

      > * {
        &:not(:last-child) {
          margin-right: calc(var(--global-space) * 2);
        }
      }
    }

    &__tel {
      @include h3;

      color: #1a1a1a;
      font-family: $Poppins-Regular;

      &--small {
        font-size: 19px;
        letter-spacing: 0.79px;
      }
    }

    &__email {
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
    }

    &__iframe {
      width: 100%;
      height: 200px;
      margin-top: calc(var(--global-space) * 1);
    }
  }

  &__right {
    position: sticky;
    position: -webkit-sticky;
    top: calc(var(--BodySpace-padding) + var(--Nav-height));
    left: 0;

    &__h3 {
      @include h3;

      color: #fff;
      font-family: $Poppins-SemiBold;
    }

    &__p {
      margin-top: calc(var(--global-space) * 0.5);
      color: #fff;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
    }

    .GetStarted__form {
      width: 100%;

      &__checkbox {
        .Component__checkbox__input-container--checked {
          background-color: #fff;
        }
      }
    }

    .GetStarted__form__buttons {
      justify-content: start;
    }
  }
}

.ServicesShow {
  &__hero {
    display: grid;
    align-items: center;
    min-height: 100vh;
    padding: calc(var(--Nav-height) + var(--global-space) * 4) 0;

    @media (max-width: $DualSlide-breakpoint) {
      min-height: unset;
      padding: calc(var(--Nav-height) + var(--global-space) * 2) 0;
    }

    .container {
      display: grid;
      grid-gap: calc(var(--global-space) * 7.5);
      grid-template-columns: 1fr 1fr;
      align-items: center;
      height: 100%;

      @media (max-width: $screen-tablets-l) {
        grid-gap: calc(var(--global-space) * 3);
      }

      @media (max-width: $screen-mobiles-l) {
        grid-gap: calc(var(--global-space) * 2);
      }

      @media (max-width: $DualSlide-breakpoint) {
        grid-gap: calc(var(--global-space) * 3);
        grid-template-columns: 1fr;
        padding-right: 0;
        padding-left: 0;
      }
    }

    &__img {
      max-height: 100%;
      justify-self: end;

      @media (max-width: $DualSlide-breakpoint) {
        padding-right: calc(var(--global-space) * 3.5);
        justify-self: start;
      }
    }

    &__wrapper {
      @media (max-width: $DualSlide-breakpoint) {
        padding-right: calc(var(--global-space) * 1);
        padding-left: calc(var(--global-space) * 1);
      }
    }

    &__small {
      display: block;
      margin-bottom: calc(var(--global-space) * 1);
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
    }

    &__h2 {
      @include h2;

      color: #1a1a1a;

      span {
        color: #2784f5;
      }

      &--green {
        span {
          color: #03e2a7;
        }
      }
    }

    &__p {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      margin-top: calc(var(--global-space) * 1);
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
      text-align: var(--p-ta);
    }

    &__contact {
      width: fit-content;
      padding: calc(var(--global-space) * 0.5) calc(var(--global-space) * 2.5);
      scroll-behavior: smooth;
    }

    .Buttons {
      margin-top: calc(var(--global-space) * 2.5);

      @media (max-width: $DualSlide-breakpoint) {
        justify-content: end;
      }
    }

    &__a {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: max-content max-content;
      align-items: center;
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 13px;
      letter-spacing: 0.54px;

      &::before {
        content: '';
        display: block;
        width: 35px;
        height: 16px;
        background-image: url(/images/ServicesShow__hero__a.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }
  }

  &__dual {
    display: grid;
    position: relative;
    align-items: center;
    min-height: 100vh;
    padding: calc(var(--global-space) * 8.5) 0;
    background-color: #2784f5;

    @media (max-width: $screen-tablets-l) {
      padding: calc(var(--global-space) * 3) 0;
    }

    @media (max-width: $screen-mobiles-l) {
      min-height: unset;
      padding: 0;
    }

    &--green {
      background-color: #00c28f;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background-color: #3a4f5f;

      @media (max-width: $screen-mobiles-l) {
        display: none;
      }
    }

    .container {
      display: grid;
      position: relative;
      grid-gap: calc(var(--global-space) * 7.5);
      grid-template-columns: 1fr 1fr;

      @media (max-width: $screen-tablets-l) {
        grid-gap: calc(var(--global-space) * 4);
      }

      @media (max-width: $screen-mobiles-l) {
        grid-gap: calc(var(--global-space) * 0);
        grid-template-columns: 1fr;
        padding-right: 0;
        padding-left: 0;
      }
    }

    &__box {
      @media (max-width: $screen-mobiles-l) {
        padding: calc(var(--global-space) * 3) calc(var(--global-space) * 1);
      }

      &:last-child {
        @media (max-width: $screen-mobiles-l) {
          background-color: #3a4f5f;
        }
      }

      &__h3 {
        @include h2;

        margin-bottom: calc(var(--BodySpace-padding));
        color: #fff;

        @media (max-width: $screen-tablets-l) {
          margin-bottom: calc(var(--global-space) * 2);
        }
      }

      &__p {
        display: grid;
        grid-gap: calc(var(--global-space) * 1);
        margin-top: calc(var(--global-space) * 1.5);
        color: #fff;
        font-family: $Poppins-Regular;
        font-size: 19px;
        letter-spacing: 0.79px;
        text-align: var(--p-ta);
      }
    }
  }

  &__deliverables {
    padding: calc(var(--BodySpace-padding)) 0;

    > .container {
      display: grid;
      grid-gap: calc(var(--global-space) * 4);
      grid-template-columns: max-content 1fr;

      @media (max-width: $screen-tablets) {
        grid-template-columns: 1fr;
      }

      @media (max-width: $screen-mobiles) {
        grid-gap: calc(var(--global-space) * 2);
      }
    }

    &__h3 {
      @include h2;

      color: #1a1a1a;
    }

    &__dl {
      display: grid;
      grid-gap: calc(var(--global-space) * 2);
      color: #1a1a1a;
      counter-reset: counter-stats-item 1;

      --ServicesShow__deliverables__dl__li-gtc: max-content max-content 1fr;
      --ServicesShow__deliverables__dl__li-a-gc: 2 / 3;
      --ServicesShow__deliverables__dl__li-b-d: block;
      --ServicesShow__deliverables__dl__item__wrapper-bgi: url(/images/ServicesShow__deliverables__dl__item.svg);

      &--white {
        align-items: center;
        color: #fff;

        --ServicesShow__deliverables__dl__li-gtc: max-content 1fr;
        --ServicesShow__deliverables__dl__li-a-gc: unset;
        --ServicesShow__deliverables__dl__li-b-d: none;
        --ServicesShow__deliverables__dl__item__wrapper-bgi: url(/images/ServicesShow__deliverables__dl__item--white.svg);
      }

      ol,
      ul {
        display: grid;
        grid-gap: calc(var(--global-space) * 1);

        li {
          display: grid;
          grid-gap: calc(var(--global-space) * 1)
            calc(var(--global-space) * 1.5);
          grid-template-columns: var(--ServicesShow__deliverables__dl__li-gtc);
          align-items: center;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;
          text-align: var(--p-ta);

          @media (max-width: $screen-mobiles) {
            grid-gap: calc(var(--global-space) * 0.5)
              calc(var(--global-space) * 0.75);
          }

          &::before {
            content: '0' counter(counter-stats-item);
            display: var(--ServicesShow__deliverables__dl__li-b-d);
            width: calc(var(--global-space) * 3);
            font-family: $Poppins-SemiBold;
            font-size: 40px;
            letter-spacing: 1.67px;
            line-height: 66px;
            text-align: right;

            @media (max-width: $screen-mobiles) {
              width: calc(var(--global-space) * 1.75);
              font-size: 22px;
              letter-spacing: 0.92px;
              line-height: 25px;
            }
          }

          &::after {
            content: '';
            display: block;
            grid-column: var(--ServicesShow__deliverables__dl__li-a-gc);
            grid-row: 1 / 2;
            width: 66px;
            height: 66px;
            background-image: var(
              --ServicesShow__deliverables__dl__item__wrapper-bgi
            );
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            counter-increment: counter-stats-item;

            @media (max-width: $screen-mobiles) {
              width: 25px;
              height: 25px;
            }
          }

          &__wrapper {
            display: contents;
          }

          &__wrapper-inner {
            @media (max-width: $screen-mobiles) {
              display: contents;
            }
          }

          &__dt {
            margin-bottom: calc(var(--global-space) * 1);
            font-family: $Poppins-Regular;
            font-size: 19px;
            letter-spacing: 0.79px;

            @media (max-width: $screen-mobiles) {
              margin-bottom: 0;
            }
          }

          &__dd {
            @media (max-width: $screen-mobiles) {
              grid-column: 1 / 4;
              grid-row: 2 / 3;
            }
          }
        }
      }
    }
  }

  &__end-result {
    position: relative;
    padding-top: calc(var(--global-space) * 12);
    padding-bottom: calc(var(--global-space) * 3.5);
    background-color: rgba(65, 92, 112, 0.8);

    @media (max-width: $screen-mobiles-l) {
      padding: calc(var(--BodySpace-padding)) 0 !important;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: calc(50vw + (550px / 2)); // Width of `.GetStarted__form`.
      height: 100%;
      background-image: url(/images/ServicesShow__end-result.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    > .container {
      position: relative;
    }

    &__box {
      padding: calc(var(--global-space) * 3.5) calc(var(--global-space) * 2.5);
      border: 3px solid #04e8ac;
      border-radius: 8px;
      background-color: #3a4f5f;

      @media (max-width: $screen-mobiles-l) {
        padding: calc(var(--global-space) * 2);
      }

      @media (max-width: $screen-mobiles-s) {
        padding: calc(var(--global-space) * 1.5);
      }

      &__h3 {
        @include h2;

        color: #fff;
      }

      &__p {
        display: grid;
        grid-gap: calc(var(--global-space) * 1);
        margin-top: calc(var(--global-space) * 1);
        color: #fff;
        font-family: $Poppins-Regular;
        font-size: 19px;
        letter-spacing: 0.79px;
        text-align: var(--p-ta);
      }

      .ServicesShow__deliverables__dl {
        margin-top: calc(var(--global-space) * 2);
      }
    }

    &__footer {
      margin-top: calc(var(--global-space) * 8.5);
      padding-top: calc(var(--global-space) * 1);
      border-top: 1px solid #fff;

      @media (max-width: $screen-mobiles-l) {
        margin-top: calc(var(--global-space) * 2);
      }

      &__h4 {
        color: #fff;
        font-family: $Poppins-Regular;
        font-size: 19px;
        letter-spacing: 0.79px;
      }

      &__links {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: calc(var(--global-space) * 1.5);
        margin-bottom: calc(var(--global-space) * -0.5);

        > * {
          margin-bottom: calc(var(--global-space) * 0.5);

          &:not(:last-child) {
            margin-right: calc(var(--global-space) * 1);
          }
        }

        &__a {
          display: grid;
          grid-gap: calc(var(--global-space) * 1);
          grid-template-columns: 1fr max-content;
          align-items: center;
          padding: calc(var(--global-space) * 0.5) calc(var(--global-space) * 1);
          border: 2px solid #04e8ac;
          border-radius: 22px;
          color: #04e8ac;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;

          &__span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &::after {
            content: '';
            display: block;
            width: 35px;
            height: 16px;
            background-image: url(/images/ServicesShow__end-result__footer__links__a.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }
      }
    }

    &__percent {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: 1fr max-content;
      align-items: start;
      margin-top: calc(var(--global-space) * 1.5);

      &__p {
        color: #04e8ac;
        font-family: $Poppins-Regular;
        font-size: 13px;
        letter-spacing: 0.54px;
        text-align: right;
      }

      &__strong {
        transform: translateY(-6px);
        color: #04e8ac;
        font-family: $Poppins-SemiBold;
        font-size: 88px;
        letter-spacing: 3.67px;
        line-height: 1;
      }
    }
  }
}

.Careers {
  &__p {
    color: #1a1a1a;
    font-family: $Poppins-Regular;
    font-size: 19px;
    letter-spacing: 0.79px;
    text-align: center;
  }

  &__benefits {
    display: grid;
    grid-gap: calc(var(--global-space) * 3);
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-top: calc(var(--BodySpace-padding));

    @media (max-width: $screen-mobiles-l) {
      grid-gap: calc(var(--global-space) * 2);
    }

    @media (max-width: $screen-mobiles) {
      grid-template-columns: 1fr;
    }

    &__img {
      justify-self: end;

      @media (max-width: $screen-tablets-l) {
        align-self: start;
      }

      @media (max-width: $screen-mobiles) {
        justify-self: center;
      }
    }

    &__right {
      &__h2 {
        @include h2;

        color: #1a1a1a;

        span {
          color: #ff765e;
        }
      }

      &__p {
        margin-top: calc(var(--global-space) * 1);
        color: #1a1a1a;
        font-family: $Poppins-Regular;
        font-size: 19px;
        letter-spacing: 0.79px;
      }

      &__list {
        display: grid;
        grid-gap: calc(var(--global-space) * 1);
        grid-template-columns: 1fr 1fr;
        align-items: start;
        margin-top: calc(var(--global-space) * 2);

        @media (max-width: $screen-tablets) {
          grid-template-columns: 1fr;
        }

        @media (max-width: $screen-mobiles) {
          margin-top: calc(var(--global-space) * 1);
        }

        &__ul {
          display: grid;
          grid-gap: inherit;
          align-items: inherit;

          &__li {
            display: grid;
            grid-gap: calc(var(--global-space) * 1);
            grid-template-columns: max-content 1fr;
            align-items: start;
            color: #1a1a1a;
            font-family: $Poppins-Regular;
            font-size: 19px;
            letter-spacing: 0.79px;

            &::before {
              content: '';
              display: block;
              width: 12px;
              height: 12px;
              transform: translateY(11px);
              border-radius: 50%;
              background-color: #03e2a7;
            }
          }
        }
      }
    }
  }

  &__positions {
    margin-top: calc(var(--BodySpace-padding));

    @media (max-width: $screen-mobiles-l) {
      grid-gap: calc(var(--global-space) * 2);
      grid-template-columns: 1fr;
    }

    &__h3 {
      @include h2;

      color: #1a1a1a;
      font-family: $Poppins-SemiBold;
      text-align: center;

      span {
        color: #ff765e;
      }
    }

    &__link {
      margin-bottom: calc(var(--global-space) * 2);
      color: #1a1a1a;
      text-align: center;
      text-decoration-line: underline;
    }

    &__dl {
      display: grid;
      grid-gap: calc(var(--global-space) * 1.5);

      &__item {
        display: grid;
        grid-gap: calc(var(--global-space) * 1);
        grid-template-columns: 1fr max-content;
        align-items: center;
        padding: calc(var(--global-space) * 1.5);
        border: 1px solid #c6c6c6;
        border-radius: 10px;

        &__dt {
          @include h3;

          color: #1a1a1a;
          font-family: $Poppins-SemiBold;
        }

        &__dd {
          display: grid;
          grid-gap: calc(var(--global-space) * 1);
          grid-row: 2 / 3;
          color: #1a1a1a;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;
          text-align: var(--p-ta);

          @media (max-width: $screen-mobiles-l) {
            grid-column: 1 / 3;
          }
        }

        &::after {
          content: '';
          display: block;
          grid-column: 2 / 3;
          width: 35px;
          height: 16px;
          background-image: url(/images/Careers__positions__dl__item.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }
      }
    }
  }

  &__video {
    padding-top: calc(var(--global-space) * 4);
    padding-bottom: calc(var(--global-space) * 2);
    background: #ffe6e2;
    text-align: center;

    &__aspect-ratio {
      position: relative;
      max-width: 960px;
      margin: 0 auto calc(var(--global-space) * 1.5);

      &::before {
        content: '';
        display: block;
        height: 0;
        padding-top: calc((315 / 560) * 100%);
      }
    }

    &__iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__h2 {
      @include h2;

      color: #1a1a1a;
    }

    &__span {
      color: #ff765e;
    }
  }
}

.CareersShow {
  padding-top: var(--Nav-height);

  &__h2 {
    @include h2;

    color: #1a1a1a;
  }

  &__content {
    display: grid;
    grid-gap: calc(var(--global-space) * 4);
    grid-template-columns: 1fr 1fr;
    margin-top: calc(var(--global-space) * 3);

    @media (max-width: $screen-tablets-l) {
      grid-template-columns: 1fr;
    }

    @media (max-width: $screen-mobiles) {
      grid-gap: calc(var(--global-space) * 2);
      margin-top: calc(var(--global-space) * 2);
    }

    &::before {
      content: '';
      display: block;
      height: 210px;
      background-color: #ff765e;

      @media (max-width: $screen-tablets-l) {
        height: 130px;
      }
    }

    &__wrapper {
      display: grid;
      grid-gap: calc(var(--global-space) * 1.5);
    }

    &__h3 {
      @include h3;

      color: #1a1a1a;
      font-family: $Poppins-SemiBold;

      &:not(:first-child) {
        margin-top: calc(var(--global-space) * 1);
      }
    }

    &__p {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
      text-align: var(--p-ta);
    }

    &__ul {
      ul {
        display: grid;
        grid-gap: calc(var(--global-space) * 0.75);
        padding-left: calc(var(--global-space) * 1);

        li {
          list-style-type: disc;
          color: #1a1a1a;
          font-family: $Poppins-Regular;
          font-size: 19px;
          letter-spacing: 0.79px;
        }
      }
    }
  }

  &__apply {
    margin-top: calc(var(--global-space) * 3);
    padding: calc(var(--BodySpace-padding)) 0;
    background-color: #3a4f5f;
    color: #fff;

    &__h3 {
      color: #fff;
      font-family: $Poppins-SemiBold;
      font-size: 28px;
      letter-spacing: 1.17px;

      @media (max-width: $screen-mobiles-s) {
        font-size: 22px;
      }
    }

    &__grid {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: 1fr 1fr 2fr;
      margin-top: calc(var(--global-space) * 1);

      @media (max-width: $screen-tablets) {
        grid-template-columns: 1fr;
      }

      &__h4 {
        color: #fff;
        font-family: $Poppins-Regular;
        font-size: 19px;
        letter-spacing: 0.79px;
      }

      &__p {
        color: #fff;
        font-family: $Poppins-Regular;
        font-size: 13px;
        letter-spacing: 0.54px;
      }

      > * {
        &:nth-child(1) {
          grid-column: 1 / 3;

          @media (max-width: $screen-tablets) {
            grid-column: unset;
          }
        }

        &:nth-child(4) {
          grid-column: 3 / 4;
          grid-row: 1 / 2;

          @media (max-width: $screen-tablets) {
            grid-column: unset;
            grid-row: unset;
            order: 1;
          }
        }

        &:nth-child(5) {
          @media (max-width: $screen-tablets) {
            order: 2;
          }
        }

        &:nth-child(8) {
          @media (max-width: $screen-tablets) {
            order: 3;
          }
        }
      }
    }

    &__buttons {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: max-content max-content;
      margin-top: calc(var(--global-space) * 1.5);
    }

    &__checkbox {
      margin-top: var(--global-space);
      font-size: 13px;
      line-height: 1em;

      &__a {
        color: #03e2a7;
        text-decoration: underline;
      }
    }
  }
}

.Policy {
  padding-top: calc(var(--Nav-height));

  &__hero {
    > .container {
      display: grid;
      grid-gap: calc(var(--BodySpace-padding) * 0.5);
      grid-template-columns: 1fr 1fr;

      @media (max-width: $screen-tablets-l) {
        grid-template-columns: 1fr;
      }
    }

    &__h2 {
      @include h2;

      color: #1a1a1a;

      span {
        color: #03e2a7;
      }
    }

    &__ps {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      padding-top: calc(var(--global-space) * 0.5);
    }

    &__p {
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
      text-align: var(--p-ta);

      strong {
        font-family: $Poppins-SemiBold;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 140px;
      margin-top: calc(var(--BodySpace-padding) * 0.5);
      background-color: #03e2a7;
    }
  }

  &__content {
    margin-top: calc(var(--BodySpace-padding) * 0.5);

    &__p {
      margin-bottom: calc(var(--global-space) * 2);
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
    }

    &__dl {
      display: grid;
      grid-gap: calc(var(--global-space) * 1.5);

      &__item {
        &__dt {
          color: #1a1a1a;
          font-family: $Poppins-SemiBold;
          font-size: 19px;
          letter-spacing: 0.79px;
        }

        &__dd {
          display: grid;
          grid-gap: calc(var(--global-space) * 1);
          margin-top: calc(var(--global-space) * 0.8);
          color: #1a1a1a;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;

          strong {
            font-weight: bold;
          }

          hr {
            display: block;
            width: 100%;
            margin: 0 auto;
            border: none;
            border-bottom: 1px solid #e8e8e8;
          }

          &__p {
            text-align: var(--p-ta);

            > a {
              color: #0083fd;
              text-decoration: underline;
            }
          }

          &__h4 {
            font-family: $Poppins-SemiBold;
            font-size: 15px;
          }

          &__ul,
          &__ol {
            padding-left: calc(var(--global-space) * 1);
            list-style-position: outside;
          }

          &__ul {
            list-style-type: disc;
          }

          &__ol {
            list-style-type: decimal;
          }
        }
      }
    }
  }
}

.CasesShow {
  &__expected-outcome {
    margin-top: calc(var(--BodySpace-padding));

    > .container {
      display: grid;
      grid-gap: calc(var(--global-space) * 3);
      grid-template-columns: max-content 1fr;

      @media (max-width: $screen-tablets) {
        grid-template-columns: 1fr;
      }

      @media (max-width: $screen-mobiles) {
        grid-gap: calc(var(--global-space) * 2);
      }
    }

    &__h3 {
      @include h2;

      color: #1a1a1a;
    }

    &__p {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
      text-align: var(--p-ta);

      ul {
        display: grid;
        grid-gap: calc(var(--global-space) * 1);
      }
    }

    &__box {
      display: grid;
      position: relative;
      grid-column: 1 / 3;
      grid-gap: calc(var(--global-space) * 1);
      padding: calc(var(--global-space) * 2);
      padding-bottom: calc(var(--global-space) * 3);
      border: 3px solid #04e8ac;
      border-radius: 8px;

      @media (max-width: $screen-tablets) {
        grid-column: unset;
      }

      @media (max-width: $screen-mobiles) {
        padding-right: calc(var(--global-space) * 1);
        padding-bottom: calc(var(--global-space) * 2);
        padding-left: calc(var(--global-space) * 1);
      }

      &__right {
        text-align: right;
        justify-self: end;

        &__name {
          color: #1a1a1a;
          font-family: $Poppins-Regular;
          font-size: 19px;
          letter-spacing: 0.79px;
        }

        &__position {
          color: #1a1a1a;
          font-family: $Poppins-Regular;
          font-size: 13px;
          letter-spacing: 0.54px;
        }
      }

      &__h4 {
        @include h2;

        color: #1a1a1a;
      }

      &__p {
        color: #1a1a1a;
        font-family: $Poppins-Regular;
        font-size: 19px;
        letter-spacing: 0.79px;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: calc(var(--global-space) * 1.5);
        width: 48px;
        height: 44px;
        transform: translateY(-50%);
        background-image: url(/images/CasesShow__expected-outcome__box.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }
  }
}

.Anchor {
  transform: translateY(calc((var(--Nav-height) * -1) + 1px));
}

@keyframes ani-Loader {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.Loader {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation-name: ani-Loader;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
  animation-delay: var(--DualSlide-ts-d);
  background-color: #fff;
  pointer-events: none;
  animation-fill-mode: forwards;
}

.Blogs {
  padding-top: var(--Nav-height);

  &__hero {
    padding-top: var(--BodySpace-padding);

    > .container {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: 1fr max-content;
      align-items: center;
      padding-bottom: calc(var(--BodySpace-padding) * 0.35);

      @media (max-width: $screen-mobiles-l) {
        grid-template-columns: 1fr;
      }
    }

    &__h2 {
      @include h2;

      color: #1a1a1a;

      span {
        color: #ff765e;
      }
    }

    &::after {
      content: '';
      display: block;
      height: 32px;
      background-color: #ff765e;
    }
  }

  &__content {
    --app-width: 1120px;
  }

  &__search {
    display: grid;
    grid-gap: calc(var(--global-space) * 0.5);
    grid-template-columns: 1fr max-content;
    align-items: center;
    width: 355px;
    padding: calc(var(--global-space) * 0.75);
    background-color: #f4f4f4;

    @media (max-width: $screen-mobiles-l) {
      width: 100%;
    }

    &__input {
      @extend .Input;

      width: 100%;
      padding: 0;
      outline: none;
      background-color: transparent;
    }

    &__button {
      width: 24px;
      height: 24px;
      border: none;
      border-radius: 0;
      outline: none;
      background-color: transparent;
      background-image: url(/images/Blogs__search__button.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      box-shadow: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }
  }

  &__nav {
    &__panel {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: max-content;
      align-items: center;
    }

    &__links {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: repeat(10, max-content);
      justify-content: space-between;
      transform: translateY(4px);

      @media (max-width: $screen-mobiles-l) {
        grid-column: 1 / 3;
        grid-gap: calc(var(--global-space) * 1.5);
        grid-template-columns: 1fr;
        align-content: center;
        padding: calc(var(--global-space) * 2) 0;
        transform: none;
        justify-items: center;
      }

      &__a {
        color: #1a1a1a;
        font-family: $Poppins-SemiBold;
        font-size: 13px;
        letter-spacing: 0.54px;

        @media (max-width: $screen-mobiles-l) {
          font-size: 28px;
          letter-spacing: 1.17px;
        }
      }
    }

    &__link {
      color: #1a1a1a;
      font-size: 16px;
      line-height: 100%;

      &--active {
        padding-right: calc(var(--global-space) * 0.1);
        padding-bottom: calc(var(--global-space) * 0.1);
        padding-left: calc(var(--global-space) * 0.1);
        border-bottom: 3px solid #1a1a1a;
        font-weight: bold;
      }
    }
  }
}

.BlogsShow {
  padding-top: var(--Nav-height);

  &__h2 {
    @include h2;

    color: #1a1a1a;
  }

  &__time {
    display: block;
    margin-top: calc(var(--global-space) * 1);
    color: #ff765e;
    font-family: $Poppins-Regular;
    font-size: 13px;
    letter-spacing: 0.54px;
  }

  &__category {
    font-size: 16px;
    text-decoration: underline;
  }

  &__cover {
    width: 100%;
    max-height: 400px;
    margin-top: calc(var(--global-space) * 1);
    object-fit: cover;
  }

  .Markdown {
    margin-top: calc(var(--global-space) * 2);
  }

  &__main {
    --app-width: 740px;
  }

  &__related {
    margin-top: calc(var(--BodySpace-padding));

    &__h5 {
      padding-top: calc(var(--BodySpace-padding) * 0.5);
      border-top: 1px solid #d8d8d8;
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 28px;
      letter-spacing: 1.17px;

      @media (max-width: $screen-tablets) {
        font-size: 20px;
      }
    }

    .Index__know__blogs__dl {
      margin-top: calc(var(--global-space) * 1);
    }
  }
}

.ProductsShow {
  padding-top: calc(var(--Nav-height));

  &__hero {
    &__wrapper {
      display: grid;
      grid-gap: calc(var(--BodySpace-padding));
      grid-template-columns: 1fr 490px;

      @media (max-width: $screen-tablets-l) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: $screen-mobiles-l) {
        grid-template-columns: 1fr;
      }
    }

    &__left {
      padding: calc(var(--global-space) * 3) 0 calc(var(--global-space) * 3);
      border-bottom: 1px solid #1a1a1a;

      @media (max-width: $screen-mobiles-l) {
        padding-top: 0;
      }
    }

    &__h2 {
      @include h2;

      color: #1a1a1a;
    }

    &__p {
      margin-top: calc(var(--global-space) * 1);
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
      text-align: var(--p-ta);
    }

    &__image {
      display: grid;
      background-color: #f4f4f4;

      @media (max-width: $screen-mobiles-l) {
        grid-row: 1 / 2;
      }

      > * {
        grid-area: 1 / 1 / 2 / 2;
      }

      &__svg {
        @media (max-width: $screen-mobiles-l) {
          display: none;
        }
      }

      &__wrapper {
        display: grid;
        align-items: center;
        padding: calc(var(--global-space) * 2) calc(var(--global-space) * 1);
        justify-items: center;
      }

      &__box {
        border-radius: 37.33px;
        background-color: #fff;
      }
    }
  }

  &__content {
    margin-top: calc(var(--global-space) * 3);

    --app-width: 1120px;

    &__h3 {
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 28px;
      letter-spacing: 1.17px;
    }

    .Markdown {
      margin-top: calc(var(--global-space) * 2);
    }
  }

  &__more {
    margin: calc(var(--BodySpace-padding)) 0;
    padding: calc(var(--BodySpace-padding) * 0.75) 0;
    background-color: #3a4f5f;
    text-align: center;

    > .container {
      display: grid;
      justify-items: center;
    }

    &__h4 {
      color: #fff;
      font-family: $Poppins-SemiBold;
      font-size: 28px;
      letter-spacing: 1.17px;
    }

    &__p {
      margin-top: calc(var(--global-space) * 0.5);
      color: #fff;
      font-family: $Poppins-Regular;
      font-size: 19px;
      letter-spacing: 0.79px;
    }

    .Button {
      margin-top: calc(var(--global-space) * 1);
    }
  }

  &__recommend {
    &__h3 {
      color: #1a1a1a;
      font-family: $Poppins-Regular;
      font-size: 28px;
      letter-spacing: 1.17px;
    }

    &__dl {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: repeat(3, 1fr);
      margin-top: calc(var(--global-space) * 2);

      @media (max-width: $screen-tablets) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: $screen-mobiles) {
        grid-template-columns: minmax(auto, 320px);
        justify-content: center;
      }
    }
  }
}

.Markdown {
  display: grid;
  grid-gap: calc(var(--global-space) * 2);
  color: #1a1a1a;
  font-family: $Poppins-Regular;
  font-size: 16px;
  letter-spacing: 0.54px;

  @media (max-width: $screen-mobiles) {
    grid-gap: calc(var(--global-space) * 1.5);
  }

  p {
    text-align: var(--p-ta);
  }

  a {
    color: #03e2a7;
  }

  strong {
    font-family: $Poppins-SemiBold;
  }

  hr {
    display: block;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    border: none;
    border-bottom: 1px solid #d8d8d8;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: calc(var(--global-space) * 1);
    color: #1a1a1a;
    font-family: $Poppins-SemiBold;
    letter-spacing: 1.17px;
  }

  h1 {
    font-size: 40px;
    letter-spacing: 1.67px;

    @media (max-width: $screen-tablets) {
      font-size: 36px;
    }
  }

  h2 {
    font-size: 36px;
    letter-spacing: 1.67px;

    @media (max-width: $screen-tablets) {
      font-size: 32px;
    }
  }

  h3 {
    font-size: 32px;
    letter-spacing: 1.67px;

    @media (max-width: $screen-tablets) {
      font-size: 28px;
    }
  }

  h4 {
    font-size: 28px;

    @media (max-width: $screen-tablets) {
      font-size: 24px;
    }
  }

  h5 {
    font-size: 24px;

    @media (max-width: $screen-tablets) {
      font-size: 20px;
    }
  }

  h6 {
    font-size: 20px;

    @media (max-width: $screen-tablets) {
      font-size: 18px;
    }
  }

  img {
    margin: 0 auto;
  }

  blockquote {
    padding-left: calc(var(--global-space) * 1.5);
    border-left: 3px solid #52686c;
    white-space: pre-line;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul,
  ol {
    display: grid;
    grid-gap: calc(var(--global-space) * 0.5);
    padding-left: calc(var(--global-space) * 2);
  }

  li {
    padding-left: calc(var(--global-space) * 0.5);
  }

  pre {
    padding: calc(var(--global-space) * 1);
    overflow-x: auto;
    overflow-y: hidden;
    background-color: rgba(218, 218, 218, 0.3);
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;

    * {
      padding: 0 !important;
      border-radius: 0 !important;
      background-color: transparent !important;
    }
  }

  code {
    padding: calc(var(--global-space) * 0.15) calc(var(--global-space) * 0.25)
      calc(var(--global-space) * 0.25);
    border-radius: 4px;
    background-color: rgba(218, 218, 218, 0.3);
    font-family: monospace;
  }

  .gist {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
  }

  &__caption {
    display: block;
    margin-top: calc(var(--global-space) * -1.25);
    color: #1a1a1a;
    font-family: $Poppins-Regular;
    font-size: 13px;
    letter-spacing: 0.54px;
    text-align: center;
  }
}

.BodySpace {
  padding: calc(var(--BodySpace-padding)) 0;

  &--no-top {
    padding-top: 0;
  }

  &--no-bottom {
    padding-bottom: 0;
  }
}

.Custom404 {
  display: grid;
  grid-template-rows: 1fr;
  min-height: 100vh;
  padding-top: var(--Nav-height);

  .BodySpace {
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;
  }

  .container {
    display: grid;
    grid-gap: calc(var(--global-space) * 3);
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;

    @media (max-width: $screen-tablets-l) {
      grid-template-columns: 1fr;
    }

    @media (max-width: $screen-tablets) {
      grid-gap: calc(var(--global-space) * 1);
    }
  }

  &__wrapper {
    display: grid;

    @media (max-width: $screen-tablets-l) {
      text-align: center;
      justify-items: center;
    }
  }

  &__h2 {
    color: #1a1a1a;
    font-family: $Poppins-SemiBold;
    font-size: 60px;
    letter-spacing: 2.5px;

    @media (max-width: $screen-tablets) {
      font-size: 44px;
    }

    @media (max-width: $screen-tablets) {
      font-size: 36px;
      letter-spacing: 1.5px;
    }

    span {
      color: #03e2a7;
    }
  }

  &__p {
    margin-top: calc(var(--global-space) * 0.5);
    color: #1a1a1a;
    font-family: $Poppins-Regular;
    font-size: 19px;
    letter-spacing: 0.79px;
  }

  .Buttons {
    margin-top: calc(var(--global-space) * 2);
  }

  &__img {
    width: 100%;

    @media (max-width: $screen-tablets-l) {
      grid-row: 1 / 2;
      max-width: 200px;
      justify-self: center;
    }

    @media (max-width: $screen-mobiles) {
      max-width: 150px;
    }
  }
}

.FaceBlur {
  position: relative;
  min-height: 100vh;
  background-color: #000001;
  color: #fff;

  --Nav-height: 90px;
  --app-width: 1110px;

  @media (max-width: $screen-mobiles-l) {
    --Nav-height: 72px;
  }

  strong {
    font-family: Anakotmai-Medium;
  }

  &__nav {
    display: grid;
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    align-items: center;
    width: 100%;
    height: var(--Nav-height);
    background-color: #000001;

    > .container {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: max-content max-content;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &__h1 {
      &__a {
        display: block;
        width: 137px;
        height: 32px;
        background-image: url(/images/FaceBlur__nav__h1__a.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        font-size: 0;
        text-indent: -99999px;
      }
    }

    &__links {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: repeat(3, max-content);

      @media (max-width: $screen-mobiles-l) {
        position: absolute;
        top: 100%;
        left: 0;
        grid-gap: 0;
        grid-template-columns: 1fr;
        width: 100%;
        padding: var(--app-padding);
        padding-top: 0;
        padding-bottom: calc(var(--global-space) * 1.5);
        transform: translateY(-4px);
        transition: opacity 0.2s, transform 0.2s;
        transition-timing-function: ease-in-out;
        border-bottom: 1px solid #282828;
        opacity: 0;
        background-color: #000001;
        pointer-events: none;
        justify-items: end;
        will-change: opacity, transform;
      }

      &--active {
        @media (max-width: $screen-mobiles-l) {
          transform: none;
          opacity: 1;
          pointer-events: auto;
        }
      }

      &__a {
        padding: 2px;
        border-radius: 28px;
        color: #fff;
        font-family: Anakotmai-Medium;
        font-size: 16px;

        &--button {
          background-image: linear-gradient(#7f0ffb, #2d9efe);

          @media (max-width: $screen-mobiles-l) {
            margin-top: calc(var(--global-space) * 0.5);
          }

          [class$='__span'] {
            padding-right: calc(var(--global-space) * 1);
            padding-left: calc(var(--global-space) * 1);
          }
        }

        &__wrapper {
          position: relative;
          padding: 2px;

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 28px;
            background-color: #000001;
          }
        }

        &__span {
          display: block;
          position: relative;
          padding: calc(var(--global-space) * 0.35) 0;
        }
      }
    }

    &__handle {
      display: none;
      box-sizing: content-box;
      padding: calc(var(--global-space) * 0.5);
      transform: translateX(calc(var(--app-padding) * 0.5));

      @media (max-width: $screen-mobiles-l) {
        display: block;
      }

      svg {
        width: inherit;

        rect {
          fill: #fff;
        }
      }
    }
  }

  &__slider {
    padding-top: var(--FaceBlur__slider-pt);
    padding-right: calc(var(--global-space) * 1);
    padding-left: calc(var(--global-space) * 1);

    --FaceBlur__slider-pt: calc(var(--global-space) * 3);

    &__wrapper {
      display: grid;
      position: relative;
      justify-content: center;
      margin-bottom: calc(var(--global-space) * -8);

      @media (max-width: $screen-mobiles) {
        margin-bottom: calc(var(--global-space) * -6);
      }

      @media (max-width: $screen-mobiles-l) {
        margin-bottom: calc(var(--global-space) * -4);
      }

      > * {
        grid-area: 1 / 1 / 2 / 2;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 26%;
        background-image: linear-gradient(
          to bottom,
          rgba(#000001, 1),
          rgba(#000001, 1) 40%,
          rgba(#000001, 0) 100%
        );
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40%;
        background-image: linear-gradient(
          to bottom,
          rgba(#000001, 0),
          rgba(#000001, 1) 40%
        );
      }
    }

    &__cover {
      position: relative;

      &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        will-change: width;

        &--auto {
          transition: width 0.8s;
          transition-timing-function: cubic-bezier(0.66, -0.67, 0.27, 1.67);
        }
      }
    }

    &__knob {
      display: grid;
      position: absolute;
      z-index: 3;
      top: calc(var(--FaceBlur__slider-pt) * -1);
      grid-template-rows: 1fr max-content 1fr;
      align-items: center;
      height: calc(100% + var(--FaceBlur__slider-pt));
      transform: translateX(-50%);
      transition: opacity 0.4s;
      transition-timing-function: ease-in-out;
      justify-items: center;
      will-change: opacity, left;

      &--hidden {
        opacity: 0;
      }

      &--auto {
        transition: left 0.8s;
        transition-timing-function: cubic-bezier(0.66, -0.67, 0.27, 1.67);
        pointer-events: none;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        width: 2px;
        height: 100%;
      }

      &::before {
        background-image: linear-gradient(
          to bottom,
          rgba(#fff, 0),
          rgba(#fff, 0) 22%,
          rgba(#fff, 1) 60%,
          rgba(#fff, 1)
        );

        @media (max-width: $screen-mobiles) {
          background-image: linear-gradient(
            to bottom,
            rgba(#fff, 0),
            rgba(#fff, 0) 35%,
            rgba(#fff, 1) 90%,
            rgba(#fff, 1)
          );
        }

        @media (max-width: $screen-mobiles-s) {
          background-image: linear-gradient(
            to bottom,
            rgba(#fff, 0),
            rgba(#fff, 0) 45%,
            rgba(#fff, 1) 90%,
            rgba(#fff, 1)
          );
        }
      }

      &::after {
        background-color: transparent;
        background-image: linear-gradient(
          to bottom,
          rgba(#fff, 1),
          rgba(#fff, 0) 60%
        );
      }

      &__handle {
        &::before {
          content: '';
          display: block;
          width: 82px;
          height: 30px;
          background-image: url(/images/FaceBlur__slider__knob.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;

          @media (max-width: $screen-mobiles-s) {
            width: 65px;
            height: 24px;
          }
        }
      }
    }

    &__content {
      display: grid;
      position: relative;
      z-index: 3;
      text-align: center;
      justify-items: center;

      &__p {
        color: #fff;
        font-family: Anakotmai-Medium;
        font-size: 24px;

        @media (max-width: $screen-mobiles) {
          font-size: 16px;
        }
      }

      &__h2 {
        margin-top: calc(var(--global-space) * 0.5);
        color: #fff;
        font-family: Anakotmai-Medium;
        font-size: 42px;

        @media (max-width: $screen-mobiles) {
          font-size: 24px;
        }
      }

      .Buttons {
        margin-top: calc(var(--global-space) * 2);
      }

      &__small {
        display: block;
        margin-top: calc(var(--global-space) * 2);
        color: #fff;
        font-family: Anakotmai-Light;
        font-size: 18px;

        @media (max-width: $screen-mobiles) {
          font-size: 16px;
        }
      }
    }
  }

  &__button {
    display: grid;
    display: block;
    width: 100%;
    padding: calc(var(--global-space) * 0.75) calc(var(--global-space) * 1.5);
    border: none;
    border-radius: 36px;
    background-image: linear-gradient(227deg, #24adff 0%, #8701fb 100%);
    color: #fff;
    font-family: Anakotmai-Medium;
    font-size: 24px;
    text-align: center;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &--trans {
      background-image: none;
      box-shadow: inset 0 0 0 1px #fff;
    }
  }

  &__sub-bg {
    width: 100%;
    margin-top: calc(-30%);
    margin-bottom: calc(-4%);

    @media (max-width: $screen-tablets-xl) {
      margin-bottom: calc(-14%);
    }
  }

  &__why {
    position: relative;
    margin-bottom: calc(-4%);

    @media (min-width: $screen-tablets-xl + 1) {
      --app-width: 1400px;
      --app-padding: calc(var(--global-space) * 3);
    }

    @media (max-width: $screen-tablets-xl) {
      margin-top: calc(-14%);
      margin-bottom: 0;
      padding-bottom: calc(var(--global-space) * 5);
    }

    @media (max-width: $screen-mobiles) {
      padding-bottom: calc(var(--global-space) * 3);
    }

    > .container {
      display: grid;
      grid-gap: calc(var(--app-padding) * 2);
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      width: 100%;

      @media (max-width: $screen-tablets-xl) {
        grid-gap: calc(var(--global-space) * 3);
        grid-template-columns: 1fr;
      }
    }

    &__img {
      @media (max-width: $screen-tablets-xl) {
        max-width: 337px;
        justify-self: center;
      }

      @media (max-width: $screen-mobiles-s) {
        max-width: 100%;
      }
    }

    &__right {
      transform: translateY(calc(var(--global-space) * -0.5));

      @media (max-width: $screen-tablets-xl) {
        transform: none;
      }

      &__h2 {
        display: block;
        width: 386px;
        height: 106px;
        margin-bottom: calc(var(--global-space) * 2);
        background-image: url(/images/FaceBlur__why__right__h2.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        font-size: 0;
        text-indent: -99999px;

        @media (max-width: $screen-tablets-xl) {
          width: 423px;
          height: 31px;
          margin-right: auto;
          margin-left: auto;
          background-image: url(/images/FaceBlur__why__right__h2--staxl.svg);
        }

        @media (max-width: $screen-mobiles) {
          width: 280px;
          max-width: 100%;
          height: 85px;
          margin-right: unset;
          margin-left: unset;
          background-image: url(/images/FaceBlur__why__right__h2--smo.svg);
        }
      }

      &__p {
        margin-top: calc(var(--global-space) * 1);
        color: #fff;
        font-family: Anakotmai-Light;
        font-size: 26px;

        @media (max-width: $screen-tablets-xl) {
          text-align: center;
        }

        @media (max-width: $screen-mobiles) {
          font-size: 20px;
          text-align: left;
        }
      }
    }
  }

  &__upload {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #fff;

    @media (min-width: $screen-tablets-xl + 1) {
      --app-width: 1400px;
      --app-padding: calc(var(--global-space) * 3);
    }

    @media (max-width: $screen-tablets-xl) {
      grid-template-columns: 1fr;
    }

    > .container {
      display: contents;
      width: 100%;

      @media (max-width: $screen-tablets-xl) {
        display: block;
      }
    }

    &__wrapper {
      display: grid;
      padding: calc(var(--global-space) * 6) var(--app-padding);
      padding-right: 0;
      justify-items: end;

      @media (max-width: $screen-tablets-xl) {
        padding-left: 0;
        justify-items: center;
      }

      @media (max-width: $screen-mobiles) {
        padding-top: calc(var(--global-space) * 3);
        padding-bottom: calc(var(--global-space) * 3);
      }
    }

    &__wrapper-inner {
      max-width: calc(var(--app-width) * 0.5);
      padding-right: var(--app-padding);

      @media (max-width: $screen-tablets-xl) {
        padding-right: 0;
      }
    }

    &__h2 {
      color: #2a2a2a;
      font-family: Anakotmai-Light;
      font-size: 49px;
      text-align: center;

      @media (max-width: $screen-mobiles) {
        font-size: 38px;
      }
    }

    &__p {
      margin-top: calc(var(--global-space) * 0.5);
      color: #000;
      font-family: Anakotmai-Light;
      font-size: 24px;
      text-align: center;

      @media (max-width: $screen-mobiles) {
        font-size: 20px;
      }
    }

    &__box {
      display: block;
      position: relative;
      margin-top: calc(var(--global-space) * 2);
      padding: calc(var(--global-space) * 1);
      padding: 2px;
      transition: box-shadow 0.2s;
      transition-timing-function: ease-in-out;
      border-radius: 32px;
      opacity: 1 !important;
      background-image: linear-gradient(#7f0ffb, #2d9efe);
      cursor: pointer;
      will-change: box-shadow;

      &--active {
        box-shadow: 0 0 8px 0 #f64f59;
      }

      input[type='file'] {
        display: none;
      }

      &__wrapper {
        padding: calc(var(--global-space) * 1);
        border-radius: 30px;
        background-color: #fff;

        @media (max-width: $screen-mobiles) {
          padding: calc(var(--global-space) * 0.75);
        }
      }

      &__wrapper-inner {
        padding: calc(var(--global-space) * 5) calc(var(--global-space) * 1);
        border: 1px dashed rgba(#6a6a6a, 0.5);
        border-radius: 24px;

        @media (max-width: $screen-mobiles) {
          padding-top: calc(var(--global-space) * 2);
          padding-bottom: calc(var(--global-space) * 2);
        }
      }

      &__label {
        &__img {
          margin: 0 auto;

          @media (max-width: $screen-mobiles) {
            max-width: 95px;
          }
        }

        &__p {
          margin-top: calc(var(--global-space) * 2);
          color: #2a2a2a;
          font-family: Anakotmai-Light;
          font-size: 28px;
          text-align: center;

          @media (max-width: $screen-tablets-xl) {
            font-size: 24px;
          }

          @media (max-width: $screen-mobiles) {
            margin-top: calc(var(--global-space) * 1);
            font-size: 16px;
          }
        }

        .Buttons {
          margin-top: calc(var(--global-space) * 2);

          @media (max-width: $screen-mobiles) {
            margin-top: calc(var(--global-space) * 1);
          }
        }

        .FaceBlur__button {
          @media (min-width: $screen-mobiles + 1) {
            padding-right: calc(var(--global-space) * 3);
            padding-left: calc(var(--global-space) * 3);
          }
        }
      }

      &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__right {
      position: relative;
      background-color: #000001;

      &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: $screen-tablets-xl) {
          position: static;
          height: unset;
        }

        &--uploaded {
          object-fit: contain;
        }
      }

      &__overlay {
        @include overlay;

        display: grid;
        position: absolute;
        align-content: center;
        color: #fff;
        font-family: Anakotmai-Medium;
        font-size: 24px;
        justify-items: center;
      }

      &__control {
        display: grid;
        position: absolute;
        bottom: 0;
        left: 0;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40%;
        padding: var(--app-padding);
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.5) 50%
        );

        @media (max-width: $screen-tablets-xl) {
          grid-gap: calc(var(--global-space) * 1);
          grid-template-columns: repeat(2, max-content);
        }

        @media (max-width: $screen-mobiles) {
          position: static;
          grid-template-columns: 1fr;
          padding-top: calc(var(--global-space) * 1.5);
          padding-bottom: 0;
        }

        > .FaceBlur__button {
          display: none;

          @media (max-width: $screen-tablets-xl) {
            display: block;
          }

          @media (max-width: $screen-mobiles) {
            grid-row: 2 / 3;
          }
        }

        &__wrapper {
          display: grid;
          grid-gap: calc(var(--global-space) * 1);
          grid-template-columns: repeat(2, max-content);

          @media (max-width: $screen-tablets-xl) {
            grid-template-columns: 1fr;
          }

          > * {
            @media (max-width: $screen-tablets-xl) {
              grid-area: 1 / 1 / 2 / 2;
              pointer-events: none;
            }

            &[class$='--trans'] {
              @media (max-width: $screen-tablets-xl) {
                opacity: 0;
                pointer-events: auto;
              }
            }
          }
        }
      }
    }
  }

  &__contact {
    padding: calc(var(--global-space) * 8) 0 calc(var(--global-space) * 4);

    @media (max-width: $screen-mobiles) {
      padding-top: calc(var(--global-space) * 4);
    }

    &__h3 {
      color: #fff;
      font-family: Anakotmai-Medium;
      font-size: 40px;
      text-align: center;

      @media (max-width: $screen-mobiles) {
        font-size: 26px;
      }
    }

    &__p {
      margin-top: calc(var(--global-space) * 1);
      color: #fff;
      font-family: Anakotmai-Light;
      font-size: 24px;
      text-align: center;

      @media (max-width: $screen-mobiles) {
        font-size: 17px;
      }
    }

    &__form {
      display: grid;
      grid-gap: calc(var(--global-space) * 0.75);
      max-width: 550px;
      margin: 0 auto;
      margin-top: calc(var(--global-space) * 2);
      margin-bottom: calc(var(--global-space) * 5);

      &__input {
        display: block;
        width: 100%;
        padding: calc(var(--global-space) * 0.75);
        border: 1px solid #e8e8e8;
        background-color: #fff;
        font-family: Anakotmai-Light;
        font-size: 18px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        &::placeholder {
          color: #ccc;
        }
      }

      .FaceBlur__button {
        width: 100%;
        margin-top: calc(var(--global-space) * 0.5);
        font-size: 20px;
      }
    }

    &__img {
      margin: 0 auto calc(var(--global-space) * 3);
    }

    &__footer {
      margin-top: calc(var(--global-space) * 1.5);
      color: #fff;
      font-family: Anakotmai-Light;
      font-size: 20px;
      text-align: center;
    }

    &__socials {
      display: grid;
      margin-top: calc(var(--global-space) * 1.5);
      justify-items: center;
    }
  }

  &__footer {
    padding: calc(var(--global-space) * 0.5) 0;
    border-top: 1px solid #282828;
    color: #888;
    font-family: Roboto-Regular;
    font-size: 14px;

    @media (max-width: $screen-mobiles-l) {
      text-align: center;
    }

    @media (max-width: $screen-mobiles-xs) {
      font-size: 12px;
    }

    > .container {
      display: grid;
      grid-gap: calc(var(--global-space) * 2);
      grid-template-columns: 1fr max-content max-content;
      width: 100%;

      @media (max-width: $screen-mobiles-l) {
        grid-gap: calc(var(--global-space) * 0.5);
        grid-template-columns: 1fr;
        justify-items: center;
      }
    }

    &__links {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: repeat(auto-fit, minmax(1px, max-content));
    }
  }
}

.Br {
  &--more-than {
    &--sta {
      @media (max-width: $screen-tablets) {
        display: none;
      }
    }

    &--smo {
      @media (max-width: $screen-mobiles) {
        display: none;
      }
    }

    &--smos {
      @media (max-width: $screen-mobiles-s) {
        display: none;
      }
    }
  }

  &--less-than {
    &--smol {
      @media (min-width: $screen-mobiles-l + 1) {
        display: none;
      }
    }

    &--smo {
      @media (min-width: $screen-mobiles + 1) {
        display: none;
      }
    }
  }
}

.Pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(var(--global-space) * 2);

  @media (max-width: $screen-mobiles) {
    justify-content: space-between;
    margin-top: calc(var(--global-space) * 1);
  }

  &__link {
    padding: calc(var(--global-space) * 0.5) calc(var(--global-space) * 1);
    transition: background-color 0.25s ease-out;
    color: #1a1a1a;
    font-family: $Poppins-Regular;
    font-size: 13px;
    letter-spacing: 0.54px;

    @media (max-width: $screen-mobiles) {
      display: none;
    }

    &:not(:first-child) {
      margin-left: calc(var(--global-space) * 0.25);
    }

    &:hover {
      background-color: #fafafa;
    }

    &--active {
      background-color: #ff775e;
      color: #fff;
      pointer-events: none;
    }

    &--icon {
      display: grid;
      grid-gap: calc(var(--global-space) * 0.25);
      align-items: center;
      padding-right: calc(var(--global-space) * 0.25);
      padding-left: calc(var(--global-space) * 0.25);

      @media (max-width: $screen-mobiles) {
        grid-template-columns: max-content max-content;
      }

      &:hover {
        background-color: transparent;
      }

      span {
        display: none;

        @media (max-width: $screen-mobiles) {
          display: block;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &--disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &__dots {
    width: 20px;
    height: 20px;

    @media (max-width: $screen-mobiles) {
      display: none;
    }
  }
}

.SearchResults {
  margin-bottom: calc(var(--global-space) * 2);

  &__h2 {
    @include h2;

    margin: 0 0 calc(var(--global-space) * 0.5);
    color: #1a1a1a;
  }

  &__h3 {
    @include h3;

    color: #1a1a1a;
    font-family: $Poppins-Regular;
  }

  &__hilight {
    color: #ff765e;
  }

  &__img {
    margin: calc(var(--global-space) * 5) auto;
  }
}

.Component {
  &__checkbox {
    display: grid;
    grid-gap: calc(var(--global-space) * 0.25);
    grid-template-columns: 20px 1fr;
    cursor: pointer;

    & > *:not(:first-of-type) {
      margin-left: 14px;
    }

    &__input-label {
      color: #fff;
      font-size: 16px;
    }

    &__input-container {
      position: relative;
      width: 20px;
      height: 20px;
      margin-top: 5px;
      transition: background 250ms;
      background-color: white;
      color: #03e2a7;

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      svg {
        width: 12px;
        height: 100%;
        margin: auto;
        padding: 0;
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-miterlimit: 1.5;
      }

      path {
        stroke-width: 6px;
        stroke-dashoffset: 0;
        stroke: none;
        fill: none;
      }

      &--checked {
        background-color: currentColor;

        path {
          stroke: black;
        }
      }
    }
  }
}

.Demo {
  color: #1a1a1a;

  &__h1 {
    span {
      color: #0083fd;
    }
  }

  &__h2 {
    @include h2;

    margin-bottom: calc(var(--global-space) * 1);

    @media screen and (max-width: $screen-mobiles) {
      margin-bottom: calc(var(--global-space) * 0.5);
    }
  }

  svg {
    display: inline-block;
    color: var(--color-tertiary);
  }

  &__p {
    margin: calc(var(--global-space) * 1.5) 0 calc(var(--global-space) * 3);
    font-size: 19px;
    letter-spacing: 0.79px;

    strong {
      font-family: $Poppins-SemiBold;
    }
  }

  &__hero {
    position: relative;
    height: calc(100vh - var(--app-nav-height));
    overflow: hidden;

    @media screen and (max-width: $screen-mobiles) {
      height: auto;
    }

    a {
      display: inline-block;

      @media screen and (max-width: $screen-mobiles) {
        display: none;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: calc(100% - 20vh);

      @media screen and (max-width: $screen-mobiles) {
        height: auto;
      }
    }

    &__body {
      width: 48%;

      @media screen and (max-width: $screen-mobiles) {
        width: 100%;
      }
    }

    &__space {
      height: 20vh;
      background-color: var(--color-tertiary);

      @media screen and (max-width: $screen-mobiles) {
        height: calc(var(--global-space) * 2);
        margin-left: calc(var(--global-space) * 3);
        border-top-left-radius: var(--global-radius);
      }
    }

    &__img {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      object-fit: cover;

      @media screen and (max-width: $screen-mobiles) {
        position: relative;
        right: auto;
        left: var(--app-padding);
        width: 100%;
        height: 80vw;
        border-radius: calc(var(--global-radius) * 0.5);
      }
    }
  }

  &__content {
    padding-top: calc(var(--global-space) * 5);
    padding-bottom: calc(var(--global-space) * 6);

    @media screen and (max-width: $screen-tablets-xl) {
      padding-top: calc(var(--global-space) * 3);
      padding-bottom: 0;
    }

    h2 {
      span {
        display: block;
        color: #0083fd;
      }
    }
  }

  &__list {
    display: grid;
    grid-gap: calc(var(--global-space) * 1.5);
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: $screen-tablets-xl) {
      margin: 0 calc(var(--global-space) * -1.25);
      padding: calc(var(--global-space) * 3) var(--app-padding)
        calc(var(--global-space) * 4);
      border-radius: var(--global-radius);
      background-color: #f2f3f5;
    }

    @media screen and (max-width: $screen-mobiles-l) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: calc(var(--global-space) * 2) calc(var(--global-space) * 1.5)
      calc(var(--global-space) * 1.5);
    border: 1px solid rgba(151, 151, 151, 0.32);
    border-radius: calc(var(--global-radius) * 0.5);
    background: #fff;

    @media screen and (max-width: $screen-tablets-xl) {
      padding: calc(var(--global-space) * 1.5);
      border: 0;
    }

    &__title {
      margin-bottom: calc(var(--global-space) * 0.5);
      font-family: $Poppins-SemiBold;
      font-size: 28px;
      letter-spacing: 1.17px;
    }

    &__description {
      font-size: 13px;
      letter-spacing: 0.54px;
    }

    &__body {
      display: grid;
      grid-gap: calc(var(--global-space) * 1.5);
      grid-template-columns: max-content 1fr;
      flex: 1;
      margin-bottom: calc(var(--global-space) * 1);

      @media screen and (max-width: $screen-tablets) {
        grid-template-columns: 1fr;
        margin-bottom: calc(var(--global-space) * 1);
      }
    }

    &__img {
      display: block;

      @media screen and (max-width: $screen-tablets) {
        display: none;
      }
    }

    &__link {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: repeat(3, max-content);
      align-items: center;
      transition: opacity var(--transition-duration) var(--ease-in-out-cubic);
      font-size: 19px;

      @media screen and (max-width: $screen-tablets) {
        grid-gap: calc(var(--global-space) * 0.5);
        font-size: 18px;
      }

      &:hover {
        opacity: 0.8;

        svg {
          transform: translateX(6px);
        }
      }

      svg {
        width: 35px;
        transition: transform var(--transition-duration)
          var(--ease-in-out-cubic);
        color: #0083fd;

        @media screen and (max-width: $screen-tablets-xl) {
          width: 24px;
        }
      }

      &__img {
        display: none;

        @media screen and (max-width: $screen-tablets) {
          display: block;
        }
      }
    }
  }
}

.DemoDetail {
  --navbar-height: 80px;
  --text-mute: #888;

  background: var(--background-color);
  color: var(--text-color);

  @media screen and (max-width: $screen-mobiles-l) {
    --navbar-height: 66px;
  }

  p {
    font-size: 19px;
    letter-spacing: 0.025rem;
  }

  &__header {
    position: sticky;
    z-index: 100;
    top: 0;
    padding: calc(var(--global-space) * 0.4) 0 calc(var(--global-space) * 0.5);
    transition: transform var(--transition-duration) var(--ease-in-out-cubic);
    background: var(--background-color);
    backdrop-filter: blur(4px);

    @media screen and (max-width: $screen-tablets-l) and (min-width: $screen-mobiles-l) {
      padding: 0;
    }

    &--hide {
      transform: translateY(-100%);
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: $screen-tablets-l) and (min-width: $screen-mobiles-l) {
        padding: 0;
      }
    }
  }

  &__logo {
    color: var(--color-primary);
    font-family: $Poppins-SemiBold;
    font-size: 22px;
    letter-spacing: 0.025rem;

    @media screen and (max-width: $screen-tablets-l) {
      display: none;
    }

    @media screen and (max-width: $screen-mobiles-l) {
      display: block;
      font-size: 20px;
    }
  }

  &__toggle-menu {
    display: none;

    @media screen and (max-width: $screen-mobiles-l) {
      display: block;
    }

    svg {
      width: 32px;
      height: 32px;
      fill: currentColor;
      color: var(--color-primary);
    }
  }

  &__nav {
    display: flex;

    @media screen and (max-width: $screen-tablets-l) {
      width: 100%;
    }

    @media screen and (max-width: $screen-mobiles-l) {
      visibility: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      flex-direction: column;
      width: 100%;
      transition: opacity var(--transition-duration) var(--ease-in-out-cubic);
      opacity: 0;
    }

    &--show {
      @media screen and (max-width: $screen-mobiles-l) {
        visibility: visible;
        opacity: 1;
      }
    }

    a {
      padding: calc(var(--global-space) * 0.25) calc(var(--global-space) * 1);
      font-family: $Poppins-SemiBold;
      font-size: 14px;
      letter-spacing: 0.025rem;

      @media screen and (max-width: $screen-tablets-l) {
        flex: 1;
        padding: calc(var(--global-space) * 1) 0;
        border-right: 1px solid #e8e8e8;
        background: var(--background-color-invert);
        color: var(--text-color-invert);
        text-align: center;
      }

      @media screen and (max-width: $screen-mobiles-l) {
        border: 0;
        background: var(--background-color);
        color: var(--text-color);
      }

      &:last-of-type {
        margin-left: calc(var(--global-space) * 1);
        transition: var(--transition-duration) var(--ease-in-out-cubic);
        border: 2px solid var(--color-primary);
        border-radius: 100px;

        &:hover {
          background: var(--color-primary);
          color: var(--text-color-invert);
        }
      }

      @media screen and (max-width: $screen-tablets-l) {
        flex: 1;
        padding: calc(var(--global-space) * 1) 0;
        border-right: 1px solid #e8e8e8;
        background: var(--backgroound-color-invert);
        color: var(--text-color-invert);
        text-align: center;

        &:last-of-type {
          margin-left: 0;
          border: 0;
          border-radius: 0;
          background: var(--color-primary);
        }
      }

      @media screen and (max-width: $screen-mobiles-l) {
        border: 0;
        background: var(--background-color);
        color: var(--text-color);

        &:last-of-type {
          color: var(--text-color-invert);
        }
      }
    }
  }

  &__wrapper {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__title {
    margin-bottom: calc(var(--global-space) * 0.5);
    font-family: $Poppins-SemiBold;
    font-size: 60px;
    line-height: 1;
    text-transform: uppercase;

    &--hilight {
      color: var(--color-primary);
    }

    @media screen and (max-width: $screen-tablets-l) {
      font-size: 44px;
    }

    @media screen and (max-width: $screen-mobiles-l) {
      font-size: 36px;
    }

    span {
      display: block;
      font-size: 0.7em;
      text-transform: none;

      @media screen and (max-width: $screen-tablets-l) {
        display: inline-block;
        margin-left: calc(var(--global-space) * 0.5);
        font-size: 1em;
      }

      @media screen and (max-width: $screen-mobiles-l) {
        display: block;
        margin-left: 0;
      }
    }
  }

  &__hero {
    position: relative;
    z-index: 1;
    text-align: center;

    h4 {
      font-family: $Poppins-Regular;
      font-size: 24px;

      @media screen and (max-width: $screen-mobiles-l) {
        font-size: 19px;
      }
    }

    p {
      max-width: 800px;
    }

    a {
      margin: calc(var(--global-space) * 2) 0;
    }

    &__content {
      display: flex;
      position: relative;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__why {
    position: relative;
    margin-top: calc(var(--global-space) * -4);
    padding-top: calc(var(--global-space) * 14);
    padding-bottom: calc(var(--global-space) * 6);

    @media screen and (max-width: $screen-tablets-l) {
      margin-top: 0;
      padding-top: calc(var(--global-space) * 8);
      background: none;
    }

    @media screen and (max-width: $screen-mobiles-l) {
      padding-top: calc(var(--global-space) * 8);
    }

    .container {
      display: grid;
      position: relative;
      grid-gap: calc(var(--global-space) * 4);
      grid-template-columns: repeat(2, 1fr);

      @media screen and (max-width: $screen-tablets-l) {
        grid-template-columns: 1fr;
      }
    }

    &__img {
      --size: calc(var(--global-space) * 1.5);

      position: relative;

      @media screen and (max-width: $screen-tablets-l) {
        width: 50%;
        margin: 0 auto;
      }

      @media screen and (max-width: $screen-mobiles-l) {
        --size: calc(var(--global-space) * 1);

        width: 80%;
        margin: 0;
        margin-left: calc(var(--size) * -4);
      }

      @media screen and (max-width: $screen-mobiles-s) {
        width: 100%;
      }

      &::before,
      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &::before {
        transform: translate(calc(var(--size) * -1), calc(var(--size) * 2));
        background: var(--color-primary);

        @media screen and (max-width: $screen-tablets-l) {
          display: block;
        }
      }

      &::after {
        transform: translate(calc(var(--size) * 1), calc(var(--size) * 1));
        background: rgba(255, 255, 255, 0.15);
        mix-blend-mode: difference;
      }

      img {
        position: relative;
        z-index: 1;
      }
    }

    &__content {
      align-self: center;
    }
  }

  &__upload {
    position: relative;
    padding: calc(var(--global-space) * 4) 0 calc(var(--global-space) * 5);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    header {
      text-align: center;

      @media screen and (max-width: $screen-mobiles-l) {
        text-align: left;
      }
    }

    h4 {
      font-family: $Poppins-Regular;
      font-size: 28px;

      @media screen and (max-width: $screen-mobiles-l) {
        font-size: 20px;
      }
    }

    h6 {
      font-family: $Poppins-SemiBold;
      font-size: 28px;
      letter-spacing: 0.025rem;

      @media screen and (max-width: $screen-mobiles-l) {
        font-size: 20px;
      }

      span {
        font-weight: 400;
      }
    }

    hr {
      display: none;

      @media screen and (max-width: $screen-tablets-xl) {
        display: block;
        width: 30%;
        height: 2px;
        margin: calc(var(--global-space) * 2) auto 0;
        border: 0;
        background: var(--color-primary);
      }
    }

    &__content {
      display: grid;
      position: relative;
      grid-template-columns: repeat(2, 1fr);
      max-width: 1600px;
      margin: calc(var(--global-space) * 3) auto 0;
      overflow: hidden;
      border: 1px solid var(--color-primary);
      border-radius: calc(var(--global-radius) * 1.5);

      @media screen and (max-width: $screen-tablets-xl) {
        grid-template-columns: 1fr;
        max-width: 620px;
        margin-top: calc(var(--global-space) * 2);
        padding: 0 var(--app-padding);
        border: 0;
        border-radius: 0;
      }
    }

    &__form {
      padding: calc(var(--global-space) * 2);
      border-right: 1px solid var(--color-primary);
      background-color: rgba(255, 255, 255, 0.2);
      font-size: 21px;

      @media screen and (max-width: $screen-mobiles) {
        font-size: 16px;
      }

      @media screen and (max-width: $screen-tablets-xl) {
        margin-bottom: calc(var(--global-space) * 3);
        padding: 0;
        border: 0;
        background: none;
      }

      h6 {
        margin-bottom: calc(var(--global-space) * 1);

        @media screen and (max-width: $screen-tablets-xl) {
          text-align: center;
        }

        @media screen and (max-width: $screen-mobiles-l) {
          text-align: left;
        }
      }

      input[type='file'] {
        visibility: hidden;
        position: absolute;
      }

      img {
        margin-bottom: calc(var(--global-space) * 0.5);

        @media screen and (max-width: $screen-mobiles) {
          width: 96px;
        }
      }

      .DemoDetail__button {
        margin-top: calc(var(--global-space) * 1.5);
        padding: calc(var(--global-space) * 0.75)
          calc(var(--global-space) * 2.5);
      }
    }

    &__frame {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 540px;
      border: 1px dashed #fff;

      @media screen and (max-width: $screen-tablets-xl) {
        position: relative;
        height: auto;
        padding: calc(var(--global-space) * 1);
        overflow: hidden;
        border: 1px solid var(--color-primary);
        border-radius: calc(var(--global-radius) * 1);
        background-color: rgba(255, 255, 255, 0.2);
      }

      &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 16px;

        @media screen and (max-width: $screen-tablets-xl) {
          height: 55vw;
          border: 1px dashed;
          border-radius: calc(var(--global-radius) * 0.8);
        }

        @media screen and (max-width: $screen-mobiles-l) {
          height: 80vw;
        }
      }

      .DemoDetail__button {
        position: relative;
        cursor: pointer;
      }
    }

    &__button-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;

      @media screen and (max-width: $screen-mobiles-l) {
        grid-template-columns: 1fr;
      }

      .DemoDetail__button {
        margin-right: auto;
        margin-left: auto;
      }
    }

    &__api {
      padding: calc(var(--global-space) * 2);
      background-color: var(--background-color);

      @media screen and (max-width: $screen-tablets-xl) {
        padding: 0;
        background: none;
      }

      header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: calc(var(--global-space) * 1);
      }

      pre {
        position: relative;
        height: 540px;
        padding: calc(var(--global-space) * 1);
        overflow: auto;
        border: 1px dashed;

        @media screen and (max-width: $screen-tablets-xl) {
          height: 55vw;
          border: 1px solid var(--color-primary);
          border-radius: calc(var(--global-radius) * 1);
          background-color: var(--background-color);
        }

        @media screen and (max-width: $screen-mobiles-l) {
          height: 80vw;
          font-size: 16px;
        }
      }
    }

    &__imgs {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @media screen and (max-width: $screen-tablets-xl) {
        padding: calc(var(--global-space) * 1);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &--two-columns {
        img {
          width: 50%;
          object-fit: cover;
        }
      }
    }
  }

  &__contact {
    padding-top: calc(var(--global-space) * 8);
    padding-bottom: calc(var(--global-space) * 3);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @media screen and (max-width: $screen-mobiles-l) {
      padding-top: calc(var(--global-space) * 4);
    }

    h4 {
      margin-bottom: calc(var(--global-space) * 0.5);
      font-family: Anakotmai-Medium, sans-serif;
      font-size: 40px;
      line-height: 1.3;
      text-align: center;

      @media screen and (max-width: $screen-mobiles-l) {
        font-size: 26px;
      }

      & + p {
        margin-bottom: calc(var(--global-space) * 1.5);
        font-size: 24px;
        text-align: center;

        @media screen and (max-width: $screen-mobiles-l) {
          font-size: 17px;
        }

        strong {
          font-family: Anakotmai-Medium, sans-serif;
        }
      }
    }

    a {
      font-family: $Poppins-SemiBold;

      &:hover {
        text-decoration: underline;
      }
    }

    form {
      max-width: 600px;
      margin: 0 auto;

      input {
        display: block;
        width: 100%;
        margin-bottom: calc(var(--global-space) * 0.75);
        padding: calc(var(--global-space) * 0.75);
        border: 0;
        background: #fff;
        font-family: $Poppins-Regular;
        font-size: 16px;

        &::placeholder {
          color: #ccc;
        }
      }

      .DemoDetail__button {
        display: block;
        width: 100%;
        margin-top: calc(var(--global-space) * 1.5);
        font-size: 20px;
        text-align: center;
      }
    }

    &__p {
      margin-bottom: calc(var(--global-space) * 1);
      text-align: center;
    }

    &__info {
      margin-top: calc(var(--global-space) * 6);
      font-size: 20px;
      text-align: center;

      @media screen and (max-width: $screen-mobiles) {
        margin-top: calc(var(--global-space) * 3);
        font-size: 16px;
      }

      address {
        font-style: normal;

        strong {
          display: block;
          font-family: $Poppins-SemiBold;
        }

        br {
          @media screen and (max-width: $screen-mobiles) {
            display: none;
          }
        }
      }

      p {
        margin-top: calc(var(--global-space) * 1.5);
      }
    }
  }

  &__spec {
    margin-bottom: calc(var(--global-space) * 3);

    &__p {
      margin-bottom: calc(var(--global-space) * 1);
      text-align: left;
    }

    img {
      margin-top: calc(var(--global-space) * 3);
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(var(--global-space) * 2);

    a {
      display: block;
      margin: 0 calc(var(--global-space) * 0.75);
    }

    img {
      display: block;
      width: 24px;
      height: 24px;

      @media screen and (max-width: $screen-mobiles-l) {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__footer {
    box-shadow: inset 0 1px 0 0 var(--footer-box-shadow);
    color: var(--text-mute);
    font-size: 13px;

    p {
      font-size: inherit;
    }

    @media screen and (max-width: $screen-mobiles-l) {
      padding-top: calc(var(--global-space) * 1);
    }

    strong {
      font-family: $Poppins-SemiBold;
    }

    .container {
      display: flex;
      justify-content: space-between;
      padding: calc(var(--global-space) * 0.75) 0;

      @media screen and (max-width: $screen-mobiles-l) {
        flex-direction: column-reverse;
        align-items: center;
      }
    }

    nav {
      display: flex;

      @media screen and (max-width: $screen-mobiles-l) {
        flex-direction: column;
        margin-top: calc(var(--global-space) * 1);
      }

      a {
        margin-right: calc(var(--global-space) * 1.5);

        @media screen and (max-width: $screen-mobiles-l) {
          margin-right: 0;
          margin-bottom: calc(var(--global-space) * 1);
        }
      }
    }

    &__space {
      flex: 1;
    }

    svg {
      display: inline-block;
      width: 18px;
      vertical-align: text-top;
    }
  }

  &__button {
    display: inline-block;
    padding: calc(var(--global-space) * 0.75) calc(var(--global-space) * 1.5);
    transition: opacity var(--transition-duration) var(--ease-in-out-cubic);
    border: 0;
    border-radius: 100px;
    outline: 0;
    background: var(--color-primary);
    color: var(--button-color);
    font-family: $Poppins-SemiBold;
    font-size: 22px;
    letter-spacing: 0.025rem;

    @media screen and (max-width: $screen-mobiles) {
      padding: calc(var(--global-space) * 0.5) calc(var(--global-space) * 2);
      font-size: 20px;
    }

    &:hover {
      opacity: 0.9;
    }

    &--disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &--sm {
      padding: calc(var(--global-space) * 0.25) calc(var(--global-space) * 0.75);
      font-size: 18px;

      @media screen and (max-width: $screen-mobiles) {
        font-size: 12px;
      }

      svg {
        display: inline-block;
        width: 20px;
        vertical-align: middle;

        @media screen and (max-width: $screen-mobiles) {
          width: 14px;
        }
      }
    }
  }
}

.OCR {
  --color-primary: #3078ff;
  --button-color: #fff;
  --background-color: #000;
  --background-color-invert: #fff;
  --text-color: #fff;
  --text-color-invert: #000;
  --footer-box-shadow: rgba(0, 0, 0, 0.05);

  background-color: #000837;

  &__scan {
    position: relative;
    width: 595px;
    height: 400px;

    @media screen and (max-width: $screen-tablets) {
      width: 300px;
      height: 200px;
    }

    &__card {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 3%;
    }

    &__mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &__outer,
      &__inner {
        position: relative;
        height: 100%;
        overflow: hidden;
      }
    }

    &__handle {
      --height: 10px;

      position: absolute;
      top: calc(50% - (var(--height) / 2));
      left: 0;
      width: 100%;
      height: var(--height);
      transition: opacity var(--transition-duration) var(--ease-in-out-cubic);
      border-radius: 10em;
      background: rgba(251, 2, 13, 1);
      box-shadow: 0 0 30px 0 rgba(255, 255, 255, 0.5),
        0 0 10px 0 rgba(255, 255, 255, 0.8);
      cursor: pointer;

      @media screen and (max-width: $screen-tablets) {
        --height: 6px;
      }
    }

    .hilight {
      &__id-card {
        position: absolute;
        height: 6%;
        background-color: rgba(255, 103, 103, 0.8);

        &:nth-of-type(1) {
          top: 30.5%;
          left: 55%;
          width: 32%;
        }

        &:nth-of-type(2) {
          top: 40%;
          left: 57%;
          width: 20%;
        }

        &:nth-of-type(3) {
          top: 46.5%;
          left: 65%;
          width: 17.5%;
        }

        &:nth-of-type(4) {
          top: 53%;
          left: 54%;
          width: 22%;
        }
      }

      &__driver {
        position: absolute;
        height: 6%;
        background-color: rgba(255, 103, 103, 0.8);

        &:nth-of-type(1) {
          top: 28.5%;
          left: 43%;
          width: 17%;
        }

        &:nth-of-type(2) {
          top: 34.8%;
          left: 43%;
          width: 11.4%;
        }

        &:nth-of-type(3) {
          top: 41.2%;
          left: 53.4%;
          width: 16.4%;
        }

        &:nth-of-type(4) {
          top: 48.7%;
          left: 53.4%;
          width: 18.4%;
        }

        &:nth-of-type(5) {
          top: 56%;
          left: 53.4%;
          width: 20.2%;
        }
      }

      &__passport {
        position: absolute;
        height: 6%;
        background-color: rgba(255, 103, 103, 0.8);

        &:nth-of-type(1) {
          top: 22.5%;
          left: 36%;
          width: 20.5%;
        }

        &:nth-of-type(2) {
          top: 32.8%;
          left: 36%;
          width: 15.5%;
        }

        &:nth-of-type(3) {
          top: 43.1%;
          left: 36%;
          width: 19%;
        }

        &:nth-of-type(4) {
          top: 53.2%;
          left: 36%;
          width: 19%;
        }

        &:nth-of-type(5) {
          top: 63.6%;
          left: 36%;
          width: 19%;
        }

        &:nth-of-type(6) {
          top: 22.5%;
          left: 62%;
          width: 21%;
        }

        &:nth-of-type(7) {
          top: 32.5%;
          left: 62%;
          width: 21%;
        }

        &:nth-of-type(8) {
          top: 43%;
          left: 62%;
          width: 2.6%;
        }
      }
    }
  }

  .DemoDetail {
    &__nav {
      @media screen and (max-width: $screen-tablets-l) {
        background: var(--background-color-invert);
      }

      a:last-of-type {
        &:hover {
          color: var(--button-color);

          @media screen and (max-width: $screen-tablets-l) {
            border-color: var(--color-primary);
            background: var(--color-primary);
          }
        }

        @media screen and (max-width: $screen-tablets-l) {
          border: 2px solid;
          background-color: var(--background-color-invert);
          color: var(--color-primary);
        }
      }
    }

    &__feature {
      display: grid;
      grid-gap: calc(var(--global-space) * 2);
      grid-template-columns: repeat(5, max-content);
      align-items: center;
      justify-content: center;
      padding: calc(var(--global-space) * 4) 0;

      @media screen and (max-width: 1500px) {
        grid-template-columns: repeat(3, max-content);
      }

      @media screen and (max-width: $screen-tablets-l) {
        padding: calc(var(--global-space) * 2) 0;
      }

      @media screen and (max-width: $screen-mobiles) {
        grid-gap: calc(var(--global-space) * 1);
      }

      &__img {
        width: 300px;

        @media screen and (max-width: 1500px) {
          display: none;
        }
      }

      button {
        border: 0;
        outline: 0;
        background: none;

        @media screen and (max-width: $screen-tablets) {
          width: 24px;
        }
      }
    }

    &__wrapper {
      background-image: url(/images/demo/ocr/bg.png);
    }

    &__upload {
      background-image: url(/images/demo/ocr/bg-form.jpg);

      &__types {
        display: flex;
        justify-content: center;
        margin-top: calc(var(--global-space) * 1);

        @media screen and (max-width: $screen-mobiles-l) {
          flex-direction: column;
        }

        label {
          display: flex;
          align-items: center;
          font-size: 22px;
          cursor: pointer;

          &:not(:first-of-type) {
            margin-left: calc(var(--global-space) * 2.5);
          }

          @media screen and (max-width: $screen-mobiles-l) {
            margin-bottom: calc(var(--global-space) * 1);
            margin-left: 0 !important;
            font-size: 18px;
          }
        }

        input {
          width: 1em;
          height: 1em;
          margin-right: calc(var(--global-space) * 0.5);
        }
      }

      &__form {
        background-color: rgba(0, 0, 0, 0.2);

        @media screen and (max-width: $screen-tablets-xl) {
          background-color: transparent;
        }
      }

      &__frame {
        border-color: var(--color-primary);
        background-color: rgba(0, 8, 55, 0.5);
      }

      &__api {
        background-color: #000837;

        @media screen and (max-width: $screen-tablets-xl) {
          background: none;
        }

        pre {
          border-color: var(--color-primary);
          background-color: rgba(255, 255, 255, 0.1);
          white-space: pre-wrap;

          @media screen and (max-width: $screen-tablets-xl) {
            background-color: rgba(0, 8, 55, 0.5);
          }
        }
      }
    }

    &__contact {
      background-image: url(/images/demo/ocr/bg.png);
    }
  }
}

.Demographic {
  --color-primary: #00e7a3;
  --button-color: #000;
  --background-color: #000;
  --backgroound-color-invert: #fff;
  --text-color: #fff;
  --text-color-invert: #000;
  --footer-box-shadow: rgba(255, 255, 255, 0.15);

  .DemoDetail {
    &__wrapper {
      background-image: url(/images/demo/demographic/bg.png);
    }

    &__feature {
      display: flex;
      position: relative;
      align-items: flex-start;
      justify-content: center;
      margin: calc(var(--global-space) * 2) 0 calc(var(--global-space) * 4);
      padding: 0 var(--app-padding);

      @media screen and (max-width: $screen-tablets-l) {
        width: 620px;
        margin-right: auto;
        margin-bottom: calc(var(--global-space) * 3);
        margin-left: auto;
      }

      @media screen and (max-width: $screen-mobiles) {
        width: 350px;
        margin-top: calc(var(--global-space) * 1);
        margin-bottom: calc(var(--global-space) * 2);
      }

      li {
        position: relative;
        margin-left: calc(var(--global-space) * 1.5);

        .frame {
          position: absolute;
          border: 2px solid var(--color-primary);
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:nth-child(1) {
          margin-top: 80px;

          @media screen and (max-width: $screen-tablets-l) {
            position: absolute;
            top: 44px;
            left: 60px;
            width: 120px;
            height: 200px;
            margin: 0;
          }

          @media screen and (max-width: $screen-mobiles) {
            top: 24px;
            left: 30px;
            width: 70px;
            height: 110px;
          }

          .frame {
            top: 22%;
            left: 28%;
            width: 45%;
            height: 28%;

            @media screen and (max-width: $screen-tablets-l) {
              top: 28%;
            }
          }

          p {
            top: 54%;
            left: 28%;
            color: var(--color-primary);

            @media screen and (max-width: $screen-tablets-l) {
              top: 12%;
            }

            @media screen and (max-width: $screen-mobiles) {
              top: 65%;
            }
          }
        }

        &:nth-child(2) {
          margin-top: 180px;

          @media screen and (max-width: $screen-tablets-l) {
            position: absolute;
            bottom: 30px;
            left: 0;
            width: 180px;
            height: 200px;
            margin: 0;
          }

          @media screen and (max-width: $screen-mobiles) {
            bottom: 10px;
            width: 100px;
            height: 110px;
          }

          .frame {
            top: 12%;
            left: 18%;
            width: 39%;
            height: 28%;

            @media screen and (max-width: $screen-tablets-l) {
              top: 21%;
              left: 18%;
              width: 40%;
              height: 44%;
            }
          }

          p {
            top: 44%;
            left: 18%;

            @media screen and (max-width: $screen-tablets-l) {
              top: 70%;
            }
          }
        }

        &:nth-child(3) {
          @media screen and (max-width: $screen-tablets-l) {
            position: relative;
            width: 210px;
            height: 500px;
            margin: 0;
          }

          @media screen and (max-width: $screen-mobiles) {
            width: 120px;
            height: 270px;
          }

          .frame {
            top: 14%;
            left: 35%;
            width: 32%;
            height: 30%;

            @media screen and (max-width: $screen-tablets-l) {
              top: 12%;
              left: 26%;
              width: 50%;
              height: 32%;
              border-width: 3px;
            }
          }

          p {
            top: 3%;
            left: 35%;
            color: var(--color-primary);

            @media screen and (max-width: $screen-tablets-l) {
              top: 47%;
              left: 26%;
              font-size: 14px;
            }

            @media screen and (max-width: $screen-mobiles) {
              top: 4%;
              font-size: 6px;
            }
          }
        }

        &:nth-child(4) {
          margin-top: 120px;

          @media screen and (max-width: $screen-tablets-l) {
            position: absolute;
            top: 15px;
            right: 40px;
            width: 140px;
            height: 200px;
            margin: 0;
          }

          @media screen and (max-width: $screen-mobiles) {
            top: 10px;
            right: 20px;
            width: 80px;
            height: 110px;
          }

          .frame {
            top: 9%;
            left: 29%;
            width: 42%;
            height: 34%;

            @media screen and (max-width: $screen-tablets-l) {
              top: 12%;
              left: 28%;
              width: 43%;
              height: 43%;
            }
          }

          p {
            top: 48%;
            left: 29%;

            @media screen and (max-width: $screen-tablets-l) {
              top: 62%;
            }
          }
        }

        &:nth-child(5) {
          margin-top: 40px;

          @media screen and (max-width: $screen-tablets-l) {
            position: absolute;
            right: 0;
            bottom: 54px;
            width: 180px;
            height: 200px;
            margin: 0;
          }

          @media screen and (max-width: $screen-mobiles) {
            bottom: 24px;
            width: 100px;
            height: 110px;
          }

          .frame {
            top: 16%;
            left: 28%;
            width: 42%;
            height: 26%;

            @media screen and (max-width: $screen-tablets-l) {
              top: 28%;
              left: 25%;
              width: 48%;
              height: 42%;
            }
          }

          p {
            top: 48%;
            left: 28%;

            @media screen and (max-width: $screen-tablets-l) {
              top: 75%;
              left: 25%;
            }
          }
        }

        &:hover {
          &::before {
            visibility: visible;
            transform: translate(8px, 8px);

            @media screen and (max-width: $screen-tablets-l) {
              transform: translate(6px, 6px);
            }

            @media screen and (max-width: $screen-mobiles) {
              transform: translate(4px, 4px);
            }
          }
        }

        &::before {
          content: ' ';
          visibility: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: transform var(--transition-duration)
            var(--ease-in-out-cubic);
          background: #2a9bf2;
        }
      }

      p {
        position: absolute;
        color: var(--text-color-invert);
        font-family: Anakotmai-Medium, sans-serif;
        font-size: 16px;
        line-height: 1.4;
        text-align: left;

        @media screen and (max-width: $screen-tablets-l) {
          font-size: 8px;
        }

        @media screen and (max-width: $screen-mobiles) {
          font-size: 6px;
        }
      }

      img {
        display: block;
        position: relative;
        object-fit: cover;
        object-position: top center;

        @media screen and (max-width: $screen-tablets-l) {
          width: 100%;
          height: 100%;
        }
      }

      .outer,
      .inner {
        width: 100%;
        height: 100%;
      }

      .outer {
        position: relative;
        overflow: hidden;
      }
    }

    &__upload {
      background-image: url(/images/demo/demographic/bg-form.jpg);
    }

    &__contact {
      background-image: url(/images/demo/demographic/bg.png);
    }
  }
}

.Detection {
  --color-primary: #001796;
  --button-color: #fff;
  --background-color: #fff;
  --backgroound-color-invert: #000;
  --text-color: #000;
  --text-color-invert: fff;
  --footer-box-shadow: rgba(0, 0, 0, 0.05);

  .DemoDetail {
    &__header {
      position: fixed;
      width: 100%;
      background: rgba(255, 255, 255, 0.9);
    }

    &__nav {
      a {
        white-space: nowrap;

        @media screen and (max-width: $screen-tablets-l) {
          background: var(--background-color);
        }

        &:last-of-type {
          color: var(--color-primary);

          &:hover {
            color: var(--button-color);
          }
        }
      }
    }

    &__wrapper {
      background-image: url(/images/demo/detection/bg.png);
      background-position: center right;
      background-size: auto;
    }

    &__feature {
      position: relative;
      height: 0;
      padding-top: calc(770 / 1800 * 100%);
      overflow: hidden;

      @media screen and (max-width: $screen-tablets) {
        padding-top: calc(650 / 770 * 100%);
      }

      &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          max-height: none;
          object-fit: cover;
          object-position: 25%;
        }
      }

      &__labels {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        div {
          position: absolute;
          border: 3px solid var(--color);
        }

        span {
          display: inline-block;
          position: absolute;
          padding: calc(var(--global-space) * 0.1)
            calc(var(--global-space) * 0.25);
          background-color: var(--color);
          font-family: Anakotmai-Medium, sans-serif;
          font-size: 10px;
          line-height: 1.4;
          text-transform: capitalize;
          white-space: nowrap;
        }

        &--building {
          --color: #ff07d2;

          div {
            &:nth-of-type(1) {
              top: 14%;
              left: 0;
              width: 21%;
              height: 52%;

              @media screen and (max-width: $screen-tablets) {
                display: none;
              }

              span {
                right: 0;
                bottom: 0;
              }
            }

            &:nth-of-type(2) {
              top: 14%;
              left: calc(21% - 3px);
              width: 11%;
              height: 58%;

              @media screen and (max-width: $screen-tablets) {
                left: 17%;
                width: 21%;
                height: 58%;
              }

              span {
                top: 10%;
                right: -52px;

                @media screen and (max-width: $screen-tablets) {
                  top: 5%;
                }
              }
            }
          }
        }

        &--car {
          --color: #0093ff;

          div {
            &:nth-of-type(1) {
              top: 74%;
              left: 16.8%;
              width: 11%;
              height: 24%;

              @media screen and (max-width: $screen-tablets) {
                top: 74%;
                left: 8%;
                width: 22%;
                height: 24%;
              }

              span {
                top: 10%;
                right: -28px;
              }
            }
          }
        }

        &--bag {
          --color: #00ff85;

          div {
            &:nth-of-type(1) {
              top: 64%;
              left: 34.6%;
              width: 3.7%;
              height: 17%;

              @media screen and (max-width: $screen-tablets) {
                top: 64%;
                left: 44%;
                width: 7%;
                height: 17%;
              }

              span {
                top: -3px;
                left: -30px;
              }
            }

            &:nth-of-type(2) {
              top: 66%;
              left: 44.2%;
              width: 3.7%;
              height: 24%;

              @media screen and (max-width: $screen-tablets) {
                top: 64%;
                left: 63%;
                width: 7%;
                height: 26%;
              }

              span {
                top: 3%;
                right: -30px;
              }
            }

            &:nth-of-type(3) {
              top: 52%;
              left: 70%;
              width: 5%;
              height: 24%;

              @media screen and (max-width: $screen-tablets) {
                display: none;
              }

              span {
                top: -20px;
                left: -3px;
              }
            }
          }
        }

        &--clock {
          --color: #ff9b00;

          div {
            &:nth-of-type(1) {
              top: 31%;
              left: 83.6%;
              width: 8.7%;
              height: 20%;

              @media screen and (max-width: $screen-tablets) {
                display: none;
              }

              span {
                right: -3px;
                bottom: -20px;
              }
            }
          }
        }

        &--people {
          --color: #fff300;

          div {
            &:nth-of-type(1) {
              top: 45%;
              left: 35%;
              width: 10%;
              height: 54%;

              @media screen and (max-width: $screen-tablets) {
                top: 45%;
                left: 44%;
                width: 19%;
                height: 54%;
              }

              span {
                top: -20px;
                right: -3px;
              }
            }

            &:nth-of-type(2) {
              top: 25%;
              left: 47%;
              width: 11%;
              height: 74%;

              @media screen and (max-width: $screen-tablets) {
                top: 24%;
                left: 68%;
                width: 20%;
                height: 75%;
              }

              span {
                top: -20px;
                right: -3px;

                @media screen and (max-width: $screen-tablets) {
                  top: -5%;
                }
              }
            }

            &:nth-of-type(3) {
              top: 65%;
              left: 59%;
              width: 6%;
              height: 34%;

              @media screen and (max-width: $screen-tablets) {
                display: none;
              }

              span {
                top: -20px;
                left: -3px;
              }
            }

            &:nth-of-type(4) {
              top: 63%;
              left: calc(65% - 3px);
              width: 5%;
              height: 36%;

              @media screen and (max-width: $screen-tablets) {
                display: none;
              }

              span {
                top: -20px;
                left: -3px;
              }
            }

            &:nth-of-type(5) {
              top: 27%;
              left: 70%;
              width: 13%;
              height: 69%;

              @media screen and (max-width: $screen-tablets) {
                display: none;
              }

              span {
                top: -20px;
                right: -3px;
              }
            }
          }
        }
      }
    }

    &__hero__content {
      padding: calc(var(--global-space) * 3) 0 calc(var(--global-space) * 5);
      background: url(/images/demo/detection/bg.png) no-repeat top center;
      background-size: cover;

      @media screen and (max-width: $screen-tablets-l) {
        background: none;
      }
    }

    &__why {
      margin-top: 0;
      padding-top: 0;

      &__img {
        &::after {
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
    }

    &__upload {
      background-image: url(/images/demo/detection/bg-form.jpg);

      h6 {
        color: var(--color-primary);
      }

      &__frame,
      pre {
        border-color: var(--color-primary);
      }
    }

    &__contact {
      background-image: url(/images/demo/detection/bg-contact.png);
      background-size: cover;

      h4 {
        color: var(--color-primary);
      }

      form input {
        border: 1px solid var(--color-primary);
      }
    }
  }
}

.FaceEmbedding {
  --color-primary: #55f1ff;
  --button-color: #000;
  --background-color: #0d0018;
  --backgroound-color-invert: #fff;
  --text-color: #fff;
  --text-color-invert: #000;
  --footer-box-shadow: rgba(255, 255, 255, 0.15);

  .DemoDetail {
    &__hero {
      &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;

        @media screen and (max-width: $screen-tablets-l) {
          position: relative;
          height: auto;
          margin-bottom: calc(var(--global-space) * 2);
        }

        @media screen and (max-width: $screen-mobiles-l) {
          margin-bottom: calc(var(--global-space) * 1);
        }
      }

      .container {
        display: flex;
        position: relative;
        z-index: 1;
        flex-direction: column;
        align-items: center;
        height: calc(100vh - var(--navbar-height));

        @media screen and (max-width: $screen-tablets-l) {
          height: auto;
        }
      }
    }

    &__why {
      &__img {
        &::after {
          background: rgba(35, 0, 66, 0.15);
          mix-blend-mode: none;
        }
      }

      &__content {
        h2 {
          span {
            white-space: nowrap;
          }
        }
      }
    }

    &__wrapper {
      background-image: url(/images/demo/face-embedding/bg.png);
      background-position: left center;
      background-size: 40%;
    }

    &__upload {
      background-image: url(/images/demo/face-embedding/bg-form.jpg);

      &__form {
        background: rgba(0, 0, 0, 0.1);
      }

      &__frame {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    &__contact {
      background-image: url(/images/demo/face-embedding/bg.png),
        url(/images/demo/face-embedding/bg-contact.png);
      background-position: top left, bottom right;
      background-size: 40%, 40%;
    }
  }
}

.FaceVerificationAPI {
  --color-primary: #042378;
  --button-color: #fff;
  --background-color: #fff;
  --background-color-invert: #000;
  --text-color: #000;
  --text-color-invert: #fff;
  --footer-box-shadow: rgba(0, 0, 0, 0.05);

  .DemoDetail {
    &__nav {
      a {
        color: var(--text-color);

        &:last-of-type {
          background-color: var(--background-color);
          color: var(--color-primary);

          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }

    &__hero {
      padding-top: calc(var(--global-space) * 2);

      .container {
        position: relative;
        z-index: 10;
      }

      &__ratio {
        height: 0;
        padding-top: 60%;

        @media screen and (max-width: $screen-tablets) {
          padding-top: 70%;
        }

        @media screen and (max-width: $screen-mobiles) {
          padding-top: 90%;
        }

        @media screen and (max-width: $screen-mobiles-s) {
          padding-top: 110%;
        }
      }

      &__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        div {
          position: absolute;
        }
      }

      .tower1 {
        top: 26%;
        left: 4.4%;
        width: 6%;
      }

      .tower2 {
        top: 12.6%;
        right: 4.4%;
        width: 6%;
      }

      .face-panel {
        top: 0;
        left: 18%;
        width: 11.6%;
      }

      .head {
        top: 0%;
        left: 56.4%;
        width: 12%;
      }

      .curve {
        top: 6%;
        left: -2%;
        width: 100%;
      }

      .human1 {
        top: 16.4%;
        left: 30.6%;
        width: 7.5%;
      }

      .human2 {
        top: 12.6%;
        left: 51.6%;
        width: 4.7%;
      }

      .stand-left {
        top: 25.6%;
        left: 22%;
        width: 28%;
      }

      .stand-right {
        top: 20%;
        left: 40%;
        width: 26%;
      }

      .stand-with-mobile {
        top: 16.3%;
        left: 61.5%;
        width: 18%;
      }

      .stand-with-human {
        top: 26%;
        left: 41.5%;
        width: 26%;
      }

      .tube1 {
        top: 26%;
        left: 77%;
        width: 8%;
      }

      .tube2 {
        top: 41%;
        left: 75%;
        width: 8%;
      }

      .tube3 {
        top: 52%;
        left: 60.5%;
        width: 8%;
      }

      .tube4 {
        top: 52%;
        left: 16%;
        width: 8%;
      }

      .guard {
        top: 40%;
        left: 37.1%;
        width: 13.5%;
      }
    }

    &__scan {
      position: relative;

      @media screen and (max-width: $screen-tablets-l) {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
      }

      &__ratio {
        position: relative;
        z-index: 10;
        height: 0;
        padding-top: 90%;

        > img {
          position: absolute;
        }
      }

      .human {
        bottom: -1%;
        left: 0;
        width: 28%;
      }

      .mobile {
        bottom: 0;
        left: 24%;
        width: 49%;
      }

      .unlock {
        bottom: -1%;
        left: 67%;
        width: 34%;
      }

      .line {
        position: relative;
        width: 100%;
        height: 8px;
        border-radius: 4em;
        background: var(--color-primary);
      }

      .capture-wrap {
        position: absolute;
        top: 20%;
        left: 32%;
        width: 31%;
      }

      .capture {
        width: 82%;
        margin: auto;
      }

      .gradient {
        position: absolute;
        top: 5%;
        left: 13%;
        width: 74%;
        height: 90%;
        background: linear-gradient(
          180deg,
          rgba(255, 91, 151, 0) 0%,
          rgba(255, 91, 151, 1) 100%
        );
      }

      .handle {
        position: absolute;
        width: 100%;
      }
    }

    &__wrapper {
      background-image: url(/images/demo/face-verfication-api/bg.png);
    }

    &__upload {
      background-image: url(/images/demo/face-verfication-api/bg-form.jpg);

      &__api pre,
      &__frame {
        border-color: var(--color-primary);
      }

      h6 {
        color: var(--color-primary);
      }
    }

    &__contact {
      padding-top: calc(var(--global-space) * 5);
      background-image: url(/images/demo/face-verfication-api/bg-contact.png);

      h4 {
        color: var(--color-primary);
      }

      form input {
        border: 1px solid var(--color-primary);
      }
    }
  }
}

.DataLabeling {
  overflow-x: hidden;

  @mixin button {
    display: inline-block;
    width: fit-content;
    min-width: 264px;
    padding: calc(var(--global-space) * 0.35) calc(var(--global-space) * 1.6);
    transition: background-color 0.2s;
    border-radius: 8px;
    background: var(--foundation-green-dark);
    color: white;
    font-family: Anakotmai, sans-serif;
    font-size: 26px;
    font-weight: 700;
    text-align: center;

    &:hover {
      background-color: rgba(93, 174, 134, 0.9);
    }
  }

  &__hero {
    display: relative;
    padding: 0 155px;
    overflow-x: hidden;
    background-image: url('/images/services/data-labeling/gradient-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: $screen-desktop) {
      padding: 0 260px;
      padding-top: 60px;
    }

    @media screen and (max-width: $screen-tablets-l) {
      padding: 0 80px;
      padding-top: -10px;
    }

    @media screen and (max-width: $screen-tablets) {
      padding: 0 20px;
      padding-top: 40px;
    }

    @media screen and (max-width: $screen-mobiles-l) {
      padding-top: 0;
    }

    @media screen and (max-width: $screen-mobiles) {
      height: 320px;
      padding: 0 16px;
      background-image: url('/images/services/data-labeling/hero-mobile-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: 80%;
    }

    &__container {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      padding-top: 151px;
      padding-bottom: 76px;
      gap: 69px;

      @media screen and (min-width: $screen-desktop) {
        gap: 120px;
      }

      @media screen and (max-width: $screen-tablets-xl) {
        padding-top: 120px;
        gap: 40px;
      }

      @media screen and (max-width: $screen-mobiles-l) {
        justify-content: center;
        padding-top: 60px;
      }

      &__circle {
        display: none;
        position: absolute;

        @media screen and (max-width: $screen-mobiles-l) {
          display: block;
          top: -80px;
          right: -60px;
          width: 100%;
          max-width: 122px;
          height: 100%;
          max-height: 124px;
        }
      }

      &__img {
        position: relative;

        @media screen and (max-width: $screen-mobiles-l) {
          display: none;
          padding-top: 47px;
          padding-bottom: 0;
        }

        &__circle {
          position: absolute;
          top: -100px;
          left: -100px;
          width: 100%;
          max-width: 362px;
          height: 100%;
          max-height: 367px;

          @media screen and (max-width: $screen-mobiles-l) {
            display: none;
          }
        }
      }

      .title {
        color: #000;
        font-family: Anakotmai, sans-serif;
        font-weight: 500;
      }

      span {
        font-family: Anakotmai, sans-serif;

        @include span();
      }

      &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 616px;
        line-height: 1.75;
        gap: 48px;

        @media screen and (min-width: $screen-desktop) {
          max-width: 700px;
        }

        @media screen and (max-width: $screen-mobiles-l) {
          max-width: 327px;
          gap: 16px;
        }

        &--desktop {
          font-size: 48px;

          @media screen and (min-width: $screen-desktop) {
            font-size: 52px;
          }

          @media screen and (max-width: $screen-tablets) {
            font-size: 32px;
          }

          @media screen and (max-width: $screen-mobiles-l) {
            display: none;
            font-size: 20px;
            gap: px;
          }
        }

        &--mobile {
          display: none;

          @media screen and (max-width: $screen-mobiles-l) {
            display: block;
          }
        }

        &__button {
          @include button;

          width: 302px;
          padding: 10px 32px;

          @media screen and (max-width: $screen-tablets) {
            font-size: 20px;
          }

          @media screen and (max-width: $screen-mobiles-l) {
            width: 100%;
            max-width: 327px;
            padding: calc(var(--global-space) * 0.4)
              calc(var(--global-space) * 1.6);
            font-size: 14px;
          }
        }
      }
    }
  }

  &__services {
    padding: 0 155px;
    font-family: Anakotmai, sans-serif;
    text-align: center;

    @media screen and (min-width: $screen-desktop) {
      padding: 0 260px;
    }

    @media screen and (max-width: $screen-tablets-xl) {
      padding: 0 calc(var(--global-space) * 4);
    }

    @media screen and (max-width: $screen-tablets) {
      padding: 0 calc(var(--global-space) * 0.8);
    }

    &__title {
      margin-bottom: 48px;

      @media screen and (max-width: $screen-mobiles-l) {
        margin: calc(var(--global-space) * 1.6) calc(var(--global-space) * 0.8);
      }
    }

    .title {
      color: #000;
      font-size: 40px;
      font-weight: 700;

      @media screen and (max-width: $screen-mobiles-l) {
        font-size: 18px;
      }
    }

    .title-desktop {
      @media screen and (max-width: $screen-mobiles-l) {
        display: none;
      }
    }

    .title-mobile {
      display: none;

      @media screen and (max-width: $screen-mobiles-l) {
        display: block;
      }
    }

    span {
      @include span();

      font-weight: 700;
    }

    &__content {
      display: grid;
      grid-gap: calc(var(--global-space) * 1.2);
      grid-template-columns: repeat(3, 1fr);

      &__item {
        padding: calc(var(--global-space) * 1.2) calc(var(--global-space) * 0.8);
        border: 1px solid var(--foundation-green-normal);
        border-radius: 16px;

        .title {
          margin-bottom: 10px;
          color: var(--foundation-green-dark-active);
          font-size: 32px;
          font-weight: 700;

          @media screen and (max-width: $screen-mobiles-l) {
            margin-bottom: 8px;
            font-size: 16px;
          }
        }

        .description {
          color: #000;
          font-family: Anakotmai, sans-serif;
          font-size: 18px;
          font-weight: 500;

          @media screen and (max-width: $screen-mobiles-l) {
            font-size: 14px;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          margin-top: 32px;
          gap: 12px;

          .item {
            color: var(--foundation-green-dark-hover);
            font-size: 16px;
            font-weight: 500;

            @media screen and (max-width: $screen-mobiles-l) {
              font-size: 12px;
            }
          }

          @media screen and (max-width: $screen-mobiles-l) {
            margin-top: 8px;
            gap: 8px;
          }
        }

        img {
          height: 80px;
          margin: auto;

          @media screen and (max-width: $screen-mobiles-l) {
            max-height: 40px;
          }
        }

        @media screen and (max-width: $screen-mobiles-l) {
          border: none;
        }
      }

      @media screen and (max-width: $screen-mobiles-l) {
        grid-gap: calc(var(--global-space) * 0.8);
        grid-template-columns: 1fr;
      }
    }

    &__box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-top: calc(var(--global-space) * 1.6);
      padding: calc(var(--global-space) * 3.1) calc(var(--global-space) * 2.9);
      border: 1px solid var(--foundation-green-normal);
      border-radius: 16px;
      color: #000;

      h3 {
        width: 500px;
        font-size: 32px;
        font-weight: 500;
        text-align: start;
      }

      .desktop {
        @media screen and (max-width: $screen-mobiles-l) {
          display: none;
        }
      }

      .mobile {
        display: none;
        text-align: center;

        @media screen and (max-width: $screen-mobiles-l) {
          display: block;
        }
      }

      &__button {
        @include button;

        @media screen and (max-width: $screen-tablets-xl) {
          grid-column-start: 2;
          width: 100%;
          font-size: 24px;
        }

        @media screen and (max-width: $screen-mobiles-l) {
          min-width: 328px !important;
        }
      }

      .img-desktop {
        @media screen and (max-width: $screen-tablets-xl) {
          grid-row-end: 3;
          grid-row-start: 1;
          width: 100%;
          font-size: 24px;
        }
      }

      .img-mobile {
        display: none;
      }

      @media screen and (max-width: $screen-tablets-xl) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 2;
        gap: 24px;
      }

      @media screen and (max-width: $screen-mobiles-l) {
        display: flex;
        flex-direction: column;
        padding: calc(var(--global-space) * 1.8) 0;
        border: none;
        gap: var(--global-space);

        h3 {
          width: 268px;
          font-size: 18px;
          text-align: center;
        }

        &__button {
          @include button;

          font-size: 14px;
        }

        .img-desktop {
          display: none;
        }

        .img-mobile {
          display: block;
        }
      }
    }

    &__logo {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: calc(var(--global-space) * 3.8) 0;
      column-gap: 65px;
      row-gap: 20px;

      @media screen and (max-width: $screen-mobiles-l) {
        height: 127px;
        margin: 0 auto calc(var(--global-space) * 1.8) auto;
      }

      @media screen and (max-width: $screen-mobiles) {
        width: 250px;
        gap: 16px;
      }

      &__img {
        @media screen and (max-width: $screen-mobiles-l) {
          max-width: 100px;
        }

        @media screen and (max-width: $screen-mobiles) {
          max-width: 60px;
        }
      }
    }
  }

  &__why {
    margin-bottom: 76px;
    padding-right: 155px;
    padding-left: 175px;
    color: #000;
    font-family: Anakotmai, sans-serif;

    @media screen and (min-width: $screen-desktop) {
      padding: 0 260px;
    }

    @media screen and (max-width: $screen-tablets-xl) {
      padding: 0 calc(var(--global-space) * 4);
    }

    @media screen and (max-width: $screen-tablets) {
      padding: 36px 0;
      background: url('/images/services/data-labeling/why-bg.svg');
      background-color: var(--color-primary-2);
      background-size: cover;
    }

    @media screen and (max-width: $screen-mobiles-s) {
      padding: 22px 16px;
    }

    &__container {
      display: flex;
      flex-direction: row;
      gap: 110px;

      @media screen and (min-width: $screen-desktop) {
        gap: 260px;
      }

      @media screen and (max-width: $screen-tablets-xl) {
        gap: 100px;
      }

      &__img {
        position: relative;

        @media screen and (max-width: $screen-tablets) {
          display: none;
        }

        img {
          z-index: 2;

          @media screen and (max-width: $screen-tablets-l) {
            transform: scale(0.9);
          }
        }

        &__bg1 {
          position: absolute;
          z-index: 1;
          top: 380px;
          left: -58px;
          width: 100%;
          max-width: 200px;
          height: 100%;
          max-height: 345px;
          background: rgba(149, 245, 198, 0.26);

          @media screen and (max-width: $screen-tablets-xl) {
            max-width: 160px;
            max-height: 276px;
          }

          @media screen and (max-width: $screen-tablets-l) {
            top: 300px;
            left: -30px;
            width: 160%;
            max-width: 140px;
            height: 60%;
            max-height: 260px;
          }
        }

        &__bg2 {
          position: absolute;
          top: 70px;
          left: -175px;
          width: 650px;
          height: 490px;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
          background: url('/images/services/data-labeling/why-bg.svg');
          background-color: var(--color-primary-2);
          background-size: cover;

          @media screen and (min-width: $screen-desktop) {
            top: 80px;
            left: -260px;
            width: 780px;
          }

          @media screen and (max-width: $screen-tablets-xl) {
            max-height: 462px;
          }

          @media screen and (max-width: $screen-tablets-l) {
            top: 15%;
            width: 160%;
            min-width: 510px;
            height: 50%;
            min-height: 300px;
            max-height: 340px;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        width: 582px;
        gap: 36px;

        @media screen and (min-width: $screen-desktop) {
          width: 680px;
        }

        @media screen and (max-width: $screen-tablets) {
          justify-content: center;
          width: 80%;
          margin: auto;
          padding: var(--global-space);
          border-radius: 8px;
          background-color: white;
        }

        @media screen and (max-width: $screen-mobiles) {
          gap: 16px;
          width: 100%;
          padding: calc(var(--global-space) * 0.8);
        }

        h2 {
          font-size: 40px;
          font-weight: 700;
          text-align: center;

          @media screen and (max-width: $screen-tablets-l) {
            font-size: 32px;
          }

          @media screen and (max-width: $screen-mobiles) {
            font-size: 18px;
          }
        }

        span {
          @include span();
        }

        &__button {
          @include button();

          width: 100%;
          padding: 10px 32px;

          @media screen and (max-width: $screen-tablets) {
            font-size: 20px;
          }

          @media screen and (max-width: $screen-mobiles) {
            max-width: 300px;
            margin: auto;
            font-size: 14px;
          }
        }

        &__iso {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 48px;

          @media screen and (max-width: $screen-mobiles) {
            width: 160px;
            margin: auto;
            gap: 24px;
          }
        }

        &__list {
          display: flex;
          flex-direction: column;
          gap: 8px;

          @media screen and (max-width: $screen-tablets) {
            width: 400px;
            margin: auto;
          }

          @media screen and (max-width: $screen-mobiles) {
            width: 100%;
            margin: auto;
          }

          &__item {
            display: flex;
            position: relative;
            flex-direction: row;
            align-items: baseline;
            font-size: 24px;
            gap: 16px;

            .icon {
              position: absolute;
              top: 6px;

              @media screen and (max-width: $screen-mobiles-l) {
                top: 2px;
                width: 10px;
              }
            }

            .item {
              margin-left: 36px;
              font-weight: 500;
              letter-spacing: 0.5px;

              @media screen and (max-width: $screen-mobiles-l) {
                margin-left: 24px;
                font-weight: 300;
              }
            }

            strong {
              font-weight: 700;
            }

            @media screen and (max-width: $screen-tablets-l) {
              font-size: 16px;
            }

            @media screen and (max-width: $screen-mobiles) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &__benefits {
    margin-bottom: 350px;
    padding: 0 76px;
    overflow-x: hidden;

    @media screen and (min-width: $screen-desktop) {
      padding: 0 140px;
    }

    @media screen and (max-width: $screen-tablets-l) {
      padding: 0 36px;
    }

    @media screen and (max-width: $screen-tablets) {
      margin-top: -40px;
      margin-bottom: 36px;
    }

    &__container {
      display: flex;
      position: relative;
      flex-direction: column;
      color: #000;
      font-family: Anakotmai, sans-serif;
      text-align: center;

      .title {
        font-size: 40px;
        font-weight: 700;

        @media screen and (max-width: $screen-mobiles-l) {
          font-size: 20px;
        }
      }

      .sub-title {
        font-size: 26px;
        font-weight: 500;

        @media screen and (max-width: $screen-mobiles-l) {
          font-size: 14px;
        }
      }

      span {
        @include span();
      }

      &__chart {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        height: 905px;
        margin-top: 76px;

        @media screen and (max-width: $screen-tablets-xl) {
          height: 680px;
        }

        @media screen and (max-width: $screen-tablets) {
          height: fit-content;
          margin-top: 32px;
        }

        &__img {
          img {
            @media screen and (max-width: $screen-tablets-xl) {
              width: 50% !important;
            }
          }

          @media screen and (max-width: $screen-tablets) {
            display: none;
          }
        }

        &__content {
          @media screen and (max-width: $screen-tablets) {
            display: flex;
            flex-direction: column;
            gap: 32px;
          }

          .item {
            display: flex;
            position: absolute;
            flex-direction: column;
            justify-content: center;
            width: 390px;
            text-align: center;
            gap: 8px;

            @media screen and (max-width: $screen-tablets) {
              position: static;
            }

            img {
              width: 75px;
              height: 75px;
              margin: auto;

              @media screen and (max-width: $screen-tablets-xl) {
                width: 60px;
                height: 60px;
              }

              @media screen and (max-width: $screen-tablets-xl) {
                width: 45px;
                height: 45px;
              }
            }

            .title {
              color: var(--foundation-green-darker);
              font-size: 24px;
              font-weight: 700;

              @media screen and (max-width: $screen-tablets-xl) {
                font-size: 18px;
              }

              @media screen and (max-width: $screen-mobiles-l) {
                color: var(--foundation-green-dark-hover);
                font-size: 16px;
              }
            }

            .description {
              font-size: 18px;
              font-weight: 500;

              @media screen and (max-width: $screen-tablets-xl) {
                font-size: 16px;
              }

              @media screen and (max-width: $screen-mobiles-l) {
                color: var(--foundation-green-dark-hover);
                font-size: 14px;
              }
            }
          }

          :first-child {
            top: -4%;
            left: 35%;

            @media screen and (min-width: $screen-desktop) {
              left: 39%;
            }

            @media screen and (max-width: $screen-tablets-xl) {
              left: 32%;
            }

            @media screen and (max-width: $screen-tablets-l) {
              left: 30%;
            }
          }

          :nth-child(2) {
            top: 35%;
            right: 0%;
          }

          :nth-child(3) {
            right: 15%;
            bottom: 0;

            @media screen and (max-width: $screen-tablets-xl) {
              right: 10%;
            }
          }

          :nth-child(4) {
            bottom: 12px;
            left: 15%;

            @media screen and (max-width: $screen-tablets-xl) {
              left: 10%;
            }
          }

          :last-of-type {
            top: 34%;
            left: 0%;
          }
        }
      }

      &__bg {
        position: absolute;
        z-index: -1;
        top: -40%;
        left: -10%;

        @media screen and (max-width: $screen-tablets) {
          display: none;
        }
      }

      &__bg1 {
        display: none;

        @media screen and (max-width: $screen-tablets) {
          display: block;
          position: absolute;
          top: 12%;
          left: -18%;
          width: 163px;
          height: 159px;
        }

        @media screen and (max-width: $screen-mobiles-l) {
          left: -40%;
        }
      }

      &__bg2 {
        display: none;

        @media screen and (max-width: $screen-tablets) {
          display: block;
          position: absolute;
          right: -18%;
          bottom: 20%;
          width: 222px;
          height: 217px;
        }

        @media screen and (max-width: $screen-mobiles-l) {
          right: -40%;
        }
      }
    }
  }
}
