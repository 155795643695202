@import './share.scss';

$screen-mobiles-xs: 350px;
$screen-mobiles-s: 400px;
$screen-mobiles: 580px;
$screen-mobiles-l: 750px;
$screen-tablets: 900px;
$screen-tablets-l: 1100px;
$screen-tablets-xl: 1320px;
$screen-desktop: 1600px;
$DualSlide-breakpoint: $screen-mobiles;
$foundation-blue-light: #effcf9;
$surface-subdued: #fafbfb;
$surface-neutral-default: #e4e5e7;
$text-subdued: #6d7175;

@mixin line {
  width: 376px;
  height: 2px;
  border: 0;
  background: #5fe3c1;

  @media screen and (min-width: $screen-desktop) {
    width: 600px;
  }

  @media screen and (max-width: $screen-tablets-l) {
    width: 30%;
  }
}

.CustomAI {
  overflow-x: hidden;
  overflow-y: auto;

  &__hero {
    min-height: 780px;

    @media screen and (max-width: $screen-mobiles-l) {
      min-height: 584px;
      max-height: 584px;
    }

    &__video {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      max-height: 780px;
      object-fit: cover;
      filter: grayscale(0.4);

      @media screen and (max-width: $screen-mobiles-l) {
        display: none;
      }
    }

    &__image {
      display: none;

      @media screen and (max-width: $screen-mobiles-l) {
        display: block;
        width: 100%;
        max-height: 587px;
      }
    }

    &__content {
      position: absolute;
      z-index: 2;
      top: 200px;
      width: 100%;
      padding-left: 93px;
      font-family: Anakotmai, sans-serif;

      @media screen and (min-width: $screen-desktop) {
        padding-left: 140px;
      }

      @media screen and (max-width: $screen-mobiles-l) {
        padding: 0;
        text-align: center;
      }

      h1 {
        color: white;
        font-size: 56px;
        font-weight: bold;

        span {
          background: linear-gradient(91deg, #69ffb7 40.28%, #00f6ff 91.5%);
          -webkit-background-clip: text;
          font-size: 64px;
          font-weight: 500;
          -webkit-text-fill-color: transparent;

          @media screen and (max-width: $screen-mobiles-l) {
            font-size: 32px;
            text-shadow: 0 9px 50px rgba(0, 223, 183, 0.5),
              6px -12px 25px rgba(0, 178, 255, 0.15),
              -9px 6px 25px rgba(51, 231, 210, 0.15);
          }
        }

        @media screen and (max-width: $screen-mobiles-l) {
          font-size: 22px;
        }
      }

      p {
        color: white;
        font-size: 28px;
        font-weight: 500;

        @media screen and (max-width: $screen-mobiles-l) {
          font-size: 16px;
        }
      }

      &__button {
        @include button;

        margin-top: 48px;
        padding: 16px 32px;
        opacity: 1;
        background-color: #47aa91;
        font-weight: 500;
        line-height: 1.4;

        &:hover {
          background-color: #3e8a75;
        }

        @media screen and (max-width: $screen-mobiles-l) {
          margin-top: 36px;
          padding: 12px 16px;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }

  &__about {
    position: relative;
    width: 100%;
    overflow-x: hidden;

    @media screen and (max-width: $screen-mobiles-l) {
      min-height: 350px;
      overflow-y: hidden;
    }

    &__bg {
      :first-child {
        position: absolute;
        z-index: -1;
        top: -200px;
        left: 0;
        opacity: 0.5;

        @media screen and (max-width: $screen-mobiles-l) {
          top: 0;
          left: 10%;
          height: 476px;
        }
      }

      :last-of-type {
        position: absolute;
        z-index: 2;
        z-index: -1;
        top: -25%;
        right: -3%;
        opacity: 0.5;

        @media screen and (max-width: $screen-mobiles-l) {
          display: none;
        }
      }
    }

    &__container {
      margin: 90px 100px 56px 100px;

      @media screen and (min-width: $screen-desktop) {
        margin: 90px 140px 56px 140px;
      }

      @media screen and (max-width: $screen-mobiles-l) {
        margin: 10%;
        margin-bottom: 0;
      }

      &__quote {
        display: flex;
        align-items: center;
        gap: 30px;

        hr {
          @include line;
        }

        @media screen and (max-width: $screen-mobiles-l) {
          display: none;
        }
      }

      &__content {
        margin-top: 75px;
        margin-bottom: 75px;
        font-family: Anakotmai, sans-serif;

        @media screen and (max-width: $screen-mobiles-l) {
          margin-top: 16px;
          margin-bottom: 32px;
        }

        h2 {
          font-family: Anakotmai, sans-serif;
          font-size: 28px;
          font-weight: 500;

          span {
            background: linear-gradient(91deg, #47aa91 40.28%, #6bd0d3 91.5%);
            -webkit-background-clip: text;
            font-weight: 500;
            -webkit-text-fill-color: transparent;
          }

          @media screen and (max-width: $screen-tablets-l) {
            font-size: 20px;
          }

          @media screen and (max-width: $screen-mobiles-l) {
            font-size: 14px;
          }
        }

        .content-1 {
          display: grid;
          position: relative;
          grid-template-columns: 25% auto;
          align-items: center;
          gap: 105px;

          @media screen and (min-width: $screen-desktop) {
            gap: 70px;
          }

          @media screen and (max-width: $screen-mobiles-l) {
            grid-template-columns: 1fr;
            padding-left: 2px;

            &::after {
              content: ' ';
              position: absolute;
              left: 0;
              width: 2px;
              height: 100%;
              background: linear-gradient(
                180deg,
                #47aa91 40.28%,
                #6bd0d3 91.5%
              );
            }
          }

          &__content--desktop {
            @media screen and (max-width: $screen-mobiles) {
              display: none;
            }

            @media screen and (max-width: $screen-mobiles-l) {
              padding-left: 27px;
            }
          }

          &__content--mobile {
            display: none;

            @media screen and (max-width: $screen-mobiles) {
              display: block;
              padding-left: 27px;
            }

            &__lineBreak {
              @media screen and (max-width: $screen-mobiles) {
                display: none;
              }
            }
          }

          &__image {
            @media screen and (max-width: $screen-mobiles-l) {
              display: none;
            }
          }
        }

        .content-2 {
          display: grid;
          position: relative;
          grid-template-columns: auto 32%;
          align-items: center;
          margin-top: 56px;
          gap: 56px;

          @media screen and (max-width: $screen-mobiles-l) {
            grid-template-columns: 1fr;
            margin-top: 24px;
            padding-right: 2px;

            &::after {
              content: ' ';
              position: absolute;
              right: 0;
              width: 2px;
              height: 100%;
              background: linear-gradient(
                180deg,
                #47aa91 40.28%,
                #6bd0d3 91.5%
              );
            }
          }

          &__content--desktop {
            max-width: 730px;

            @media screen and (min-width: $screen-desktop) {
              max-width: 1200px;
              gap: 40px;
            }

            @media screen and (max-width: $screen-mobiles-l) {
              display: none;
            }
          }

          &__content--mobile {
            display: none;

            @media screen and (max-width: $screen-mobiles-l) {
              display: block;
              max-width: 730px;
              padding-right: 27px;
            }

            &__lineBreak {
              @media screen and (max-width: $screen-mobiles) {
                display: none;
              }
            }
          }

          &__image {
            display: flex;
            justify-content: end;

            @media screen and (max-width: $screen-mobiles-l) {
              display: none;
            }
          }
        }
      }

      &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        font-family: Anakotmai, sans-serif;

        a {
          display: flex;
          align-items: center;
          padding: 16px 32px;
          border: 2px solid var(--foundation-blue-darker, #214f44);
          border-radius: 36px;
          background: transparent;
          font-size: 20px;
          font-weight: 500;
          gap: 10px;

          &:hover {
            background: #f8f8f8;
          }

          @media screen and (max-width: $screen-tablets-l) {
            font-size: 16px;
          }

          @media screen and (max-width: $screen-mobiles-l) {
            margin: auto;
            padding: 12px 16px;
            border: 1px solid var(--foundation-blue-darker, #214f44);
          }

          .icon {
            width: 21px;
            height: 21px;

            @media screen and (max-width: $screen-mobiles-l) {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }

  &__benefits {
    position: relative;
    padding: 80px 100px;
    padding-top: 20px;
    overflow: hidden;
    background-color: $surface-subdued;

    @media screen and (min-width: $screen-desktop) {
      margin: 80px 0;
    }

    @media screen and (max-width: $screen-mobiles-l) {
      margin-top: 30px;
      padding: 32px;
    }

    &__bg {
      display: none;

      @media screen and (max-width: $screen-mobiles-l) {
        display: block;
        position: absolute;
        z-index: -1;
        top: -100%;
        right: -80px;
        height: 476px;
        opacity: 0.5;
      }
    }

    &__container {
      color: 'black';
      font-family: Anakotmai, sans-serif;

      h1 {
        margin-bottom: 24px;
        background: linear-gradient(91deg, #47aa91 30%, #6bd0d3 80.5%);
        -webkit-background-clip: text;
        font-size: 48px;
        font-weight: 700;
        text-align: center;
        -webkit-text-fill-color: transparent;

        @media screen and (max-width: $screen-mobiles-l) {
          font-size: 20px;
        }

        span {
          background: black;
          -webkit-background-clip: text;
          font-size: 40px;
          font-weight: 500;

          @media screen and (max-width: $screen-mobiles-l) {
            font-size: 16px;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media screen and (max-width: $screen-mobiles-l) {
          gap: 8px;
        }
      }
    }

    .card {
      position: relative;
      height: 120px;
      padding: 36px;
      overflow: hidden;
      border-radius: 8px;
      background-color: white;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 0 5px 0 rgba(0, 0, 0, 0.05);
      cursor: pointer;

      @media screen and (max-width: $screen-mobiles-l) {
        height: 55px;
        padding: 12px;
      }

      h2 {
        font-size: 36px;
        font-weight: 700;

        @media screen and (max-width: $screen-mobiles-l) {
          font-size: 18px;
        }

        span {
          padding-right: 10px;
          color: #47aa91;
          font-weight: 500;
        }
      }

      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        svg {
          width: fit-content;
        }

        .icon {
          width: 45px;
          height: 40px;
          transition-duration: 0.2s;

          @media screen and (max-width: $screen-mobiles-l) {
            width: 24px !important;
            height: 24px !important;
            transition-duration: 0.2s;
          }
        }

        .icon--mobile {
          display: none;

          @media screen and (max-width: $screen-mobiles-l) {
            display: block;
          }
        }

        .icon--desktop {
          display: block;

          @media screen and (max-width: $screen-mobiles-l) {
            display: none;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        margin-top: 26px;
        gap: 24px;

        &__heading {
          display: flex;
          align-items: center;

          svg {
            width: fit-content;
          }

          &__icon {
            margin-right: 8px;

            @media screen and (max-width: $screen-mobiles-l) {
              display: none;
            }
          }
        }

        @media screen and (max-width: $screen-mobiles-l) {
          gap: 16px;
        }

        h3 {
          font-size: 24px;
          font-weight: 500;

          @media screen and (max-width: $screen-mobiles-l) {
            font-size: 16px;
          }
        }

        p {
          color: $text-subdued;
          font-size: 16px;
          font-weight: 500;

          @media screen and (max-width: $screen-mobiles-l) {
            font-size: 14px;
          }
        }

        hr {
          display: none;

          @media screen and (max-width: $screen-mobiles-l) {
            display: block;
            width: 100%;
            height: 1px;
            margin-top: 16px;
            border: 0;
            background: #f6f6f7;
          }
        }
      }
    }
  }

  &__realWorld {
    font-family: Anakotmai, sans-serif;

    @media screen and (max-width: $screen-mobiles-l) {
      margin: 32px 10%;
      margin-top: 25px;
    }

    &__container {
      margin-top: 20px;
      padding: 80px 100px;
      overflow: hidden !important;
      color: 'black';

      @media screen and (max-width: $screen-mobiles-l) {
        padding: 0;
      }

      h1 {
        margin-bottom: 24px;
        background: linear-gradient(91deg, #47aa91 30%, #6bd0d3 80.5%);
        -webkit-background-clip: text;
        font-size: 48px;
        font-weight: 700;
        text-align: center;
        -webkit-text-fill-color: transparent;

        @media screen and (max-width: $screen-mobiles-l) {
          font-size: 20px;
        }

        span {
          padding-left: 8px;
          background: black;
          -webkit-background-clip: text;
          font-size: 40px;
          font-weight: 500;

          @media screen and (max-width: $screen-mobiles-l) {
            font-size: 18px;
          }
        }
      }

      &__tab--desktop {
        display: block;

        @media screen and (max-width: $screen-mobiles-l) {
          display: none;
        }
      }

      &__tab--mobile {
        display: none;

        @media screen and (max-width: $screen-mobiles-l) {
          display: block;
          overflow-x: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      &__tabs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 8px;

        @media screen and (max-width: $screen-mobiles-l) {
          justify-content: start;
          width: max-content;
          gap: 16px;
        }

        .item {
          padding-bottom: 2px;
          border: 1px solid var(--text-disabled, #8c9196);
          border-radius: 99px;
          font-weight: 500;
          cursor: pointer;

          &__content {
            padding: 8px 12px;

            p {
              font-weight: 700;
            }
          }

          &--active {
            border: 2px solid var(--foundation-blue-normal-hover, #56ccae);
            border-radius: 99px;
            background: var(--foundation-blue-light, #effcf9);
            color: #398874;
            font-weight: 700;
          }
        }

        @media screen and (max-width: $screen-mobiles) {
          flex-wrap: nowrap;
          overflow: hidden;
          gap: 8px;
        }
      }

      &__content {
        display: flex;
        position: relative;
        flex-direction: column;
        gap: 24px;

        @media screen and (max-width: $screen-mobiles-l) {
          margin-top: 1.5em;
          gap: 8px;
        }

        .desktop {
          display: flex;

          @media screen and (max-width: $screen-mobiles-l) {
            display: none;
          }
        }

        .mobile {
          display: none;

          @media screen and (max-width: $screen-mobiles-l) {
            display: flex;
          }
        }

        .desktop,
        .mobile {
          .cards {
            display: flex;
            padding: 32px 60px;
            gap: 32px;

            @media screen and (max-width: $screen-mobiles-l) {
              flex-direction: column;
              margin-top: 10px;
              padding: 0;
            }

            .card {
              display: flex;
              flex-direction: column;
              min-height: 300px;
              padding: 20px 24px;
              text-align: center;
              gap: 24px;

              @media screen and (max-width: $screen-tablets-l) {
                min-height: 0;
                padding: 0;
                gap: 8px;
              }

              @media screen and (max-width: $screen-mobiles-s) {
                gap: 20px;
                min-height: 0px;
                padding: 0px;
              }

              img {
                width: 80px;
                height: 80px;
                margin: 0 auto;

                @media screen and (max-width: $screen-tablets-l) {
                  width: 55px;
                  height: 55px;
                }
              }

              &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 40px;

                &__list {
                  display: flex;
                  flex-direction: column;
                  gap: 36px;

                  &__label {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    height: 80px;
                    gap: 16px;

                    @media screen and (max-width: $screen-mobiles-l) {
                      align-items: center;
                      height: 22px;
                    }

                    @media screen and (min-width: $screen-desktop) {
                      height: 40px;
                    }

                    @media screen and (min-width: 751px) and (max-width: 824px) {
                      height: 120px;
                    }

                    h3 {
                      font-weight: 500;

                      @media screen and (min-width: $screen-desktop) {
                        font-size: 28px;
                      }

                      @media screen and (max-width: $screen-desktop) {
                        font-size: 20px;
                      }
                    }
                  }

                  &__description {
                    min-height: 100px;

                    p {
                      color: $text-subdued;
                      font-size: 20px;
                      font-weight: 500;

                      @media screen and (max-width: $screen-tablets-l) {
                        font-size: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    hr {
      width: 100%;
      height: 1px;
      margin-top: 80px;
      border: 0;
      background: #c9cccf;

      @media screen and (max-width: $screen-mobiles-l) {
        display: none;
      }
    }
  }

  &__why {
    position: relative;
    font-family: Anakotmai, sans-serif;

    @media screen and (min-width: $screen-desktop) {
      margin: 80px 140px;
    }

    @media screen and (max-width: $screen-mobiles-l) {
      margin-top: 0;
    }

    &__container {
      margin: 80px 100px;
      @media screen and (max-width: 375px) {
        margin: 80px 0px;
      }
      h2 {
        font-size: 48px;
        font-weight: 500;
        text-align: center;

        @media screen and (max-width: $screen-mobiles-l) {
          font-size: 18px;
        }

        span {
          padding-left: 8px;
          background: linear-gradient(91deg, #47aa91 30%, #6bd0d3 80.5%);
          -webkit-background-clip: text;
          font-weight: 500;
          -webkit-text-fill-color: transparent;
        }
      }

      &__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 24px;
        gap: 24px;

        @media screen and (max-width: $screen-tablets) {
          grid-template-columns: 1fr;
        }

        #data {
          grid-column-end: 3;
          grid-column-start: 1;

          @media screen and (max-width: $screen-tablets) {
            grid-column-end: 2;
            grid-column-start: 1;
          }
        }

        .card {
          display: grid;
          position: relative;
          z-index: 2;
          grid-template-columns: 200px auto;
          padding: 16px;
          border-radius: 16px;
          background-color: white;
          gap: 12px;

          picture {
            display: block;
          }

          @media screen and (max-width: $screen-tablets-l) {
            grid-template-columns: 1fr;
            justify-content: center;
            text-align: center;
          }

          @media screen and (max-width: $screen-mobiles-l) {
            grid-template-columns: 1fr;
            max-width: 210px;
            margin: auto;
            padding: 0;
            border: none;
            text-align: center;
          }

          &__image {
            @media screen and (max-width: $screen-tablets-l) {
              margin: auto;
            }
          }

          &__content {
            h3 {
              margin-bottom: 16px;
              font-size: 20px;
              font-weight: 500;

              @media screen and (max-width: $screen-mobiles-l) {
                font-size: 16px;
              }
            }

            p {
              display: contents;
              font-size: 16px;
              font-weight: 300;

              @media screen and (max-width: $screen-mobiles-l) {
                display: none;
              }
            }
          }
        }
      }

      .divider {
        display: none;
        margin-top: 56px;
        background: #c9cccf;

        @media screen and (max-width: $screen-mobiles-l) {
          display: block;
          margin-top: 32px;
        }
      }
    }

    &__bg {
      position: absolute;
      top: 0;
      right: 0;

      @media screen and (max-width: $screen-mobiles) {
        display: none;
      }
    }
  }

  &__customer {
    font-family: Anakotmai, sans-serif;

    @media screen and (min-width: $screen-desktop) {
      margin: 80px 140px;
    }

    @media screen and (max-width: $screen-mobiles-l) {
      margin: 32px 10%;
      margin-top: 0;
    }

    &__container {
      h2 {
        font-size: 48px;
        font-weight: 500;
        text-align: center;

        @media screen and (max-width: $screen-tablets-l) {
          font-size: 36px;
        }

        @media screen and (max-width: $screen-mobiles-l) {
          font-size: 18px;
        }

        span {
          padding-left: 8px;
          background: linear-gradient(91deg, #47aa91 30%, #6bd0d3 80.5%);
          -webkit-background-clip: text;
          font-weight: 500;
          -webkit-text-fill-color: transparent;
        }
      }

      &__content {
        margin-top: 40px;
        margin-bottom: 80px;

        @media screen and (max-width: $screen-mobiles-l) {
          margin-bottom: 32px;
        }

        &__scroller {
          .logo {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: 4em;
            row-gap: 24px;

            .customer-list img {
              @media screen and (max-width: $screen-tablets-l) {
                width: 140px;
              }

              @media screen and (max-width: $screen-mobiles-l) {
                display: none;
              }
            }
          }
        }

        &__scroller[data-animated='true'] {
          max-width: 1200px;
          margin: 0 auto;
          overflow: hidden;
          -webkit-mask: linear-gradient(
            90deg,
            transparent,
            white 20%,
            white 80%,
            transparent
          );
          mask: linear-gradient(
            90deg,
            transparent,
            white 30%,
            white 80%,
            transparent
          );

          .logo {
            flex-wrap: nowrap;
            width: max-content;
            animation: scroll 30s forwards linear infinite;
          }
        }

        @keyframes scroll {
          to {
            transform: translate(calc(-50% - 2em));
          }
        }
      }
    }
  }

  &__iso {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
    background: $surface-subdued;
    gap: 32px;
    font-family: Anakotmai, sans-serif;

    @media screen and (max-width: $screen-mobiles-l) {
      padding: 16px;
      gap: 16px;
    }

    p {
      text-align: center;
    }

    .desktop {
      display: block;
      font-size: 28px;
      font-weight: 300;

      @media screen and (max-width: $screen-tablets-l) {
        font-size: 20px;
      }

      @media screen and (max-width: $screen-mobiles-l) {
        display: none;
      }

      strong {
        font-weight: 500;
      }
    }

    .mobile {
      display: none;
      font-size: 14px;
      font-weight: 300;

      @media screen and (max-width: $screen-mobiles-l) {
        display: block;
      }

      strong {
        font-weight: 500;
      }
    }

    &__image {
      display: flex;
      flex-direction: row;
      gap: 64px;

      @media screen and (max-width: $screen-mobiles-l) {
        gap: 24px;
      }

      img {
        @media screen and (max-width: $screen-mobiles-l) {
          width: 114px;
        }
      }
    }
  }

  &__other {
    position: relative;
    padding: 80px 100px;
    font-family: Anakotmai, sans-serif;

    @media screen and (max-width: $screen-tablets-l) {
      padding: 80px 60px;
    }

    @media screen and (min-width: $screen-desktop) {
      padding: 80px 140px;
    }

    @media screen and (max-width: $screen-mobiles-l) {
      padding: 24px 16px;
      overflow: hidden;
    }

    h2 {
      margin-bottom: 32px;
      font-size: 40px;
      font-weight: 500;
      text-align: center;

      span {
        background: linear-gradient(91deg, #47aa91 30%, #6bd0d3 80.5%);
        -webkit-background-clip: text;
        font-weight: 500;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (max-width: $screen-mobiles-l) {
        font-size: 20px;
        font-weight: 700;
      }
    }

    &__content {
      display: grid;
      position: relative;
      z-index: 3;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
      gap: 24px;

      @media screen and (max-width: $screen-tablets-l) {
        gap: 20px;
      }

      @media screen and (max-width: $screen-mobiles-l) {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px 24px;
        border: 1px solid $surface-neutral-default;
        border-radius: 8px;
        background-color: white;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15),
          0 0 5px 0 rgba(0, 0, 0, 0.05);
        white-space: nowrap;
        gap: 8px;

        @media screen and (max-width: $screen-tablets) {
          width: 100%;
          padding: 8px 0;
        }

        h3 {
          font-size: 24px;
          font-weight: 500;

          @media screen and (max-width: $screen-tablets-l) {
            font-size: 18px;
          }

          @media screen and (max-width: $screen-mobiles-l) {
            font-size: 16px;
          }
        }

        img {
          @media screen and (min-width: $screen-desktop) {
            width: 200px;
          }

          height: 100px;
        }

        &:hover {
          border: 2px solid var(--Gradient-Text, #47aa91);
          background: var(--White, #fff);
        }
      }
    }

    &__bg {
      &__desktop {
        position: absolute;
        z-index: 2;
        top: -230px;
        right: 0;
        height: 594.184px;
        overflow-y: hidden;

        @media screen and (max-width: $screen-mobiles) {
          display: none;
        }
      }

      &__mobile {
        display: none;

        @media screen and (max-width: $screen-mobiles) {
          display: block;
          position: absolute;
          top: -100px;
          right: 0;
          overflow: hidden;
        }
      }
    }
  }

  &__footer {
    font-family: Anakotmai, sans-serif;

    &__container {
      display: grid;
      position: relative;
      z-index: 3;
      grid-template-columns: 1fr 1.5fr;
      max-width: none;
      height: 928px;
      padding: 0;

      @media (max-width: $screen-tablets-l) {
        grid-template-columns: 1fr;
        height: 515px;
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 76px 41px;
        padding: calc(var(--global-space) * 2.4) calc(var(--global-space) * 1.8);
        background: linear-gradient(
            0deg,
            rgba(33, 79, 68, 0.8),
            rgba(33, 79, 68, 0.8)
          ),
          url('/images/custom-ai/footer.svg');
        background-repeat: no-repeat;
        background-position: top 0;
        background-size: cover;
        color: white;

        @media (min-width: $screen-desktop) {
          padding-left: 160px;
        }

        @media (max-width: $screen-tablets-l) {
          grid-template-columns: 1fr;
          padding: 56px 16%;
        }

        @media (max-width: $screen-mobiles-l) {
          padding: 56px 10%;
        }

        .address {
          display: flex;
          flex-direction: column;
          font-size: 20px;
          gap: 36px;

          @media (max-width: $screen-mobiles) {
            font-size: 16px;
          }
        }

        .contact {
          display: flex;
          flex-direction: column;
          margin-top: 32px;

          #phone {
            font-size: 24px;
          }

          #email {
            font-size: 28px;
          }

          @media (max-width: $screen-mobiles) {
            font-size: 20px;
          }

          iframe {
            @include company-map;

            max-width: 100%;
            height: auto;
            aspect-ratio: 1 / 1;

            @media (max-width: $screen-mobiles-l) {
              max-height: 160px;
            }
          }
        }
      }

      &__form {
        position: relative;
        background-color: #47aa91;

        @media (min-width: $screen-desktop) {
          padding-right: 60px;
        }

        @media (max-width: $screen-tablets-l) {
          grid-row-start: 1;
          padding: 32px 0;
        }

        .form {
          .title {
            color: #214f44;
          }

          .form-button {
            @include submit-button;
          }
        }
      }
    }
  }
}
